/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Icon } from "@aws-amplify/ui-react";
export default function CreateNewGotchaButton(props) {
  const { addgotchabutton, overrides, ...rest } = props;
  const divStyles = {
    boxShadow: '5px 5px 5px #F4AAB9',
    position: 'absolute',
    borderRadius: "50%",
    padding: "0px 0px 30px 52px",
    width: "56px",
    height: "56px",
  };
  return (
    <Flex
      gap="9px"
      direction="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "CreateNewGotchaButton")}
    >
      <Flex
        gap="0"
        direction="column"
        width="86px"
        height="75px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Add text button")}
      >
           <div style={divStyles}>
        <Flex
          gap="-80px"
          width="fit-content"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          style={divStyles}
          {...getOverrideProps(overrides, "Frame 426")}
        >
          <Icon
            width="56px"
            height="56px"
            viewBox={{ minX: 0, minY: 0, width: 56, height: 56 }}
            paths={[
              {
                d: "M56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28Z",
                fill: "rgba(63,125,74,1)",
                fillRule: "nonzero",
              },
            ]}
            shrink="0"
            position="relative"
            margin="-60"
            {...getOverrideProps(overrides, "Shape")}
          ></Icon>
          <Flex
            padding="0px 0px 0px 0px"
            width="30.1px"
            height="28.78px"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Group")}
          >
            <Icon
              width="23.35595703125px"
              height="23.3193359375px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 23.35595703125,
                height: 23.3193359375,
              }}
              paths={[
                {
                  d: "M4.08062 23.2522C2.46939 23.0054 1.13837 21.9655 0.402808 20.3616C-1.25265e-07 19.4804 0 19.4451 0 11.549L0 3.61771L0.402808 2.82457C0.980749 1.71419 1.90896 0.797676 2.92473 0.357046C3.74786 0.00454158 4.02808 -0.0130836 11.6114 0.00454158L19.4398 0.00454158L20.5257 0.550923C21.8742 1.20305 22.9075 2.52495 23.2052 3.97021C23.3278 4.58709 23.3804 7.35425 23.3453 12.2012C23.2928 18.652 23.2578 19.5509 22.9951 20.1501C22.4521 21.3487 21.664 22.177 20.5257 22.7234L19.4398 23.2522L12.1543 23.3051C8.14372 23.3403 4.51845 23.3051 4.08062 23.2522L4.08062 23.2522ZM19.8777 20.8552C20.2279 20.6437 20.6833 20.1501 20.8935 19.7624C21.2963 19.0574 21.2963 19.0574 21.2437 11.408L21.1912 3.75871L20.7183 3.14183C19.8076 1.94331 19.65 1.92569 11.3837 1.97856C4.39586 2.03144 3.99305 2.04906 3.45013 2.38394C3.13489 2.57782 2.69706 3.01845 2.4869 3.35333C2.1016 3.97021 2.1016 4.05834 2.1016 11.6724C2.1016 19.1631 2.11912 19.3746 2.46939 19.8505C3.09987 20.7142 3.73035 21.0667 4.86872 21.1724C5.46417 21.2429 8.94934 21.2782 12.6096 21.2605C19.1421 21.2429 19.2822 21.2429 19.8777 20.8552L19.8777 20.8552Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              position="absolute"
              top="18.97%"
              bottom="0%"
              left="22.4%"
              right="0%"
              {...getOverrideProps(overrides, "Vector35594389")}
            ></Icon>
            <Icon
              width="9.92333984375px"
              height="4.47265625px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 9.92333984375,
                height: 4.47265625,
              }}
              paths={[
                {
                  d: "M2.98811 4.14523C1.86726 3.7046 0.921534 2.91146 0.343593 1.94208C-0.164295 1.06082 -0.111755 0.584937 0.518726 0.161932C1.07915 -0.208197 1.67461 0.0561793 2.25255 0.955064C2.84801 1.87157 3.84627 2.38271 5.00215 2.38271C6.14052 2.38271 6.91111 2.04783 7.52408 1.30757C7.78678 0.97269 8.11953 0.567312 8.25964 0.408685C8.59239 -0.01432 9.48557 0.00330362 9.73076 0.426308C10.1336 1.18419 9.94092 1.6777 8.80255 2.82334C7.92688 3.7046 7.48905 4.00422 6.75349 4.21573C5.51004 4.58586 4.07394 4.55061 2.98811 4.14523Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              position="absolute"
              top="66.44%"
              bottom="18.02%"
              left="44.48%"
              right="22.55%"
              {...getOverrideProps(overrides, "Vector35594390")}
            ></Icon>
            <Icon
              width="3.677001953125px"
              height="4.5654296875px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 3.677001953125,
                height: 4.5654296875,
              }}
              paths={[
                {
                  d: "M0.821674 4.1243C-0.684476 2.85528 0.0160597 0 1.81994 0C3.62381 0 4.35938 2.78478 2.90577 4.10667C2.22274 4.70593 1.52221 4.72355 0.821674 4.1243L0.821674 4.1243Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              position="absolute"
              top="41.64%"
              bottom="42.49%"
              left="41.55%"
              right="46.23%"
              {...getOverrideProps(overrides, "Vector35594391")}
            ></Icon>
            <Icon
              width="5.140380859375px"
              height="3.3623046875px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 5.140380859375,
                height: 3.3623046875,
              }}
              paths={[
                {
                  d: "M0.1466 3.10333C-0.151127 2.75083 0.0240067 1.74619 0.496868 1.07643C1.30248 -0.069204 3.00128 -0.351207 3.99954 0.477177C5.17294 1.48181 5.54072 3.06808 4.63002 3.29721C4.12214 3.42059 3.47414 3.10333 3.47414 2.75083C3.47414 2.39832 2.84366 1.78144 2.51091 1.78144C2.3708 1.78144 2.09058 2.04582 1.89794 2.36307C1.7228 2.6627 1.47762 3.03283 1.37254 3.15621C1.10984 3.45584 0.409301 3.42059 0.1466 3.10333Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              position="absolute"
              top="42.81%"
              bottom="45.51%"
              left="63.52%"
              right="19.4%"
              {...getOverrideProps(overrides, "Vector35594392")}
            ></Icon>
            <Icon
              width="6.47998046875px"
              height="6.697265625px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 6.47998046875,
                height: 6.697265625,
              }}
              paths={[
                {
                  d: "M2.62701 5.28756L2.62701 3.87754L1.3135 3.87754L0 3.87754L0 3.26066L0 2.64378L1.3135 2.64378L2.62701 2.64378L2.62701 1.32189L2.62701 0L3.23997 0L3.85294 0L3.85294 1.32189L3.85294 2.64378L5.16645 2.64378L6.47995 2.64378L6.47995 3.26066L6.47995 3.87754L5.16645 3.87754L3.85294 3.87754L3.85294 5.28756L3.85294 6.69758L3.23997 6.69758L2.62701 6.69758L2.62701 5.28756Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              position="absolute"
              top="0%"
              bottom="76.73%"
              left="0%"
              right="78.47%"
              {...getOverrideProps(overrides, "Vector35594393")}
            ></Icon>
          </Flex>
        </Flex>
        </div>
      </Flex>
    </Flex>
  );
}
