

// Modern theme

/* import 'survey-react/modern.min.css'; */
// Default theme
// import 'survey-react/survey.min.css';
import { StylesManager, Model } from 'survey-react';
import { Component } from 'react';
import "survey-core/modern.css";
import * as Survey from "survey-core";
import * as SurveyReact from "survey-react-ui";

import * as widgets from "surveyjs-widgets";
import { germanSurveyStrings } from '../localization/german.ts';
import { Flex } from '@aws-amplify/ui-react';
import { createReport } from '../services/graphQlServices';






const surveyJson = {
    "title": "Report Abuse",
    "description": "If you think that this Gotchaaa is inappropriate, please report it here.",
    "showProgressBar": "top",
    "pages": [
        {
            "navigationTitle": "Observation",
            "navigationDescription": "We need you to answer a few questions so we can better understand what’s going on in this account’s profile",
            "elements": [
                {
                    type: "html",
                    html: "We need you to answer a few questions so we can better understand what’s going on in this account’s profile. You’ll also have the option to add more info in your own words to this report. <br/><br/>We take reports seriously. If we find a rule violation, we’ll either ask them to remove the content or lock or suspend the account. <br/><br/>If there’s immediate danger, call your local emergency services in addition to reporting."
                },                
            ]
        },
        {
            "navigationTitle": "Observation",
            "navigationDescription": "We need you to answer a few questions so we can better understand what’s going on in this account’s profile",
            "elements": [
                {
                    type: "radiogroup",
                    name: "forWhom",
                    title: "Who is this report for?",
                    choices: [
                        "Myself", "Someone else or a specific group of people e.g. racial or religious groups", "Everyone on Gotchaaa e.g. misleading information"
                    ]
                },
                
                
                
                
            ]
        },
        {
            "navigationTitle": "What is going on?",
            "navigationDescription": "Deine bisherige Berufserfahrung",
            "elements": [
                {
                    type: "radiogroup",
                    name: "whatIsGoingOn",
                    title: "What is happening?",
                    choices: [
                        "Attacked because of their identity",
                        /* Slurs, misgendering, racist or sexist stereotypes, encouraging others to harass, sending hateful imagery */
                        "Harassed or intimidated with violence",
                        /* Sexual harassment, group harassment, insults or name calling, posting private info, threatening to expose private info, violent event denial, violent threats, celebration of violent acts */
                        "Spammed",
                        /* Posting malicious links, misusing hashtags, fake engagement, repetitive replies, Retweets, or Direct Messages. */
                        "They're being impersonated or shown a deceptive identity",
                        "Shown content related to or encouraged to self-harm",
                        "Offered tips or currency — or encouraged to send them — in a way that’s deceptive or promotes or causes harm"
                    ]
                }

            ]
        },
        {
            "navigationTitle": "Erfahrung",
            "navigationDescription": "Deine bisherige Berufserfahrung",
            "elements": [
                {
                    type: "radiogroup",
                    name: "howAbuse",
                    title: "How is the abuse happening?",
                    choices: [
                        "Posting misleading or deceptive links, leading to scams, phishing, or other malicious links",
                        "Misuse of chat function",
                        "Sending a lot of aggressive, unwanted, repetitive or unrelated chat messages or Direct Messages",
                        "Using bad language and insults",
                        "Using multiple accounts to interact or coordinate with other people to manipulate accounts, Gotchaaas, or other Gotchaaas features",
                        "Something else"
                    ]
                },
                {
                    "type": "comment",
                    "name": "summary",
                    "title": "Please summarize your observations in your own words?"
                },
                
                
                
            ]
        }
    ]
};

Survey.StylesManager.applyTheme("modern");
widgets.sortablejs(Survey);


class AbuseReport extends Component{
    constructor(props) {
        super(props);
   console.log(props)     
const survey = new Survey.Model(surveyJson);
//Add a new locale into the library.
var mycustomSurveyStrings = {
    pagePrevText: "Back",
    pageNextText: "Next",
    completeText: "Send",
    completingSurvey: "Thank you for reporting this Gotchaaa!"
};
let counter = 0
/* Survey
    .surveyLocalization
    .locales["my"] = germanSurveyStrings; */
Survey
    .surveyLocalization
    .locales["my"] = mycustomSurveyStrings;
    
        
        this.survey = survey;
        survey.locale = 'my';
        console.log(survey)
        var storageName = "abusereport";

        async function saveSurveyData(survey) {
            var data = survey.data;
            
            let report = {abuseReport: data, challengeId: props.challengeID, abuser: props.props}
            console.log(data)
            
            /* data.pageNo = survey.currentPageNo; */
            let res = await createReport(report)
            console.log({res})
        }

        survey.onPartialSend.add(function(sender){
            console.log(sender.data)
            
            let currentCounter = String(counter++)
            let action = "HelperQuestionnairePartialSend"+currentCounter
            /* ReactGA.event({
                category: "servicesuchender",
                action: action,
              }); */
            /* saveSurveyData(sender); */
        });

        survey.onComplete.add(function(sender, options){
            console.log({sender})
            console.log({options})
            /* ReactGA.event({
                category: "servicesuchender",
                action: "HelperQuestionnaireCompleted",
              }); */
            saveSurveyData(sender);
            
        });
        
        survey.sendResultOnPageNext = true;
       /*  var prevData = window.localStorage.getItem(storageName) || null;
        if(prevData) {
            var data = JSON.parse(prevData);
            survey.data = data;
            if(data.pageNo) {
                survey.currentPageNo = data.pageNo;
            }
        } */
    }
    

    render() {
        return (
            <>
            <Flex >
            <SurveyReact.Survey
                model={this.survey}
            />
            </Flex>
            </>
        );
    }
}
export default AbuseReport;