import logo from './logo.svg';
import './App.css';
import Login from './pages/Login';
import { Authenticator, ScrollView, TableRow, Text, View } from '@aws-amplify/ui-react';
import './layout.css'
import MyRoutes from './routes/MyRoutes';
import { BrowserRouter, Link, useNavigate } from 'react-router-dom';
import { GlobalContext, GlobalProvider } from './Context/globalState';
import { InitDataWrapper } from './routes/InitLoadWrapper';
import { RequireAuth } from './components/requireAuth';
import MemberArea from './pages/MemberArea';
import MenuNew from './ui-components/MenuNew';
import MenuPublic from './ui-components/MenuPublic';
import Header from './components/header';
import { Grid } from '@mui/material';
import CarouselCustom from './components/carousel';
import { GetCurrentUser } from './services/getters';
import { GetUser } from './services/graphQlServices';
import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import CookieConsent from "react-cookie-consent";
import {Adsense} from '@ctrl/react-adsense';


function App() {
  const { loadAvailableGotchaaas, loadMyGotchaaas, loadMyProfile, isRegisteredUser, isRegisteredFunction } = useContext(GlobalContext);
    
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [userId, setuserId] = useState("");
    const [ isRegistered, setisRegistered] = useState(isRegisteredUser);
    const [ isMobile, setisMobile] = useState(true);
    

  console.log(window.location.pathname)
  let path = window.location.pathname
  let pathArray = path.split("/")
  console.log(pathArray)
  
  const innerHeight = window.innerHeight-50
  const innerHeightContent = window.innerHeight-100
  const innerWidth = window.innerWidth
  function checkIsMobile(){
  if(innerWidth > 500){
    setisMobile(false)
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: innerWidth*0.8,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

  async function getUser() {
    const user = await GetCurrentUser();
    console.log(user) 
    let userId = user.username
    setuserId(userId)
    console.log({ userId });
    const userDetails = await GetUser(userId);
    console.log({ userDetails });
    console.log({ isRegisteredUser });
    
    
    if(userDetails.data){
      const userData = userDetails.data.getUser
      console.log({ userData });
      setisRegistered(true)
      isRegisteredFunction(true)
    }
      if(!userDetails.data || !isRegisteredUser){
    
      console.log("no user")
      setisRegistered(false)
    }
    
  }

  useEffect(() => {
    checkIsMobile()
    getUser()
   console.log("isRegisteredUser", isRegisteredUser)
    
  }, [isRegisteredUser])

  return (
    <>
    {isMobile ?
    
    
    <Authenticator.Provider>
    
      
        
        <Grid style={{margin: 0, height: "100%", overflow:"hidden"}}>
       
        <View 
        style={{
          position: 'fixed',
          top: "10",
          marginTop: "5px",
          width: '100%',
          
        }}
        >
             <Header  width={"100px"}/>
        </View>
        
       
       
            <TableRow style={{position:"fixed", top:60, width:"100%", height:innerHeight}} >
        <BrowserRouter>
        <ScrollView width={"100%"} alignItems="center" height={innerHeightContent}  >
      <MyRoutes userId={userId} pathname={path}/>
      
      </ScrollView>
      
            <View 
            style={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              
            }}
            >
              
         {/* isRegistered */ isRegisteredUser ?
         <MenuNew/>
         :
         <MenuPublic/>}
            
            </View>
            
            </BrowserRouter>
            </TableRow> 
            </Grid>
      
    </Authenticator.Provider>
  : 
  <>
  <Box sx={style}>
    <Header width={"15%"}/>
    <Text 
    style={{
      
      fontSize: "20px",
      
      marginTop: "20px"
    }}>
    Gotchaaa is mobile-first, please use your mobile phone to access Gotchaaa.<br/> Desktop version coming soon...
    </Text>
    <Adsense
  client="ca-pub-2644478000888518"
  slot="8115052959"
/>
    </Box>
  </>
  }
  <CookieConsent
  location="bottom"
  buttonText="Accept"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "20px" }}
  expires={150}
  debug={false}
  /* onAccept={acceptCookies()} */
>
This website uses cookies to improve user experience.{" "}
  <span style={{ fontSize: "10px" }}><a style={{color: "brown"}} href='/privacy-declaration' target='_blank' >Privacy Declaration</a></span>
</CookieConsent>
    
    </>
  );
}

export default App;
