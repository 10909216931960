
function compare( a, b ) {
    if ( a.quantitySi > b.quantitySi ){
      return -1;
    }
    if ( a.quantitySi < b.quantitySi ){
      return 1;
    }
    return 0;
  }
  
  
 
 
 
 function addIngredients(currentRecipes){
    let summarizedIngredientsArray = []
    let ingredientsArrayRef = []  
    let myRecipesLocal = [...currentRecipes]
    
    
    for(let el of myRecipesLocal){
        let ingredientsArray = el.ingredientsEntries
        for(let ingredient of ingredientsArray){
            let name = ingredient.name
            if(name === "all-purpose"){
                name = "flour"
            }
            console.log(ingredient)
            if(ingredient.slices){
                let quantitySi = Math.round(ingredient.slices.siTotalNumber)
                let siUnit = ingredient.slices.siUnit
                if(ingredientsArrayRef.indexOf(name) === -1){
                    ingredientsArrayRef.push(name)
                    summarizedIngredientsArray.push({name: name, quantitySi:quantitySi, siUnit:siUnit})
                } else {
                    let posIngredient = ingredientsArrayRef.indexOf(name)
                    summarizedIngredientsArray[posIngredient].quantitySi = summarizedIngredientsArray[posIngredient].quantitySi+ quantitySi
                }
            }
           
        }

}
summarizedIngredientsArray.sort(compare)
console.log(summarizedIngredientsArray)
return summarizedIngredientsArray


     
}

const AppReducer = (state, action) => {
    switch(action.type){
        case "SAVE_AVAILABLE_GOTCHAAAS":
            /* let currentGotchaaas = [action.payload, ...state.currentGotchaaas] */
            return{
                availableGotchaaas: action.payload,
                myGotchaaas: [...state.myGotchaaas],
                myProfile: state.myProfile,
                myChats: [...state.myChats],
                isRegistered: state.isRegistered,
                isConnected: state.isConnected,
                chatState: state.chatState
            }
        case "SAVE_MY_GOTCHAAAS":
            return{
                availableGotchaaas: [...state.availableGotchaaas],
                myGotchaaas: action.payload,
                myProfile: state.myProfile,
                myChats: [...state.myChats],
                isRegistered: state.isRegistered,
                isConnected: state.isConnected,
                chatState: state.chatState
            }
        case "SAVE_REFERAL_ID":
            return{
                availableGotchaaas: [...state.availableGotchaaas],
                myGotchaaas: [...state.myGotchaaas],
                myProfile: state.myProfile,
                myChats: [...state.myChats],
                referalId: action.payload,
                isRegistered: state.isRegistered,
                isConnected: state.isConnected,
                chatState: state.chatState
            }
        case "SAVE_MY_PROFILE":
            return{
                availableGotchaaas: [...state.availableGotchaaas],
                myGotchaaas: [...state.myGotchaaas],
                myProfile: action.payload,
                myChats: [...state.myChats],
                referalId: state.referalId,
                isRegistered: state.isRegistered,
                isConnected: state.isConnected,
                chatState: state.chatState
            }
        case "STATE_CHANGE":
            return{
                availableGotchaaas: [...state.availableGotchaaas],
                myGotchaaas: [...state.myGotchaaas],
                myProfile: {...state.myProfile},
                myChats: [...state.myChats],
                stateChange: action.payload,
                isRegistered: state.isRegistered,
                isConnected: state.isConnected,
                chatState: state.chatState
            }
        case "REGISTERED":
            return{
                availableGotchaaas: [...state.availableGotchaaas],
                myGotchaaas: [...state.myGotchaaas],
                myProfile: {...state.myProfile},
                myChats: [...state.myChats],
                stateChange: state.stateChange,
                isRegistered: action.payload,
                isConnected: state.isConnected,
                chatState: state.chatState
            }
        case "CONNECTED":
            return{
                availableGotchaaas: [...state.availableGotchaaas],
                myGotchaaas: [...state.myGotchaaas],
                myProfile: {...state.myProfile},
                myChats: [...state.myChats],
                stateChange: state.stateChange,
                isRegistered: state.isRegistered,
                isConnected: action.payload,
                chatState: state.chatState
            }
        case "CHAT_STATE":
            return{
                availableGotchaaas: [...state.availableGotchaaas],
                myGotchaaas: [...state.myGotchaaas],
                myProfile: {...state.myProfile},
                myChats: [...state.myChats],
                stateChange: state.stateChange,
                isRegistered: state.isRegistered,
                isConnected: state.isConnected,
                chatState: action.payload
            }
        case "ADD_ITEM":
            let currentRecipes = [action.payload, ...state.myRecipes]
            let ingrdientSh = addIngredients(currentRecipes)
            return{
                myRecipes: [action.payload, ...state.myRecipes],
                allRecipes: state.allRecipes.filter(item => (item !== action.payload)),
                ingredientShopping:[...ingrdientSh]
            }
        case "ADD_INGREDIENT":
            return{
                ingredientShopping: [...action.payload],
                myRecipes: [...state.myRecipes],
                allRecipes: [...state.allRecipes]
            }
        case "DELETE_ITEM":
            let currentRecipesAfterDelete = state.myRecipes.filter(item => (item !== action.payload)).slice()
            let ingrdientShAfterDelete = addIngredients(currentRecipesAfterDelete)
            return{
                myRecipes: state.myRecipes.filter(item => (item !== action.payload)),
                allRecipes: [action.payload, ...state.allRecipes],
                ingredientShopping:[...ingrdientShAfterDelete]
            }
        case "GET_STATE":
            return{
                myRecipes: [...state.myRecipes]
            }
        default:
            return [state]
    }
}

export default AppReducer