import { Button, Flex, ScrollView, Text, View } from "@aws-amplify/ui-react"
import { API, Auth, graphqlOperation } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { CreateNewGotchaButton, GotchaElementFeed, ProfileSlectedGotchaas } from "../ui-components"
import GotchaaProfileCustom from "../ui-components/GotchaaProfileCustom"
import { AmplifyS3Image, AmplifyS3ImagePicker } from '@aws-amplify/ui-react/legacy';

import { onUpdateUser } from "../graphql/subscriptions";
import { GlobalContext } from "../Context/globalState";
import { createReferal, GetReferal, GetReferalInternal } from '../services/graphQlServices';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    getOverrideProps,
    useNavigateAction,
  } from "@aws-amplify/ui-react/internal";
import Quiz from "./Quiz";
import { Box, Modal, Tooltip } from "@mui/material";
import { Badge } from "./badge";

const innerWidth = window.innerWidth
const innerHeight = window.innerHeight
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: innerWidth*0.9,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    
  };




const demoFeed = [0,1,2,3,4]
export default function BodyProfile(props){
    const homeOnClick = useNavigateAction({ type: "url", url: "/" });
    const { myProfile, loadMyProfile, loadMyGotchaaas, stateChange, isRegisteredUser, isRegisteredFunction } = useContext(GlobalContext);
    
    const [feedbackIsVisible, setFeedbackIsVisible] = useState(false)
    const [referal, setReferal] = useState(null)
    console.log(myProfile)
    if(myProfile === {}){
        loadMyProfileLocal()
        
    }
    let challengesNumber = 0
    if(myProfile.challenges){
        challengesNumber = myProfile.challenges.length
        console.log({challengesNumber})
    }

    const checkReferalExist = async () => {
        console.log({myProfile})
        if(myProfile){
            let challengeResponse1 = await GetReferalInternal(myProfile.referalId)
            console.log({challengeResponse1})
            if(challengeResponse1){
                setReferal(challengeResponse1.data.getReferal)
    
            }

        }
    }

    const signOut = async () => {
        try {
            await Auth.signOut();
            isRegisteredFunction(false)
            /* homeOnClick() */
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }


    const openFeedback = () => {
        setFeedbackIsVisible(true)
    }


    const handleClose = () => setFeedbackIsVisible(false);
    
    const createReferalLocal = async () =>  {
        console.log("createReferalLocal")
        /* setloading(true) */
        let res = await createReferal()
        console.log({res})
        setReferal(res.data.createReferal)
        loadMyProfileLocal()
        window.location.reload()
        /* setloading(false) */
        
    }
    const bodyHeight = window.innerHeight-100
    const [shareLinkVisible, setShareLinkVisible] = useState(false)
    const [linkCopied, setlinkCopied] = useState(false)
    
    const handleOpen = () => setShareLinkVisible(true);
    const handleCloseLnk = () => {
      setShareLinkVisible(false)
      setlinkCopied(false)
    };
    
    function CopyToClipboard(e){
    
        setlinkCopied(true)
        console.log(myProfile.name)
        let copyString = "You're invited to Gotchaaa! Click the link to get started: https://gotchaaa.net/login/"+myProfile.referalId /*  from "+myProfile.name+" */
        navigator.clipboard.writeText(copyString)
      }
    
/* const subscription = API.graphql(
    graphqlOperation(onUpdateUser)
  ).subscribe({
    next: ({ provider, value }) => {
        console.log({ provider, value })
    },
     
    error: (error) => console.warn(error)
  }); */
  
  function loadMyProfileLocal(){
        loadMyProfile()
        
  }
      
useEffect(() => {
    console.log("useEffect")
    console.log(myProfile)
    console.log(referal)
    loadMyProfileLocal()
    checkReferalExist()
}, [])



    
    return(
        <>
        <Modal
        open={shareLinkVisible}
        onClose={handleCloseLnk}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
        
                
                <div style={{maxWidth: "375px"}}>
                    <Flex direction="row" gap="small">
                {/* <StepperHori step={1}/> */}
                <Flex
                    direction="column"
                    gap="small" 
                    style={{width: "100%"}}
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                    margin={5}
                    
                >
                <Flex direction="column" gap="small" style={{width: "80%"}}>
               <Text fontSize={25}>
                Invite a friend
                </Text>
               <Text fontSize={12}>
                Click on the right symbol to copy the referal invite to your clipboard. Then send it to your friend.
                </Text>
               <Text style={{fontWeight: "bold"}}>
                Your Referal-ID
                </Text>
               <Text onClick={() => CopyToClipboard()}>
                {myProfile.referalId}
                </Text>
                
                </Flex>
                
                </Flex>
                
                <Flex direction={"row"} alignContent="center"
                    justifyContent="center"
                    alignItems="center" style={{padding:"20 0 0 0"}}>
                     {!linkCopied ? <ContentCopyIcon onClick={() => CopyToClipboard()} style={{fontSize: 50, color: "DarkMagenta"}} />: 
                     <>
                     <Flex direction="column" gap="0">
                      <CheckCircleIcon style={{fontSize: 50, color: "green"}} />
                      <Text fontSize={12}>
                      Copied!
                      </Text>
                      </Flex>
                     
                     </>}
                
                </Flex>
                </Flex>
                
            </div>
            
               </Box>
               </Modal>
        <Flex height={window.innerHeight-100} direction="column" gap="small" style={{width: "100%"}}>
        <View >
        {myProfile && <GotchaaProfileCustom props={myProfile} overrides={
            {
                "Melinda Marcus": {children: myProfile.name},
                "Design Engineer at Cloth Studios": {children: "from "+myProfile.location},
                "123 Gotchaaas": {children: ""},
                "99 Followers": {children: "100 Gotchaaas"},
                "Last Gotcha: 12.09.2022": {children: "Last Gotcha: "+myProfile.updatedAt.split("T")[0]},
                "Registered since: 10.09.2022": {children: "Registered: "+myProfile.createdAt.split("T")[0]},
                "99 Followers": {children: challengesNumber+" Gotchaaas"},
            }
            }
            
            />}
        </View>
        <View padding={10}>
        <Button onClick={openFeedback}>Feedback</Button>
        </View>
        <View padding={10}>
       
        <Flex direction={'column'}
        alignContent={'center'}
        justifyContent={'center'}
        padding={10}
        alignItems={'center'}


        >
            <Flex>
            <Text fontWeight={"bold"}>Status</Text>
            </Flex>
             <Flex>
             {myProfile && myProfile.badges && myProfile.badges.includes("participant") ?
                <Badge colorA='#FCFCFC' colorB='#4b0082' colorC="#7cfc00" label='Participation' tooltip={'Entry level. You can join Gotchaaas.'} labelColor="#FCFCFC" sizeBadge={100} sizeLabel={14}/>
            :
            <Badge colorA='#FCFCFC' colorB='#B4B4B4' colorC="#7cfc00" label='Participation' tooltip={'Entry level. You can join Gotchaaas.'} labelColor="#FCFCFC" sizeBadge={70} sizeLabel={14}/>
            }

            {myProfile && myProfile.badges && myProfile.badges.includes("creator") ?
            <Badge colorA='#FCFCFC' colorB='#4b0082' colorC="#7cfc00" label='Creator' tooltip={'As a creator you can create new Gotchaaas (min. 2 milestones)'} labelColor="#FCFCFC" sizeBadge={100} sizeLabel={8}/>
            :
            <Badge colorA='#FCFCFC' colorB='#B4B4B4' colorC="#7cfc00" label='Creator' tooltip={'As a creator you can create new Gotchaaas (min. 2 milestones)'} labelColor="#FCFCFC" sizeBadge={70} sizeLabel={8}/>
            
            }

            {myProfile && myProfile.badges && myProfile.badges.includes("gotchaaaner") ?
            <Badge colorA='#FCFCFC' colorB='#4b0082' colorC="#7cfc00" label='Gotchaaaner' tooltip={'You have advanced control over the group chat in your Gotchaaas (min. 4 milestones)'}  labelColor="#FCFCFC" sizeBadge={100} sizeLabel={12}/>
            :
            <Badge colorA='#FCFCFC' colorB='#B4B4B4' colorC="#7cfc00" label='Gotchaaaner' tooltip={'You have advanced control over the group chat in your Gotchaaas (min. 4 milestones)'}  labelColor="#FCFCFC" sizeBadge={70} sizeLabel={12}/>
            }
            </Flex>

            <Flex>
            <Text fontWeight={"bold"}>Milestones</Text>
            </Flex>
            <Flex>
       
            {myProfile && myProfile.badges && myProfile.badges.includes("Engaged") ?
             <Badge colorA='#FCFCFC' colorB='#4b0082' colorC="#7cfc00" label='Engaged' tooltip={'Participate in 5 Gotchaaas.'}  labelColor="#FCFCFC" sizeBadge={70} sizeLabel={14}/>
            :
            <Badge colorA='#FCFCFC' colorB='#B4B4B4' colorC="#7cfc00" label='Engaged' tooltip={'Participate in 5 Gotchaaas.'}  labelColor="#FCFCFC" sizeBadge={70} sizeLabel={14}/>
            }

            {myProfile && myProfile.badges && myProfile.badges.includes("Motivated") ?
             <Badge colorA='#FCFCFC' colorB='#4b0082' colorC="#7cfc00" label='Motivated' tooltip={'1 gotchaaa per day for 2 weeks'}  labelColor="#FCFCFC" sizeBadge={70} sizeLabel={14}/>
            :
            <Badge colorA='#FCFCFC' colorB='#B4B4B4' colorC="#7cfc00" label='Motivated' tooltip={'1 gotchaaa per day for 2 weeks'}  labelColor="#FCFCFC" sizeBadge={70} sizeLabel={14}/>
            }
            </Flex>
          
          
            <Flex>
            {myProfile && myProfile.likeCount && myProfile.challenges && (myProfile.likeCount/myProfile.challenges.length) > 0.5  ?
            <Badge colorA='#FCFCFC' colorB='#4b0082' colorC="#7cfc00" label='Good Vibes' tooltip={'Receive many likes for good vibes.'}  labelColor="#FCFCFC" sizeBadge={70} sizeLabel={8}/>
            :
            <Badge colorA='#FCFCFC' colorB='#B4B4B4' colorC="#7cfc00" label='Good Vibes' tooltip={'Receive many likes for good vibes.'}  labelColor="#FCFCFC" sizeBadge={70} sizeLabel={8}/>
            }

           {/*  {myProfile && myProfile.badges && myProfile.badges.includes("area") ?
             <Badge colorA='#FCFCFC' colorB='#4b0082' colorC="#7cfc00" label='Area Star' tooltip={'Create and participate in most Gotchaaas from your area.'}  labelColor="#FCFCFC" sizeBadge={70} sizeLabel={14}/>
            :
            <Badge colorA='#FCFCFC' colorB='#B4B4B4' colorC="#7cfc00" label='Area Star' tooltip={'Create and participate in most Gotchaaas from your area.'}  labelColor="#FCFCFC" sizeBadge={70} sizeLabel={14}/>
            } */}

            {referal && referal.referredUsers && referal.referredUsers.length > 5 ?
             <Badge colorA='#FCFCFC' colorB='#4b0082' colorC="#7cfc00" label='Community' tooltip={'5 people that register with your referal ID link'}  labelColor="#FCFCFC" sizeBadge={70} sizeLabel={12}/>
            :
            <Badge colorA='#FCFCFC' colorB='#B4B4B4' colorC="#7cfc00" label='Community' tooltip={'5 people that register with your referal ID link'}  labelColor="#FCFCFC" sizeBadge={70} sizeLabel={12}/>
            }

            {referal && referal.referredUsers && referal.referredUsers.length > 10 ? 
             <Badge colorA='#FCFCFC' colorB='#4b0082' colorC="#7cfc00" label='Gotchaaa Star' tooltip={'15 people that register with your referal ID link'}  labelColor="#FCFCFC" sizeBadge={70} sizeLabel={12}/>
            :
            <Badge colorA='#FCFCFC' colorB='#B4B4B4' colorC="#7cfc00" label='Gotchaaa Star' tooltip={'15 people that register with your referal ID link'}  labelColor="#FCFCFC" sizeBadge={70} sizeLabel={12}/>
            }
            
            
            
            </Flex>
           
          {/*   <Badge colorA='#FCFCFC' colorB='#1e90ff' colorC="#7cfc00" label='Sustainable' labelColor="#FCFCFC" sizeBadge={70} sizeLabel={20}/>
            <Badge colorA='#FCFCFC' colorB='#1e90ff' colorC="#7cfc00" label='Kindness' labelColor="#FCFCFC" sizeBadge={70} sizeLabel={14}/>
            <Badge colorA='#FCFCFC' colorB='#1e90ff' colorC="#7cfc00" label='Motivated' labelColor="#FCFCFC" sizeBadge={70} sizeLabel={14}/> */}
            </Flex>
        </View>

        <View padding={10}>
        
        
        {referal ?
        <>
        <Flex direction={'column'}
        alignContent={'center'}
        justifyContent={'center'}
        padding={10}
        alignItems={'center'}


        >
            
            
                <Text><b>Your Referal Link</b></Text>
            
            <Flex>
            <Button onClick={handleOpen}>Share Invite</Button>
            </Flex>
        
        <Flex direction={'column'}
        alignContent={'center'}
        justifyContent={'center'}
        padding={10}
        alignItems={'center'}>
            <Flex>
            {referal && referal.referredUsers && <><Text> Referals:  </Text><Text fontWeight={"bold"}>{referal.referredUsers.length}</Text></>}
            {referal && !referal.referredUsers && <>   <Text> Referals:  </Text><Text fontWeight={"bold"}>{0}</Text></>}
            </Flex>
         
        </Flex>
        
        </Flex>
            
            </>
            : 
            <Button onClick={createReferalLocal}>Create Referal</Button>
            }
        </View>
        
        <Modal
        open={feedbackIsVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <ScrollView height={innerHeight*0.8}>
        <Quiz />      
        </ScrollView>
        </Box>
        </Modal>
            
       {/*  <ProfileSlectedGotchaas style={{marginLeft: "1.2rem", maxWidth:"90%"}} overrides={
            {
                "MELINDA\u2019S WORLD": {children: "Favorite Gotchaaas"},
                "Rectangle 2": {src:  "https://images.pexels.com/photos/2726111/pexels-photo-2726111.jpeg?auto=compress&cs=tinysrgb&w=630&h=375&dpr=2"},
                "Rectangle 2.1": {src:  "https://images.pexels.com/photos/2726111/pexels-photo-2726111.jpeg?auto=compress&cs=tinysrgb&w=630&h=375&dpr=2"},
                "Rectangle 2.2": {src:  "https://images.pexels.com/photos/2726111/pexels-photo-2726111.jpeg?auto=compress&cs=tinysrgb&w=630&h=375&dpr=2"},
                "Rectangle 2.3": {src:  "https://images.pexels.com/photos/2726111/pexels-photo-2726111.jpeg?auto=compress&cs=tinysrgb&w=630&h=375&dpr=2"},
                "Rectangle 2.4": {src:  "https://images.pexels.com/photos/2726111/pexels-photo-2726111.jpeg?auto=compress&cs=tinysrgb&w=630&h=375&dpr=2"},
                "Rectangle 2.5": {src:  "https://images.pexels.com/photos/2726111/pexels-photo-2726111.jpeg?auto=compress&cs=tinysrgb&w=630&h=375&dpr=2"}
            }
            
        } /> */}
        {myProfile.favoriteGotchas ?
        <>
         <View padding={10}>
        <Text
         fontSize="1.5rem"
         fontWeight={"bold"}
         >
            Gallery ({(myProfile.favoriteGotchas && myProfile.favoriteGotchas.includes("empty")) ? myProfile.favoriteGotchas.length-1: myProfile.favoriteGotchas.length})
            
        </Text>
        {/* <Button margin={5} onClick={() => loadMyProfileLocal()}>Re</Button> */}
        </View>
        {myProfile && myProfile.favoriteGotchas && myProfile.favoriteGotchas.map((gotcha, index) => {
            if(gotcha != "empty"){
            return <AmplifyS3Image imgProps={{style: {"border-radius": "50%"}}} imgKey={gotcha} />
            }
        })
      }
      </> :
      <Flex margin= "2rem" direction={"column"}>
        <Text
            textAlign={"left"}
            fontSize="1.5rem"
            fontWeight={"bold"}
            >
            Gallery (0)
        </Text>
        <Text
            textAlign={"left"}
            fontSize="1rem"
            
            >
            Mark your favorite Gotchaaas to see them here
        </Text>
        <Button  onClick={() => loadMyProfileLocal()}>Refresh</Button>
      </Flex>
         }
        
        <View padding="1rem">
            <Button onClick={signOut}>Logout</Button>
        </View>  
        </Flex>
        </>
    )
}