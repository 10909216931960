/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGotcha = /* GraphQL */ `
  mutation CreateGotcha($event: String) {
    createGotcha(event: $event)
  }
`;
export const joinGotcha = /* GraphQL */ `
  mutation JoinGotcha($event: String) {
    joinGotcha(event: $event)
  }
`;
export const getMyGotchas = /* GraphQL */ `
  mutation GetMyGotchas($event: String) {
    getMyGotchas(event: $event)
  }
`;
export const listAvailableGotchas = /* GraphQL */ `
  mutation ListAvailableGotchas($event: String) {
    listAvailableGotchas(event: $event)
  }
`;
export const createUserCloud = /* GraphQL */ `
  mutation CreateUserCloud($event: String) {
    createUserCloud(event: $event)
  }
`;
export const changeUserPicture = /* GraphQL */ `
  mutation ChangeUserPicture($event: String) {
    changeUserPicture(event: $event)
  }
`;
export const likeChallenger = /* GraphQL */ `
  mutation LikeChallenger($event: String) {
    likeChallenger(event: $event)
  }
`;
export const replyToChallenger = /* GraphQL */ `
  mutation ReplyToChallenger($event: String) {
    replyToChallenger(event: $event)
  }
`;
export const getChallenge = /* GraphQL */ `
  mutation GetChallenge($event: String) {
    getChallenge(event: $event)
  }
`;
export const markFavorite = /* GraphQL */ `
  mutation MarkFavorite($event: String) {
    markFavorite(event: $event)
  }
`;
export const createFeedbackFunction = /* GraphQL */ `
  mutation CreateFeedbackFunction($event: String) {
    createFeedbackFunction(event: $event)
  }
`;
export const createReferalFunction = /* GraphQL */ `
  mutation CreateReferalFunction($event: String) {
    createReferalFunction(event: $event)
  }
`;
export const createReportAbuseFunction = /* GraphQL */ `
  mutation CreateReportAbuseFunction($event: String) {
    createReportAbuseFunction(event: $event)
  }
`;
export const joinWaitlist = /* GraphQL */ `
  mutation JoinWaitlist($event: String) {
    joinWaitlist(event: $event)
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      profilPicture
      username
      location
      lat
      long
      interests
      humors
      groups
      birthdate
      challenges
      channels
      chatToken
      lastGotcha
      favoriteGotchas
      referalsLeft
      referalId
      invite
      badges
      likeCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      profilPicture
      username
      location
      lat
      long
      interests
      humors
      groups
      birthdate
      challenges
      channels
      chatToken
      lastGotcha
      favoriteGotchas
      referalsLeft
      referalId
      invite
      badges
      likeCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      profilPicture
      username
      location
      lat
      long
      interests
      humors
      groups
      birthdate
      challenges
      channels
      chatToken
      lastGotcha
      favoriteGotchas
      referalsLeft
      referalId
      invite
      badges
      likeCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAbuse = /* GraphQL */ `
  mutation CreateAbuse(
    $input: CreateAbuseInput!
    $condition: ModelAbuseConditionInput
  ) {
    createAbuse(input: $input, condition: $condition) {
      id
      user
      abuseReport
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAbuse = /* GraphQL */ `
  mutation UpdateAbuse(
    $input: UpdateAbuseInput!
    $condition: ModelAbuseConditionInput
  ) {
    updateAbuse(input: $input, condition: $condition) {
      id
      user
      abuseReport
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAbuse = /* GraphQL */ `
  mutation DeleteAbuse(
    $input: DeleteAbuseInput!
    $condition: ModelAbuseConditionInput
  ) {
    deleteAbuse(input: $input, condition: $condition) {
      id
      user
      abuseReport
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createWaitlist = /* GraphQL */ `
  mutation CreateWaitlist(
    $input: CreateWaitlistInput!
    $condition: ModelWaitlistConditionInput
  ) {
    createWaitlist(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateWaitlist = /* GraphQL */ `
  mutation UpdateWaitlist(
    $input: UpdateWaitlistInput!
    $condition: ModelWaitlistConditionInput
  ) {
    updateWaitlist(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteWaitlist = /* GraphQL */ `
  mutation DeleteWaitlist(
    $input: DeleteWaitlistInput!
    $condition: ModelWaitlistConditionInput
  ) {
    deleteWaitlist(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      user
      findUs
      suggestions
      recommendFriends
      satisfaction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      user
      findUs
      suggestions
      recommendFriends
      satisfaction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      user
      findUs
      suggestions
      recommendFriends
      satisfaction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createReferal = /* GraphQL */ `
  mutation CreateReferal(
    $input: CreateReferalInput!
    $condition: ModelReferalConditionInput
  ) {
    createReferal(input: $input, condition: $condition) {
      id
      user
      allowedInvites
      referredUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateReferal = /* GraphQL */ `
  mutation UpdateReferal(
    $input: UpdateReferalInput!
    $condition: ModelReferalConditionInput
  ) {
    updateReferal(input: $input, condition: $condition) {
      id
      user
      allowedInvites
      referredUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteReferal = /* GraphQL */ `
  mutation DeleteReferal(
    $input: DeleteReferalInput!
    $condition: ModelReferalConditionInput
  ) {
    deleteReferal(input: $input, condition: $condition) {
      id
      user
      allowedInvites
      referredUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBadges = /* GraphQL */ `
  mutation CreateBadges(
    $input: CreateBadgesInput!
    $condition: ModelBadgesConditionInput
  ) {
    createBadges(input: $input, condition: $condition) {
      id
      mainPic
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBadges = /* GraphQL */ `
  mutation UpdateBadges(
    $input: UpdateBadgesInput!
    $condition: ModelBadgesConditionInput
  ) {
    updateBadges(input: $input, condition: $condition) {
      id
      mainPic
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBadges = /* GraphQL */ `
  mutation DeleteBadges(
    $input: DeleteBadgesInput!
    $condition: ModelBadgesConditionInput
  ) {
    deleteBadges(input: $input, condition: $condition) {
      id
      mainPic
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFriends = /* GraphQL */ `
  mutation CreateFriends(
    $input: CreateFriendsInput!
    $condition: ModelFriendsConditionInput
  ) {
    createFriends(input: $input, condition: $condition) {
      id
      friends
      blocked
      incomingfriendRequests
      outgoingfriendRequests
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFriends = /* GraphQL */ `
  mutation UpdateFriends(
    $input: UpdateFriendsInput!
    $condition: ModelFriendsConditionInput
  ) {
    updateFriends(input: $input, condition: $condition) {
      id
      friends
      blocked
      incomingfriendRequests
      outgoingfriendRequests
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFriends = /* GraphQL */ `
  mutation DeleteFriends(
    $input: DeleteFriendsInput!
    $condition: ModelFriendsConditionInput
  ) {
    deleteFriends(input: $input, condition: $condition) {
      id
      friends
      blocked
      incomingfriendRequests
      outgoingfriendRequests
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      challenges
      participants
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      challenges
      participants
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      challenges
      participants
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createChallenge = /* GraphQL */ `
  mutation CreateChallenge(
    $input: CreateChallengeInput!
    $condition: ModelChallengeConditionInput
  ) {
    createChallenge(input: $input, condition: $condition) {
      id
      creatorUid
      timestamp
      timestampEnd
      group
      friendsOnly
      friends
      notsuccessfulChallengers
      successfulChallengers
      interests
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChallenge = /* GraphQL */ `
  mutation UpdateChallenge(
    $input: UpdateChallengeInput!
    $condition: ModelChallengeConditionInput
  ) {
    updateChallenge(input: $input, condition: $condition) {
      id
      creatorUid
      timestamp
      timestampEnd
      group
      friendsOnly
      friends
      notsuccessfulChallengers
      successfulChallengers
      interests
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChallenge = /* GraphQL */ `
  mutation DeleteChallenge(
    $input: DeleteChallengeInput!
    $condition: ModelChallengeConditionInput
  ) {
    deleteChallenge(input: $input, condition: $condition) {
      id
      creatorUid
      timestamp
      timestampEnd
      group
      friendsOnly
      friends
      notsuccessfulChallengers
      successfulChallengers
      interests
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createChallenger = /* GraphQL */ `
  mutation CreateChallenger(
    $input: CreateChallengerInput!
    $condition: ModelChallengerConditionInput
  ) {
    createChallenger(input: $input, condition: $condition) {
      id
      userid
      challenge
      mainPic
      mainPicBlurred
      frontPic
      frontPicBlurred
      qualified
      timestamp
      message
      likes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChallenger = /* GraphQL */ `
  mutation UpdateChallenger(
    $input: UpdateChallengerInput!
    $condition: ModelChallengerConditionInput
  ) {
    updateChallenger(input: $input, condition: $condition) {
      id
      userid
      challenge
      mainPic
      mainPicBlurred
      frontPic
      frontPicBlurred
      qualified
      timestamp
      message
      likes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChallenger = /* GraphQL */ `
  mutation DeleteChallenger(
    $input: DeleteChallengerInput!
    $condition: ModelChallengerConditionInput
  ) {
    deleteChallenger(input: $input, condition: $condition) {
      id
      userid
      challenge
      mainPic
      mainPicBlurred
      frontPic
      frontPicBlurred
      qualified
      timestamp
      message
      likes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMatches = /* GraphQL */ `
  mutation CreateMatches(
    $input: CreateMatchesInput!
    $condition: ModelMatchesConditionInput
  ) {
    createMatches(input: $input, condition: $condition) {
      id
      challenge
      challengeTimestamp
      picUrlA
      picUrlB
      idA
      idB
      userIdA
      userIdB
      matchTimeStamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMatches = /* GraphQL */ `
  mutation UpdateMatches(
    $input: UpdateMatchesInput!
    $condition: ModelMatchesConditionInput
  ) {
    updateMatches(input: $input, condition: $condition) {
      id
      challenge
      challengeTimestamp
      picUrlA
      picUrlB
      idA
      idB
      userIdA
      userIdB
      matchTimeStamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMatches = /* GraphQL */ `
  mutation DeleteMatches(
    $input: DeleteMatchesInput!
    $condition: ModelMatchesConditionInput
  ) {
    deleteMatches(input: $input, condition: $condition) {
      id
      challenge
      challengeTimestamp
      picUrlA
      picUrlB
      idA
      idB
      userIdA
      userIdB
      matchTimeStamp
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createReports = /* GraphQL */ `
  mutation CreateReports(
    $input: CreateReportsInput!
    $condition: ModelReportsConditionInput
  ) {
    createReports(input: $input, condition: $condition) {
      id
      reportGiver
      reportGiverMsg
      reportedUser
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateReports = /* GraphQL */ `
  mutation UpdateReports(
    $input: UpdateReportsInput!
    $condition: ModelReportsConditionInput
  ) {
    updateReports(input: $input, condition: $condition) {
      id
      reportGiver
      reportGiverMsg
      reportedUser
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteReports = /* GraphQL */ `
  mutation DeleteReports(
    $input: DeleteReportsInput!
    $condition: ModelReportsConditionInput
  ) {
    deleteReports(input: $input, condition: $condition) {
      id
      reportGiver
      reportGiverMsg
      reportedUser
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAvailableGotchasPublic = /* GraphQL */ `
  mutation ListAvailableGotchasPublic($event: String) {
    listAvailableGotchasPublic(event: $event)
  }
`;
