import Carousel from 'react-material-ui-carousel'
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
 /*
  What is a gotcchaaa?
  A gotchaaa is a new way of sharing a moment in your life with just 2 pictures.
  Upload one picture of what is in front (with your main camera) and one picture from yourself (selfie from you front camera).
  That is a gotchaaa.

  You can also optionally add a short description to your gotchaaa.
Although it is totally fine to not write anything, since this was the original idea of gotchaaa.
But first user feedback indicated that people may like to add a short description to their gotchaaa.
 

  Get inspired
  3. Get inspired by what others are doing and try new things! 

  Share Moments
  4. Share moments with the world!

  Meet new people
  1. Join the social media platform that connects you with new people via private chat if you like each others gotchaas!

  View exclusive content
  1. View exclusive moments by uploading your own! 

  Similar Interests
  2. Find people with similar interests and connect with them!


   If you want to see the world, you have to get out of your comfort zone.
   If you want to get out of your comfort zone, you have to try new things.
   If you want to try new things, you have to get inspired by others.
   If you want to get inspired by others, you have to share your own moments.
   If you want to share your own moments, you have to connect with others.
   If you want to connect with others, you have to find people with similar interests.
   If you want to find people with similar interests, you have to create an account.

  5. Connect by group and private chat!
  1. Find and connect with like-minded individuals from around your area!
  2. Expand your social media network and never miss a beat!
  2. Get a behind-the-scenes look at your favorite creators by uploading your own content! 
  2. A great way to meet new people and make friends.
  3. The perfect way to find people with similar interests.
  3. Get a front row seat to the action by uploading your own content!
  2. Share your artwork with the community and get feedback from other artists.
  
  -Make new friends and expand your social circle!
  3. Find people with similar interests and exchange with them!
  -Find your perfect match!
  
  1. Find and follow people with similar hobbies and interests as you!
  3. Keep up with the latest trends and find out what's happening in your field!
  
  2. Find your perfect match and chat with them privately on our social media platform!
3. Connect with new people and chat with them privately on our social media platform – it's the perfect way to meet new friends!

-Get to know new and interesting people from all over the world!

1. Social media platform that connects you with new people via private chat based on interests and likes.
  */

var items = [
    /*   {
          name: "Meet new poeple",
          description: "Probably the most random thing you have ever seen!",
          src: "https://previews.123rf.com/images/smilepoker/smilepoker1811/smilepoker181100080/120464595-group-of-peoples-meeting-in-park-by-use-laptop-for-teamwork.jpg"
      },
       */
      {
          name: "Get inspired",
          description: "Get inspired by what others are doing and try new things!",
          src:"https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/boySelfie.jpg"
      },
      {
          name: "Share",
          description: "Share moments with the like-minded people!",
          /* src:"https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/selfieJo-red.jpg" */
          src:"https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/girl1.jpg"
      },
      {
          name: "Participate",
          description: "If you like each others gotchaas, you get connected via private chat!",
          /* src:"https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/peoplesharingtheircraft.jpg" */
          src:"https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/man1.jpg"
      },
      {
          name: "Exclusive",
          description: "View exclusive content by participating!",
          /* src:"https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/peoplesharingbar.jpg" */
          /* src:"https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/newWays.jpg" */
          /* src:"https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/man2nerd.jpg" */
          src:"https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/woman5.jpg"
      },
      {
          name: "Have conversations",
          description: "Find people with similar interests in your area!",
          /* src:"https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/mountainsInspiration.jpg" */
          src:"https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/woman4.jpg"
      }
  ]

  function Item(props)
{
    return (
        <Paper style={{margin: "1em", padding: "2em"}}>
            <h2>{props.item.name}</h2>
            <img src={props.item.src} width={"90%"} />
            <p>{props.item.description}</p>
            
           {/*  <Button className="CheckButton">
                Check it out!
            </Button> */}
        </Paper>
    )
}

export default function CarouselCustom(props){
    
    return(
        <Carousel
        style={{width: "100%"}}
        navButtonsWrapperProps={{style: {bottom: "10"}}}
        navButtonsAlwaysInvisible={true}
        indicatorContainerProps={
          {
            style: {
              
            zIndex: 1,
            marginTop: "-10px",
            marginBottom: "10px",
            position: "relative",
            /* padding: "-10px" */
          
            }}}
        indicators={true}
          /* IndicatorIcon={<Home/>} */
        >
                  {
                      items.map( (item, i) => <Item key={i} item={item} /> )
                  }
              </Carousel>
    )
}