import { Image, View } from "@aws-amplify/ui-react";


export default function Header(props){
    return(
        <>
        
        <Image
  alt="Amplify logo"
  src="https://saubernessbucket121805-dev.s3.eu-central-1.amazonaws.com/public/gotchaaa.png"
  objectFit="initial"
  objectPosition="50% 50%"
  backgroundColor="initial"
  
  width={props.width}
  opacity="100%"
  onClick={() => alert('📸 Say cheese!')}
/>
        
        
        </>
    )
}