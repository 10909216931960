import { ScrollView, Text } from "@aws-amplify/ui-react";
import { Card, Modal } from "@mui/material";
import Header from "./header";


export function ToS(props) {

    return(
        <>
        
        <Header width={"30%"}/>
        <ScrollView style={{height: window.innerHeight-100,  width: "100%" /* , backgroundColor: "black" */}}>
        <Card style={{margin:"2rem"}} >
        <Text style={{color: "Black", fontSize:30}}>Terms of Service</Text>
        <Text style={{color: "Black", fontSize:16, margin: "1.5rem"}}>
            <br/><br/>
            <b>1. AGREEMENT TO TERMS</b><br/><br/>
           <p> Instead of paying to use Gotchaaa and the other products and services we offer, by using the Gotchaaa Service covered by these Terms, you acknowledge that we can show you personalized ads and other commercial and sponsored content that business and organizations pay us to promote on and off the SchooIT Products. We use your personal data, such as information about your activity and interests, to show you personalized ads and sponsored content that may be more relevant to you.
       Protecting people's privacy is central to how we've designed our personalized ads system. This means that we can show you relevant and useful ads without telling advertisers who you are. We don't sell your personal data. We allow advertisers to tell us things like their business goal, and the kind of audience they want to see their ads (for example, people between the age of 18-35 who are interested in programming). We then show their ad to people who we think might be interested.
       </p>
                These Terms of Service constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“user”, “you” or “your”) and SchooIT, an Swiss company with its principal place of business at Rüttihardstrasse 10, CH-4127 Birsfelden (“SchooIT”, “we”, “us” or “our”), concerning your access to and use of the www.gotchaaa.net website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms of Service. If you do not agree with all of these Terms of Service, then you are expressly prohibited from using the Site and you must discontinue use immediately.

                Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Service at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of these Terms of Service, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms of Service to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Service by your continued use of the Site after the date such revised Terms of Service are posted.

                The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                <br/><br/>
                2. INTELLECTUAL PROPERTY RIGHTS<br/><br/>
                The Site and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by SchooIT, its licensors or other providers of such material and are protected by Switzerland and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.

                These Terms of Service permit you to use the Site for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Site, except as follows:

                Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.
                You may store files that are automatically cached by your Web browser for display enhancement purposes.
                You may print or download one copy of a reasonable number of pages of the Site for your own personal, non-commercial use and not for further reproduction, publication or distribution.
                If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.
                If we provide social media features with certain content, you may take such actions as are enabled by such features.

                You must not:
                Modify copies of any materials from this Site.
                Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.
                Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this Site.
                You must not access or use for any commercial purposes any part of the Site or any services or materials available through the Site.
                If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Site in breach of the Terms of Service, your right to use the Site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Site or any content on the Site is transferred to you, and all rights not expressly granted are reserved by SchooIT. Any use of the Site not expressly permitted by these Terms of Service is a breach of these Terms of Service and may violate copyright, trademark and other laws.
                <br/><br/>
                3. USER REPRESENTATIONS
                By using the Site, you represent and warrant that:

                You are at least 18 years of age.
                You have not previously been suspended or removed from the Site.
                You have full power and authority to enter into these Terms of Service and doing so will not violate any other agreement to which you are a party.
                You will not violate any rights of SchooIT, including, without limitation, intellectual property rights, rights of publicity, confidentiality, or privacy.
                <br/><br/>
                4. USER REGISTRATION<br/><br/>
                You may be required to register with SchooIT in order to access and use certain features of the Site. If you choose to register for the Site, you agree to provide and maintain true, accurate, current and complete information about yourself as prompted by the Site’s registration form. Registration data and certain other information about you are governed by our Privacy Policy. If you are under 18 years of age, you are not authorized to register for the Site or use the Site.
                <br/><br/>
                5. FEES AND PAYMENT
                <br/><br/>
                Certain features of the Site may be provided for a fee or other charge. If you elect to use paid features of the Site, you agree to the pricing and payment listed on the Site which we may update from time to time. SchooIT may add new services for additional fees and charges, or terminate a particular service at any time in its sole discretion.
                <br/><br/>
                6. CANCELLATION
                <br/><br/>
                You may cancel your SchooIT account at any time by contacting us at [email protected]
                <br/><br/>
                7. PROHIBITED ACTIVITIES
                <br/><br/>
                The Site may be used only for lawful purposes and in a lawful manner. You agree to comply with all applicable laws, statutes and regulations regarding the Site and any transactions conducted on or through the Site.

                You agree not to:

                Use the Site or its content:
                In any way that breaches any applicable local, national or international law or regulation.
                In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.
                To send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content Standards set out in these Terms of Service.
                To transmit, or procure the sending of, any unsolicited or unauthorized advertising or promotional material or any other form of similar solicitation (spam).
                To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.
                Interfere with any other person’s use or enjoyment of the Site.
                Breach any standards, content policies or codes promulgated by any relevant authority.
                Interfere or attempt to interfere with the proper working of the Site or any activities conducted on the Site.
                Bypass, circumvent or attempt to bypass or circumvent any measures we may use to prevent or restrict access to the Site.
                <br/><br/>
                8. LINKS FROM THE SITE
                <br/><br/>
                Where the Site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.

                We have no control over the contents of those sites or resources.
                <br/><br/>
                9. DISCLAIMER OF WARRANTIES
                <br/><br/>
                Your use of the Site is at your sole risk. The Site is provided on an “AS IS” and “AS AVAILABLE” basis. The Site is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.


                SchooIT its subsidiaries, affiliates, and its licensors do not warrant that a) the Site will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Site is free of viruses or other harmful components; or d) the results of using the Site will meet your requirements.
                <br/><br/>
                10. LIMITATION OF LIABILITY
                <br/><br/>
                In no event will SchooIT, its affiliates, officers, directors, employees, agents, suppliers or licensors be liable to you for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Site; (ii) any conduct or content of any third party on the Site; (iii) any content obtained from the Site; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.

                In no event will SchooIT’s aggregate liability for all claims relating to the Site exceed the greater of one hundred U.S. dollars ($100.00) or the amount you have paid us in the past twelve months.

                The limitations of this section shall apply to any theory of liability, whether based on warranty, contract, tort (including negligence), strict liability or any other legal theory, and whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
                <br/><br/>
                11. EXCLUSIONS
                <br/><br/>
                Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.
                <br/><br/>
                12. GOVERNING LAW
                <br/><br/>
                These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Switzerland.
                <br/><br/>
                13. CHANGES TO TERMS OF SERVICE
                <br/><br/>
                We reserve the right, at our sole discretion, to modify or replace these Terms of Service at any time. If a revision is material we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

                By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to access the Site.
                <br/><br/>
                14. CONTACT INFORMATION
                <br/><br/>
                Questions about the Terms of Service should be sent to us at [email protected]
                </Text>

                </Card>
                </ScrollView>
         
                </>
    )
}