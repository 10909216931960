/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
import { JoinGotcha } from "../services/graphQlServices";
import { AmplifyS3Image } from '@aws-amplify/ui-react/legacy';
import { Chip } from "@mui/material";
export default function GotchaElementFeedCustom(props) {
  const { overrides, ...rest } = props;
  console.log({props})

  const joinGotcha = async () => {
    console.log({props})
    await JoinGotcha(props.props.id)
  }




  return (
    <>
      
    <Flex
      gap="0"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "GotchaElementFeed")}
    >
      <Flex direction={'column'}>
      <Flex
            gap="10px"
            width="fit-content"
            alignItems="flex-start"
            
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 2934682769")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="700"
              color="rgba(217,217,217,1)"
              lineHeight="16px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="1 Minute ago"
              {...getOverrideProps(overrides, "1 Minute ago")}
            ></Text>
          </Flex>
      <Flex 
      justifyContent={"center"}
      
      >
         
        {/* <img src={"data:image/jpeg;base64, "+image} width="60%" alt="image" /> */}
        {/* <img src={imagePreview} width="60%" alt="image" /> */}
        <AmplifyS3Image  imgProps={{style: {"border-radius": "50%"}}} style={{"--width": "90%"}} imgKey={props.props.id} />
      </Flex>
      
      <Flex
        gap="0"
        width="fit-content"
        alignItems="center"
        shrink="0"
        height="84px"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 421")}
      >
        
        <Flex
          gap="0px"
          direction="column"
          shrink="0"
          height="84px"
          position="relative"
          padding="0px 0px 0px 20px"
          {...getOverrideProps(overrides, "Frame 2934682795")}
        >
       
          
         
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="15px 15px 15px 15px"
            {...getOverrideProps(overrides, "Frame 2834682757")}
          >
            <Flex
              gap="12px"
              width="fit-content"
              justifyContent="center"
              alignItems="flex-start"
              shrink="0"
              height="37px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 2834682767")}
            >
              
              
              {props.props.profilPicture ? 
                <AmplifyS3Image imgProps={{style: {"border-radius": "50%"}}} style={{"--width": "37px"}} imgKey={props.props.profilPicture} />
                :
              <Image
                width="37px"
                height="37px"
                shrink="0"
                position="relative"
                borderRadius="160px"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "image")}
              ></Image>}
              <Flex
                gap="0"
                direction="column"
                shrink="0"
                height="34px"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "NameLocation")}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="18px"
                  fontWeight="800"
                  color="rgba(139, 0, 139,1)"
                  lineHeight="16.5px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0px"
                  shrink="0"
                  maxWidth={65}
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Frank"
                  {...getOverrideProps(overrides, "Frank")}
                ></Text>
                <Text
                  fontFamily="Inter"
                  fontSize="10px"
                  fontWeight="400"
                  color="rgba(0,0,0,1)"
                  lineHeight="16.5px"
                  textAlign="left"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0px"
                  width="100px"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Basel"
                  {...getOverrideProps(overrides, "Basel")}
                ></Text>
                
              </Flex>
            </Flex>
          
          </Flex>
        </Flex>
        <Flex
          gap="4px"
          direction="column"
          alignItems="stretch"
          shrink="0"
          height="33px"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 2834682796")}
        >
          <Button
            display="flex"
            margin="-20px 0px 0px 10px"
            gap="0"
            width="127px"
            height="33px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            size="small"
            isDisabled={false}
            variation="primary"
            children="Join"
            onClick={joinGotcha}
            {...getOverrideProps(overrides, "Button")}
          ></Button>
        </Flex>
        <Flex position="absolute" margin="40px 0px 0px 0px">
          <Text
                  /* fontFamily="Chilanka" */
                  fontSize="11px"
                  fontWeight="600"
                  color="rgba(139,0,139,1)"
                  lineHeight="16.5px"
                  textAlign="center"
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  letterSpacing="0px"
                  width="350px"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 200px"
                  marginTop={0}
                  whiteSpace="pre-wrap"
                  children="Basel"
                  {...getOverrideProps(overrides, "Interests")}
                ></Text>
                {/* <Chip label="Chip Filled" /> */}
          </Flex>
        
      </Flex>
      
      </Flex>
    </Flex>
    
    
    </>
  );
}
