import React, { useContext, useEffect, useRef, useState } from 'react';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, ChannelHeader, MessageInput, MessageList, Thread, Window, LoadingIndicator, ChannelList, useMessageContext, Attachment, useChatContext, useChannelStateContext, TypingIndicator, Avatar, ChannelPreviewMessenger, VirtualizedMessageList, MessageStatus, MessageOptions, ReactionsList, ReactionSelector, MessageText, MML, MessageErrorIcon, MessageRepliesCountButton, MessageTimestamp, messageHasReactions, SimpleReactionsList } from 'stream-chat-react';
import './../styles/chat.scss'
import 'stream-chat-react/dist/css/v2/index.css';


/* import './../layout.css' */

import { GlobalContext } from "../Context/globalState";
import { GetUser } from '../services/graphQlServices';
import { Flex, Text } from '@aws-amplify/ui-react';

import { AmplifyS3Image } from '@aws-amplify/ui-react/legacy';
import { useComponentWillUnmount } from '../hooks/lifecycleHooks';


/* import './customMessage.css'; */
const userToken0 = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiZGVsaWNhdGUtZmVhdGhlci0zIn0.PVhSPgu5TkWJDWwTHnjK9t20US0VESZToOBuEpuODlY';
let demoUserToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiODI1YzVlNTQtMzc0Mi00NTg5LThhMTQtMmZkZTUzMGZkNDcwIn0.DA6k0qHVeVhraGwf7rKQ1MjCiHz7dpT9Yohvm4-CIp0'
const userToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoieW91bmctZGV3LTcifQ.AXEIbVvYNH0WJXl2Iax2l7c4EXWuadc15z4D_yx3OQs"

let demoUser = {
  id: 'init',
  name: "testUSer",
  image: 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=100'

}

let customChannelId = '827440c-4032-722c-a54-5a773ff2e720'

const user = {
  id: 'young-dew-7',
  name: 'young-dew-7',
  image: 'https://getstream.io/random_png/?id=young-dew-7&name=young-dew-7',
}
const userTokenMorning = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibW9ybmluZy1zaGFwZS0xIn0.LrTTSiTnq6Ia3qO3PHRGrOEYbKKQ6mPqzrPGYIKuGYo"
const user1 = {
  id: 'morning-shape-1',
  name: 'morning-shape-1',
  image: 'https://getstream.io/random_png/?id=young-dew-7&name=young-dew-7',
}
const userTokenDelicate = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZGVsaWNhdGUtZmVhdGhlci0zIn0.CdvV4b6Zop_Sh2EK1hbMpyW15GB6EVerRF8WvcEamQ0"
const user2 = {
  id: 'delicate-feather-3',
  name: 'delicate-feather-3',
  image: 'https://getstream.io/random_png/?id=young-dew-7&name=young-dew-7',
}





const sort = { last_message_at: -1 };
const options = { limit: 10 }

export default function BodyChat (props){
  const {chatState, isConnected, isConnectedFunction, setChatState} = useContext(GlobalContext)
 /*  const chatClient = new StreamChat('upcx4nnrvqgr');
  console.log({chatClient})
  let filters = { members: { $in: [ props.user.id ] } }
let connect = chatClient.connectUser({id:props.user.id, name:props.user.name, image: props.user.profilPicture},  props.user.chatToken).then(el => {
  console.log({el})
}); */

// ✅ You should disconnect user once you are done with chatClient
const [chatClientState, setChatClientState] = useState(chatState);
const chatClient = StreamChat.getInstance('upcx4nnrvqgr');
let filters = { members: { $in: [ props.user.id ] } }


/* const Users = () => {
  const { channel } = useChannelStateContext();
  const [channelUsers, setChannelUsers] = useState([]);

  useEffect(() => {
    const updateChannelUsers = () => {
      setChannelUsers(
        Object.values(channel.state.members).map((user) => ({
          name: user.user_id,
          online: user.user.online,
        })),
      );
    };

    updateChannelUsers();
  }, [chatClient, channel]);

  return (
    <ul className='users-list'>
      {channelUsers.map((member) => (
        <li key={member.name}>
          
          {member.name} - {member.online ? 'online' : 'offline'}
        </li>
      ))}
    </ul>
  );
}; */

/* const CustomChannelHeader = (props: ChannelHeaderProps) => {
  const { title } = props;

  const { channel } = useChannelStateContext();
  const { name } = channel.data || {};

  return (
    <div className='str-chat__header-livestream'>
      <div>
        <div className='header-item'>
          <span className='header-pound'>#</span>
          {title || name}
        </div>
        <TypingIndicator />
      </div>
    </div>
  );
}; */

const [channelsNo, setChannelsNo] = useState(0)

async function getChannels(){
  let channels = await chatClient.queryChannels(filters, sort, options)
  setChannelsNo(channels.length)
  console.log({channelsNo})
}

const CustomAvatar = (props) => {
  console.log({props})
  let img = "https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/0c6c4d6-6a7c-27-54cb-3f8872a3717f"
  return <Avatar image={img} shape='square' size={50} />;
};
  
  useEffect(() => {
    
    console.log({chatState})
    console.log({isConnected})
    console.log({props})
    console.log('useEffect')
    console.log({demoUser})
    const initChat = async () => {
      // open the WebSocket connection to start receiving events
      await chatClient.connectUser({id:props.user.id},  props.user.chatToken).then(el => {
        console.log({el})
      });
      setChatState(chatClient);
      setChatClientState(chatClient);
    }
    console.log({chatClientState})
    if(chatState === null){
       initChat();
      getChannels()
      /* isConnectedFunction(true) */
    }
   
    // close the WebSocket connection when component dismounts
  /*   if(!props.user.id){
      return () => chatClient.disconnectUser();
    } */
  }, [])

 /*  useComponentWillUnmount(() => {
    console.log('chatdismounted')
    return () => chatClient.disconnectUser();
  }); */
  /* if (demoUser.id === 'init') {
    return null; // render nothing until connection to the backend is established
  } */
   const CustomMessage = () => {
    const {
      isReactionEnabled,
      message,
      reactionSelectorRef,
      showDetailedReactions,
    } = useMessageContext();
  
    const messageWrapperRef = useRef(null);
  
    const hasReactions = messageHasReactions(message);
    const hasAttachments = message.attachments && message.attachments.length > 0;
  
    return (
      <div className='message-wrapper'>
        <CustomAvatar />
        <div className='message-wrapper-content'>
          <MessageOptions messageWrapperRef={messageWrapperRef} />
          <div className='message-header'>
            <div className='message-header-name'>{message.user?.name}</div>
            <div className='message-header-timestamp'>
              <MessageTimestamp />
            </div>
          </div>
          {showDetailedReactions && isReactionEnabled && (
            <ReactionSelector ref={reactionSelectorRef} />
          )}
          <MessageText />
          <MessageStatus />
          {hasAttachments && <Attachment attachments={message.attachments} />}
          {hasReactions && !showDetailedReactions && isReactionEnabled && <SimpleReactionsList />}
          <MessageRepliesCountButton reply_count={message.reply_count} />
        </div>
      </div>
    );
  };
  
  return (
    <>
    {chatClientState ? 
   
  
      <Chat client={chatClientState} theme='str-chat__theme-light'>
     
      <ChannelList filters={filters} sort={sort} options={options} /* Preview={(props) => <ChannelPreviewMessenger {...props} Avatar={CustomAvatar} />} *//>
    <Channel /* Avatar={CustomAvatar}  *//* Message={CustomMessage} */ /* channel={channel} */ >
      <Window>
        <ChannelHeader />
        {/* <Users /> */}
        {/* <CustomChannelHeader /> */}
          
        <MessageList />
        <MessageInput focus />
      </Window>
      <Thread />
    </Channel>
  </Chat> 
  
  : 
   
   <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
          margin={"2rem"}
        >
          
         
          <Text color={"darkgray"} style={{fontSize:20}} ><b>No Chats yet...</b><br/>  </Text>
         
         
          <Text color={"darkblue"} style={{fontSize:14}} ><b>Go to "Feed" to create or join a Gotchaaa, afterwards you will see your chats here.</b><br/> </Text>
         
       </Flex>
  }
    
  </>
  /*  <Chat client={chatClient}>
   <ChannelList filters={filters} sort={sort} options={options} showChannelSearch />
   <Channel>
     <Window>
       <ChannelHeader />
       <MessageList />
       <MessageInput focus />
     </Window>
     <Thread />
   </Channel>
 </Chat> */
  )
}