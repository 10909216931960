/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, { useContext, useState } from "react";
import { getOverrideProps, IconClose, useNavigateAction } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, ScrollView, SearchField, SwitchField, Text } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { AmplifyS3Image, AmplifyS3ImagePicker } from '@aws-amplify/ui-react/legacy';
import { Auth, Storage } from "aws-amplify";
import uuid from 'react-uuid';
import { useEffect } from "react";
import Friend from "./Friend";
import { CreateGotcha, JoinGotcha } from "../services/graphQlServices";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useLocation } from 'react-router';


import Confetti from 'react-confetti'
import { getImage } from "../services/getters";
import { GlobalContext } from "../Context/globalState";


/* import {Close} from 'react-icons/md';` */

export default function AddGotchaCustomized(props) {
  
  const { myProfile, loadMyProfile, loadMyGotchaaas, stateChange } = useContext(GlobalContext);
  console.log({props})
  const width = window.screen.width
  const height = window.screen.height
  const { overrides, ...rest } = props;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const navigate = useNavigate();

  const [user1, setUser1] = useState("");
  const [joinGotcha1, setjoinGotcha1] = useState(true /* props.props */);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setshowAddFriends(false)
    setjoinGotcha1(false)
  };
  
  const [openF, setOpenF] = useState(false);
  const handleOpenF = () => setOpenF(true);
  const handleCloseF = () => setOpenF(false);
  const [mainPicture, setmainPicture] = useState("")
  const [frontPicture, setfrontPicture] = useState("")
  const [timepassed, settimepassed] = useState(false)
  const [newgotcha, setnewgotcha] = useState("")
  const [newgotchaF, setnewgotchaF] = useState("")
  const [existingGotchaM, setexistingGotchasM] = useState("")
  const [existingGotchaF, setexistingGotchasF] = useState("")
  const [gotchaIdState, setgotchaIdState] = useState("")
  
  const [showAddFriends, setshowAddFriends] = useState(false)
  const [addedFriends, setaddedFriends] = useState([1,2,3,4,5])
  const [gotchaObj, setgotchaObj] = useState({user: "", gotchaId: "false", friendsOnly: false, friends: [], mainPicture: "", frontPicture: "", gotchaId: ""})
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarText, setsnackbarText] = React.useState("false");
  const [loadingM, setloadingM] = useState(false)
  const [loadingF, setloadingF] = useState(false)
  const [loading, setloading] = useState(false)
  const [confetti, setconfetti] = useState(false)
  /* const { width, height } =  */
 
  

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };
  const navigation = useNavigate()
  
  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const action = (
    <React.Fragment>
      
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
      <IconClose />
      </IconButton>
    </React.Fragment>
  );

  async function clearDataStore(){
    await DataStore.clear()
  }

async function createGotcha(){
  /* if(){
    return handleClickSnackbar()
  } */
  setloading(true)
  console.log({user1})
  
  console.log({mainPicture})
  console.log({frontPicture})
  console.log({newgotcha})
  console.log({newgotchaF})
  
  
  if(mainPicture != "" || newgotcha != ""){
    if(frontPicture != "" || newgotchaF != ""){
     /* await setgotchaObj(gotchaObj => { 
        let newState = {...gotchaObj}
        newState.user = user1

        newState.mainPicture = mainPicture
        if(newgotcha != ""){
          newState.mainPicture = newgotcha
        }

        newState.frontPicture = frontPicture
        if(newgotcha != ""){
          newState.frontPicture = newgotchaF
        }
        
        return newState
      }) */
      let newState = {...gotchaObj}
      
      
        newState['gotchaId'] = gotchaIdState
      
      newState.user = user1
      
      
      newState['timestamp'] = String(Date.now())
      newState['timestampEnd'] = Number(Date.now())+4*60*60*1000


      newState.mainPicture = mainPicture
      if(newgotcha != ""){
        newState.mainPicture = newgotcha
      }

      newState.frontPicture = frontPicture
      if(newgotcha != ""){
        newState.frontPicture = newgotchaF
      }
      console.log({newState})
    
      let response = await CreateGotcha(newState).then(approve => {
        console.log(approve.data.createGotcha)
        let res = approve.data.createGotcha
        setloading(false)
        switch (res) {
          case "bad":
            
            break;

          case "success":
            console.log({res})
            setsnackbarText("Success! Your are automatically forwarded...")
            setOpenSnackbar(true);
            setconfetti(true)
            
            setTimeout(() => {
              loadMyGotchaaas()
              navigation("/gotchaaa");
            }, 4000)
           break;
          case "frontPictureNotAllowed":
            console.log({res})
            setsnackbarText("Error! Front picture declined, upload a new photo...")
            setOpenSnackbar(true);
            
           
           break;
          case "mainPictureNotAllowed":
            console.log({res})
            setsnackbarText("Error! Main picture declined, upload a new photo...")
            setOpenSnackbar(true);
            
           break;
          case "bothPicturesNotAllowed":
            console.log({res})
            setsnackbarText("Error! Both pictures declined, upload new photos...")
            setOpenSnackbar(true);
           
           break;
          default:
            break;
        }

      })
      
      

    } else {
      setsnackbarText("Upload front image.")
      setOpenSnackbar(true);
    }
  } else {
    setsnackbarText("Upload main image.")
    setOpenSnackbar(true);
  }
  
  /* setgotchaObj({...gotchaObj}, user: "{user1}") */
  
  
}
async function joinGotcha(){
  /* if(){
    return handleClickSnackbar()
  } */
  setloading(true)
  console.log({user1})
  
  console.log({mainPicture})
  console.log({frontPicture})
  console.log({newgotcha})
  console.log({newgotchaF})
  
  
  if(mainPicture != "" || newgotcha != ""){
    if(frontPicture != "" || newgotchaF != ""){
     /* await setgotchaObj(gotchaObj => { 
        let newState = {...gotchaObj}
        newState.user = user1

        newState.mainPicture = mainPicture
        if(newgotcha != ""){
          newState.mainPicture = newgotcha
        }

        newState.frontPicture = frontPicture
        if(newgotcha != ""){
          newState.frontPicture = newgotchaF
        }
        
        return newState
      }) */
      let newState = {...gotchaObj}
      
      
        newState['challengeId'] = props.props.id
        if(props.props.frontPicBlurred){
          newState['challengeId'] = props.props.challenge
        }
      
      newState.user = user1
      
      
      newState['timestamp'] = String(Date.now())
      newState['timestampEnd'] = Number(Date.now())+1*60*60*1000


      newState.mainPicture = mainPicture
      if(newgotcha != ""){
        newState.mainPicture = newgotcha
      }

      newState.frontPicture = frontPicture
      if(newgotcha != ""){
        newState.frontPicture = newgotchaF
      }
      console.log({newState})
    
      let response = await JoinGotcha(newState).then(approve => {
        console.log(approve.data.joinGotcha)
        let res = JSON.parse(approve.data.joinGotcha)
        console.log({res})
        setloading(false)
        switch (res.msg) {
          case "bad":
            
            break;

          case "success":
            console.log({res})
            setsnackbarText("Success! Your are automatically forwarded...")
            setOpenSnackbar(true);
            setconfetti(true)
            
            setTimeout(async () => {
              await loadMyGotchaaas()
              navigate('/gotchaaa/'+res.challengeId+'/'+res.challengerId);
              
              /* navigation("/gotchaaa/"+res.challengeId+"/"+res.challengerId); */
            }, 4000)
           break;
          case "frontPictureNotAllowed":
            console.log({res})
            setsnackbarText("Error! Front picture declined, upload a new photo...")
            setOpenSnackbar(true);
            
           
           break;
          case "mainPictureNotAllowed":
            console.log({res})
            setsnackbarText("Error! Main picture declined, upload a new photo...")
            setOpenSnackbar(true);
            
           break;
          case "bothPicturesNotAllowed":
            console.log({res})
            setsnackbarText("Error! Both pictures declined, upload new photos...")
            setOpenSnackbar(true);
           
           break;
          default:
            break;
        }

      })
      
      

    } else {
      setsnackbarText("Upload front image.")
      setOpenSnackbar(true);
    }
  } else {
    setsnackbarText("Upload main image.")
    setOpenSnackbar(true);
  }
  
  /* setgotchaObj({...gotchaObj}, user: "{user1}") */
  
  
}

function addFriends(){
  setshowAddFriends(!showAddFriends)
}

  async function getUser(){
    if(props.props){
      setgotchaIdState(props.props.id)
    } else {
      let uuid1 = uuid()
      console.log({uuid1})
      setgotchaIdState(uuid1)
    }
   
    const user1 = await Auth.currentAuthenticatedUser().then(el => {
      
      
      setUser1(el.username)
      
    })
  }
  async function checkPicture(m,f){
    let hasKeyM = await Storage.list(m)
    let hasKeyF = await Storage.list(f)
    
    setexistingGotchasM(hasKeyM)
    setexistingGotchasF(hasKeyF)
      console.log({hasKeyM})
  }

  async function checkPictureM(m){
    let hasKeyM = await Storage.list(m)
    setexistingGotchasM(hasKeyM)
  }
  async function checkPictureF(m){
    let hasKeyM = await Storage.list(m)
    setexistingGotchasF(hasKeyM)
  }
  
  useEffect(()=> {
    getUser()
    
  },[])


 

     function namePicture(){
      setnewgotcha("")
      let uuid2 = uuid()
      setmainPicture(uuid2)

      setOpen(false)
       timer(uuid2)
      console.log(uuid2)
      
      return uuid2
    }
     function namePictureF(){
      setnewgotchaF("")
      let frontPicId = uuid()
      
      setfrontPicture(frontPicId)

      setOpenF(false)
       timerF(frontPicId)
      console.log(frontPicId)
      
      return frontPicId
    }

    function timer(newGotchaKey){
      setloadingM(true)
      
      setTimeout(async () => {
        await getImage(newGotchaKey).then(el => {
          console.log(el)
          if(el === false){
            timer(newGotchaKey)
          } else {
            setnewgotcha(newGotchaKey)
            setloadingM(false)
          }
        })
        
    }, 2000);
  }
    function timerF(newGotchaKey){
      setloadingF(true)
     
      setTimeout(async () => {
        await getImage(newGotchaKey).then(el => {
          console.log(el)
          if(el === false){
            timerF(newGotchaKey)
          } else {
            setnewgotchaF(newGotchaKey)
            setloadingF(false)
          }
        })
    
    }, 2000);
  }

   

   
  
  return (
    <>
    
   {confetti && <Confetti
      width={width}
      height={height}
    />}
    
    <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarText}
        action={action}
      />

     <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
        
                <ScrollView>
                <div style={{maxWidth: "375px"}}>
                    
                {/* <StepperHori step={1}/> */}
                <AmplifyS3ImagePicker fileToKey={namePicture} headerTitle="Main Camera" headerHint="Make a picture with your main camera of what is in front of you" />
                
            </div>
            </ScrollView>    
               </Box>
               </Modal>

               <Modal
        open={openF}
        onClose={handleCloseF}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
        
                <ScrollView>
                <div style={{maxWidth: "375px"}}>
                    
                {/* <StepperHori step={1}/> */}
                <AmplifyS3ImagePicker fileToKey={namePictureF} headerTitle="Front Camera" headerHint="Make a picture of yourself with the front camera in the same moment" />
                
            </div>
            </ScrollView>    
               </Box>
               </Modal>
    <Flex
      gap="29px"
      direction="column"
      alignItems="center"
      position="relative"
      padding="0px 16px 0px 16px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "AddGotcha")}
    >
      <Text
        fontFamily="Roboto"
        fontSize="14px"
        fontWeight="700"
        color="rgba(0,0,0,1)"
        lineHeight="16.40625px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Let’s Gotchaaa"
        {...getOverrideProps(overrides, "Let\u2019s Gotchaa")}
      ></Text>
      <Flex
        gap="-47px"
        direction="column"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 425")}
      >
        <Flex
          gap="0"
          direction="column"
          width="341px"
          height="284px"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "buttonBack")}
        >
         {mainPicture === "" && <Image
            width="303px"
            height="284px"
            shrink="0"
            position="relative"
            style={{"border-radius": "50%"}}
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "image34803798")}
          ></Image>}
         {loadingM && <CircularProgress />}
          {newgotcha != "" &&
            
            <Flex
          gap="0"
          direction="column"
          width="341px"
          height="284px"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 10%"
          {...getOverrideProps(overrides, "buttonBack")}
        >
            <AmplifyS3Image imgProps={{style: {"border-radius": "50%"}}} style={{"--width": "90%","--height": "100%"}} imgKey={newgotcha} />
            </Flex>
            }
            
          <MyIcon
            width="55px"
            height="55px"
            shrink="0"
            overflow="hidden"
            position="relative"
            padding="0px 0px 0px 0px"
            type="more_horiz"
            margin="-167 0 0 0"
            onClick={handleOpen}
            {...getOverrideProps(overrides, "MyIcon34803805")}
          ></MyIcon>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          position="relative"
          borderRadius="160px"
          marginTop="-75px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "buttonFront")}
        >
            {frontPicture === "" && <Image
            width="99px"
            height="95px"
            shrink="0"
            position="relative"
            borderRadius="50%"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "image34803799")}
            ></Image>
         }
            {/* {frontPicture != "" &&
             <AmplifyS3Image style={{"--width": "100px"}} imgKey={frontPicture} />
            } */}
            {loadingF && <CircularProgress />}

            {newgotchaF != "" &&
              <AmplifyS3Image  imgProps={{style: {"border-radius": "50%"}}} style={{"--width": "100px", "--height": "100px"}} imgKey={newgotchaF} />
            }
         
          <MyIcon
            width="55px"
            height="55px"
            shrink="0"
            overflow="hidden"
            position="relative"
            padding="0px 0px 0px 0px"
            type="more_horiz"
            margin="-60 0 0 0"
            onClick={handleOpenF}
            {...getOverrideProps(overrides, "MyIcon34803803")}
          ></MyIcon>
        </Flex>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "postPublicFrame")}
      >
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "postPublic")}
        >
        {/*   {!joinGotcha1 && !showAddFriends && <SwitchField
            display="flex"
            gap="8px"
            width="fit-content"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="8px 8px 8px 8px"
            label="Post to Gotchaaa Platform"
            size="default"
            defaultChecked={true}
            isDisabled={false}
            labelPosition="start"
            onChange={addFriends}
            {...getOverrideProps(overrides, "SwitchField")}
          ></SwitchField>
            } */}

         {/*  {showAddFriends && <SwitchField
            display="flex"
            gap="8px"
            width="fit-content"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="8px 8px 8px 8px"
            label="Post only to selected friends"
            size="default"
            defaultChecked={false}
            isDisabled={false}
            labelPosition="start"
            onChange={addFriends}
            {...getOverrideProps(overrides, "SwitchField")}
          ></SwitchField>} */}
          
          <Flex
            padding="0px 0px 0px 0px"
            width="248px"
            height="14px"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Public")}
          >
            {!showAddFriends &&<Text
              fontFamily="Roboto"
              fontSize="12px"
              fontWeight="100"
              color="rgba(0,0,0,1)"
              lineHeight="14.0625px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              position="absolute"
              top="0px"
              left="0px"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Get engaged with new, cool people on Gotchaaa"
              {...getOverrideProps(
                overrides,
                "Get engaged with new, cool people on Gotchaaa"
              )}
            ></Text>}
           {/*  {showAddFriends && <Text
              fontFamily="Roboto"
              fontSize="12px"
              fontWeight="100"
              color="rgba(0,0,0,1)"
              lineHeight="14.0625px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              position="absolute"
              top="0px"
              left="0px"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="This Gotchaaa is sent only to your selected friends"
              {...getOverrideProps(
                overrides,
                "This Gotchaaa is sent only to your selected friends"
              )}
            ></Text>} */}
            
          </Flex>
          
            
        {/*   {showAddFriends &&
           <>
           <Flex margin={15}>
           <SearchField />
           </Flex>
           
           <Flex width={350}>
           
           <ScrollView>
           <Flex>
           
           {addedFriends.map(el => {
            return(  
              <Flex>
              <Friend />
              </Flex>
              )
           })}
           </Flex>
           </ScrollView>
           </Flex>
           </>
          } */}
          
        </Flex>
      </Flex>
      <Flex
        gap="4px"
        direction="column"
        alignItems="stretch"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "SendGotcha")}
      >
        {props.props ?
        <Button
        display="flex"
        gap="0"
        width="127px"
        height="33px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        size="small"
        isDisabled={false}
        variation="primary"
        children="Join"
        onClick={joinGotcha}
        {...getOverrideProps(overrides, "ButtonJoin")}
      ></Button>
            :
            <Button
            display="flex"
            gap="0"
            width="127px"
            height="33px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            size="small"
            isDisabled={false}
            variation="primary"
            children="Join Now"
            onClick={createGotcha}
            {...getOverrideProps(overrides, "Button")}
          ></Button>

        }
        {loading && <CircularProgress />}
       
       {/*  <Text
          fontFamily="Inter"
          fontSize="10px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="15px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          width="110px"
          height="18px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Only 120 seconds left"
          {...getOverrideProps(overrides, "Noch 120 Sekunden")}
        ></Text> */}
      </Flex>
    </Flex>
    
    </>
  );
}
