
// components/Login.js
import { useContext, useEffect, useState } from "react";

import { Authenticator, Button, Text, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';
import CarouselGotchaaa from "./carouselGotchaaa";
import { InitDataWrapper } from "../routes/InitLoadWrapper";
import { GlobalContext } from "../Context/globalState";
import Header from "./header";
import { AddToWaitingList, GetReferal, GetUser } from "../services/graphQlServices";

import * as getters from "../services/getters";
import { Input } from "@mui/material";

export function Login1() {
  const { route } = useAuthenticator((context) => [context.route]);
  const {saveReferalId ,myProfile, isRegisteredFunction, loadMyProfile, loadAvailableGotchaaas, stateChangeFunction, loadMyGotchaaas} = useContext(GlobalContext);
  const location = useLocation();
  const [referal, setReferal] = useState(false)
  const [email, setEmail] = useState("")
  const [emailSent, setemailSent] = useState(false)
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/feed';
  const formFields = {
    hideSignUp: true    
  }

  async function checkRegistrationLink(){
    let pathname = window.location.pathname
  console.log({pathname})
    let pathnameArray = pathname.split("/")
    let refId = pathnameArray[2]
    console.log({pathnameArray})
   
    let challengeResponse1 = await GetReferal(refId)
    console.log({challengeResponse1})
    if(challengeResponse1 && challengeResponse1.data){
    let customChallengeResponseData = challengeResponse1.data.getReferal
    if(customChallengeResponseData.allowedInvites > 0){
      saveReferalId(refId)
      setReferal(true)
    }
    console.log({customChallengeResponseData})
  }
  }

  async function decidePath(){
    let GetCurrentUser = await getters.GetCurrentUser()
    console.log({GetCurrentUser})
    let user = await GetUser(GetCurrentUser.username)
    console.log(user)
    if(user.data.getUser){
      loadMyProfile()
      loadAvailableGotchaaas()
      loadMyGotchaaas()
      isRegisteredFunction(true)
      navigate(from)
    } else {
      navigate('/register')
    }
  }

  async function AddToWaitingListLocal(e){
    console.log({email})
    console.log({e})
    setemailSent(true)
    let response = await AddToWaitingList(email)
  }

  useEffect(() => {
    if (route === 'authenticated') {
      decidePath()
     /*  navigate(from, { replace: true });
      loadMyProfile();
      loadAvailableGotchaaas();
      loadMyGotchaaas(); */
      /* window.location.reload(); */
      
    } else {
      checkRegistrationLink()
    }
    
  }, [route, navigate, from]);
  

  
 
  

  return (
    <>
    {/* <CarouselGotchaaa/> */}
    {/* <Header width="40%"/> */}
    {/* <Text style={{margin: "1em", fontSize:"2em"}} className="auth-title">Login</Text> */}
    <View className="auth-wrapper">
      
      {!referal && <Authenticator hideSignUp /*  formFields={formFields} */></Authenticator>}
      {referal &&  <Authenticator /*  formFields={formFields} */></Authenticator>}
    </View>
    
    {!emailSent ?
    <>
    <Text style={{margin: "1em"}} className="auth-title">Gotchaaa is invite only. If you know someone registered, ask them to share their referal link.
    <br/><br/>
    Alternatively, you can sign up to the waiting list.
    </Text>
    <Input
        placeholder="Email"
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      /> <br/>
      <Button style={{margin:"1em"}} onClick={() =>  AddToWaitingListLocal()}>Join Waiting List</Button>
      
      {/* <Text style={{margin: "1em"}} className="auth-title">X-mas countdown, we are giving away 1000 invite links.</Text> */}
      </>
    :
    <Text style={{margin: "1em"}} className="auth-title">Your email has been successfully added to the waiting list. We will let you know when you can join.</Text>
}

    </>
    
  );
}