import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FolderIcon from '@mui/icons-material/Folder';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import ChatIcon from '@mui/icons-material/Chat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import PreviewIcon from '@mui/icons-material/Preview';

import {
    getOverrideProps,
    useNavigateAction,
  } from "@aws-amplify/ui-react/internal";
import { Link } from 'react-router-dom';

export default function MenuPublic(page) {
    console.log({page})
  const [value, setValue] = React.useState(page.page);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const homeOnClick = useNavigateAction({ type: "url", url: "/" });
  const gotchaaasOnClick = useNavigateAction({ type: "url", url: "/gotchaaa" });
  const chatOnClick = useNavigateAction({ type: "url", url: "/chat" });
  const profileOnClick = useNavigateAction({ type: "url", url: "/profile" });

  return (
    
    <BottomNavigation /* sx={{ width: "100%" }} */ value={value} onChange={handleChange} >
       <Link to="/" style={{width: "25%"}}>
        <BottomNavigationAction
          size="large"
          label="Home"
          value="home"
          icon={<HomeIcon />}
        />
       </Link>
       <Link to="/previewFeed" style={{width: "25%"}}>
        <BottomNavigationAction
          size="large"
          label="Feed"
          value="feed"
          icon={<PreviewIcon />}
        />
        </Link>
       <Link to="/login" style={{width: "25%"}}>
        <BottomNavigationAction
          size="large"
          label="Home"
          value="home"
          icon={<LoginIcon />}
        />
       </Link>
       <Link to="/privacy-declaration" style={{width: "25%"}}>
        <BottomNavigationAction
          size="large"
          label="Privavy Declaration"
          value="privacy"
          icon={<VerifiedUserIcon />}
        />
       </Link>
       <Link to="/imprint" style={{width: "25%"}} className='link'>
      <BottomNavigationAction
        label="Imprint"
        value="imprint"
        icon={<NotListedLocationIcon />}
      />
      </Link>
      
      
    </BottomNavigation>
    
  );
}
