import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../Context/globalState";


export const InitLoaderFunction = async () => {
  const { loadAvailableGotchaaas, loadMyGotchaaas, loadMyProfile } = useContext(GlobalContext);
  const [isLoaded, setIsLoaded] = useState(false);


    loadAvailableGotchaaas();
    loadMyGotchaaas();
    loadMyProfile()
    setIsLoaded(true);
    

    


  return isLoaded
}