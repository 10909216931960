import { Button, Flex, Grid, ScrollView, View } from "@aws-amplify/ui-react";
import BodyGotchaaa from "../components/bodyGotchaaa";
import BodyHome from "../components/bodyHome";
import BodyChat from "../components/bodyChat";

import Footer from "../components/footer";
import Header from "../components/header";
import BodyProfile from "../components/bodyProfile";
import { AddGotcha } from "../ui-components";
import { AmplifyS3ImagePicker } from '@aws-amplify/ui-react/legacy';

import { Auth } from "aws-amplify";
import StepperHori from "../components/stepper";
import { Divider, TableRow } from "@mui/material";

import { GetUser } from "../services/graphQlServices";
import { useContext, useEffect, useState } from "react";
import { GetCurrentUser } from "../services/getters";
import MenuNew from "../ui-components/MenuNew";
import { GlobalContext } from "../Context/globalState";



export default function MemberArea (props) {
    console.log(props)
    const {myProfile, loadMyProfile, loadMyGotchaaas, myGotchaaas} = useContext(GlobalContext)
    console.log({myProfile})
    const [user, setUser] = useState()

    async function currentUser () {
      let user = await GetCurrentUser()
      console.log(user) 
    
       let userDetails = await GetUser(user.username)
      let userObj =  userDetails.data.getUser
      
      return userObj
        
      }
      
      useEffect(() => {
        let user = currentUser().then(user => {
          console.log({user})
          setUser(user)
        })
        console.log('useeffect')
        console.log({myProfile})
        
        if(myProfile.length === 0){
            loadMyProfile()   
        }
        if(myGotchaaas.length === 0){
            loadMyGotchaaas()   
        }
      }, [])
      

    async function onChange(e) {
        const user = await Auth.currentAuthenticatedUser()
        console.log(user.username)
        const file = e.target.files[0];
        try {
            
          await Storage.put(file.name, file, {
            contentType: "image/png", // contentType is optional
          });
        } catch (error) {
          console.log("Error uploading file: ", error);
        }
      }
    
 /*    const bodyHeight = window.screen.height-100
    const contentHeight = window.screen.height*0.75
    const height = window.screen.height
    const innerHeight = window.innerHeight-150 */
    
    return(
       //center in the middle of the screen
        <>
        <Grid position='relative' /* sx={{ height:{bodyHeight}}}  */ container
  >
    
    <Grid item xs={3}>
       
        
        <TableRow   >
          
                {props.page === "feed" &&  <BodyHome  />}
                {props.page === "feed" && props.pathname &&  <BodyHome pathname={props.pathname} />}
                {props.page === "gotchaaa" &&  <BodyGotchaaa user={user}  />}
                {user && props.page === "chat" &&  <BodyChat user={user} />}
                {user && props.page === "profile" &&  <BodyProfile user={user} />}
               
                {props.page === "frontCam" &&  <input type="file" onChange={onChange} /> }
                {props.page === "newGotcha" &&  
                
                <AddGotcha overrides={{
                  image34803798: {src:"https://images.unsplash.com/photo-1491637639811-60e2756cc1c7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8a25hcHNhY2t8ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60"},
                  image34803799: {src:"https://images.pexels.com/photos/59576/pexels-photo-59576.jpeg?auto=compress&cs=tinysrgb&w=400"}
                }
              } />}
              
            
          </TableRow>
         {/*  <Divider />
          <View >
            <MenuNew  page={props.page} />
            </View> */}
          </Grid>
         {/*  <Footer style={{position:'absolute',
            left:0,
            bottom:0,
            right:0}}/> */}
           
       </Grid>
 
            
      
 
       </>
       
           
            
           
            
           
            
            
           
        
    )
}