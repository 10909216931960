/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, SwitchField, Text } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
export default function AddGotcha(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="29px"
      direction="column"
      alignItems="center"
      position="relative"
      padding="0px 16px 0px 16px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "AddGotcha")}
    >
      <Text
        fontFamily="Roboto"
        fontSize="14px"
        fontWeight="700"
        color="rgba(0,0,0,1)"
        lineHeight="16.40625px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Let’s Gotchaa"
        {...getOverrideProps(overrides, "Let\u2019s Gotchaa")}
      ></Text>
      <Flex
        gap="-47px"
        direction="column"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 425")}
      >
        <Flex
          gap="0"
          direction="column"
          width="341px"
          height="284px"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "buttonBack")}
        >
          <Image
            width="303px"
            height="284px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "image34803798")}
          ></Image>
          <MyIcon
            width="55px"
            height="55px"
            shrink="0"
            overflow="hidden"
            position="relative"
            padding="0px 0px 0px 0px"
            type="more_horiz"
            margin="-167 0 0 0"
            {...getOverrideProps(overrides, "MyIcon34803805")}
          ></MyIcon>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "buttonFront")}
        >
          <Image
            width="99px"
            height="95px"
            shrink="0"
            position="relative"
            borderRadius="160px"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "image34803799")}
          ></Image>
          <MyIcon
            width="55px"
            height="55px"
            shrink="0"
            overflow="hidden"
            position="relative"
            padding="0px 0px 0px 0px"
            type="more_horiz"
            margin="-67 0 0 0"
            {...getOverrideProps(overrides, "MyIcon34803803")}
          ></MyIcon>
        </Flex>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "postPublicFrame")}
      >
        <Flex
          gap="0"
          direction="column"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "postPublic")}
        >
          <SwitchField
            display="flex"
            gap="8px"
            width="fit-content"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="8px 8px 8px 8px"
            label="Post to Gotchaaa Platform"
            size="default"
            defaultChecked={true}
            isDisabled={false}
            labelPosition="start"
            {...getOverrideProps(overrides, "SwitchField")}
          ></SwitchField>
          <Flex
            padding="0px 0px 0px 0px"
            width="248px"
            height="14px"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Public")}
          >
            <Text
              fontFamily="Roboto"
              fontSize="12px"
              fontWeight="100"
              color="rgba(0,0,0,1)"
              lineHeight="14.0625px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              position="absolute"
              top="0px"
              left="0px"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Get engaged with new, cool people on Gotchaaa"
              {...getOverrideProps(
                overrides,
                "Get engaged with new, cool people on Gotchaaa"
              )}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="4px"
        direction="column"
        alignItems="stretch"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "SendGotcha")}
      >
        <Button
          display="flex"
          gap="0"
          width="127px"
          height="33px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          size="small"
          isDisabled={false}
          variation="primary"
          children="Gotchaa"
          {...getOverrideProps(overrides, "Button")}
        ></Button>
        <Text
          fontFamily="Inter"
          fontSize="10px"
          fontWeight="700"
          color="rgba(0,0,0,1)"
          lineHeight="15px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          width="110px"
          height="18px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Noch 120 Sekunden"
          {...getOverrideProps(overrides, "Noch 120 Sekunden")}
        ></Text>
      </Flex>
    </Flex>
  );
}
