import { Button, Flex, Grid, ScrollView, Text, View } from "@aws-amplify/ui-react";
import BodyGotchaaa from "../components/bodyGotchaaa";
import BodyHome from "../components/bodyHome";
import BodyChat from "../components/bodyChat";

import Footer from "../components/footer";
import Header from "../components/header";
import BodyProfile from "../components/bodyProfile";
import { AddGotcha } from "../ui-components";
import { AmplifyS3ImagePicker } from '@aws-amplify/ui-react/legacy';

import { Auth } from "aws-amplify";
import StepperHori from "../components/stepper";
import { Divider, TableRow } from "@mui/material";

import { GetAvailableGotchasPublic, GetUser } from "../services/graphQlServices";
import { useContext, useEffect, useState } from "react";
import { GetCurrentUser } from "../services/getters";
import MenuNew from "../ui-components/MenuNew";
import { GlobalContext } from "../Context/globalState";
import GotchaElementFeedCustom from "../ui-components/GotchaElementFeedCustom";
import GotchaSingleDisplayCustom from "../ui-components/GotchaSingleDisplayCustom";
import { useNavigate } from "react-router-dom";



export default function MemberAreaPublic (props) {
    console.log(props)
    
    
    const { loadAvailableGotchaaas, availableGotchaaas, loadAvailableGotchaaasPublic, loadMyGotchaaas, loadMyProfile, isRegisteredUser, isRegisteredFunction } = useContext(GlobalContext);
    const [availableGotchaaasLocal, setavailableGotchaaasLcal] = useState()
    const innerHeight = window.innerHeight
    const innerWidth = window.innerWidth
    const bodyHeight = window.innerHeight-100
    const navigate = useNavigate();

      async function getPublicGotchaaas () {
        let publicGotchaaas = await loadAvailableGotchaaasPublic()
        console.log({availableGotchaaas})
        setavailableGotchaaasLcal(availableGotchaaas)
      }

      const handleOpenJoinGotcha1 = (item) => {
        console.log({item})
        navigate('/login')
      }
      
      useEffect(() => {
        if(availableGotchaaasLocal == undefined){
          getPublicGotchaaas()
        }
      }, [])
      

   
    
    return(
       //center in the middle of the screen
        <>
        <Grid position='absolute' /* sx={{ height:{bodyHeight}}}  */ container
        style={{
          
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
        }}
  >
    
    <Grid item xs={3} >
       
        
        <TableRow   >
        <ScrollView style={{marginLeft: 10}} width={innerWidth} height={bodyHeight} maxWidth="375px">
        {availableGotchaaas && availableGotchaaas.length > 0 && availableGotchaaas.map((item, index) => {
          let interests = String(item.interests)
          let intString = interests.replaceAll(",", ", ")
          console.log({item})
          
          
          let timeString = (Math.floor((Number(Date.now())-item.timestamp)/(1000*60*60)))+" Hours ago"
          if(item.successfulChallengers && item.successfulChallengers.length > 1){
          timeString = (Math.floor((Number(Date.now())-item.timestamp)/(1000*60*60)))+" Hours ago"+" ("+item.successfulChallengers.length+" successful participants)"
          }
           return (
           <>
           {/*  <amp-ad width="100vw" height="320"
            type="adsense"
            data-ad-client="ca-pub-2644478000888518"
            data-ad-slot="8115052959"
            data-auto-format="rspv"
            data-full-width="">
 <div overflow=""></div>

</amp-ad> */}
{/* <Adsense
  client="ca-pub-2644478000888518"
  slot="8115052959"
/> */}

       

          {item.creatorUid && <GotchaElementFeedCustom style={{marginBottom: 30}} props={item} onClick={() => handleOpenJoinGotcha1(item)} overrides={
            {
                'Frank': {children: item.name},
                'Basel': {children: item.location},
                'Interests': {children: intString},
                '1 Minute ago': {children: timeString},
            }
        }
            props={item}
           />}
          {item.frontPicBlurred && 
          <>
           <Text color={"green"} fontWeight="bold" 
            padding={"30px 0px 0px 0px"}
            margin={"0px 0px -10px 0px"}
           >
            New Participant🔥
          </Text>
          
            <GotchaSingleDisplayCustom usage={"feed"} onClick={() => handleOpenJoinGotcha1(item)} props={item} user={{user:item.userid}} challengeId={item.challenge} overrides={
                                {
                                image34772788: {src: "https://images.pexels.com/photos/2726111/pexels-photo-2726111.jpeg?auto=compress&cs=tinysrgb&w=630&h=375&dpr=2"},
                                image34772776: {src: "https://images.pexels.com/photos/12476792/pexels-photo-12476792.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"},
                                image34772779: {src: "https://www.kukksi.de/wp-content/uploads/2022/03/Selfie.png"},
                                "Frank": {children: item.name},
                                "Basel": {children: item.location},
                                'Interests': {children: intString}
                                
                                }
                                    } />
                                    </>}
        
        <Divider
          height="1px"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          size="small"
          orientation="horizontal"
        ></Divider>
        
        </>)

        })}
        </ScrollView>
                
              
            
          </TableRow>
         {/*  <Divider />
          <View >
            <MenuNew  page={props.page} />
            </View> */}
          </Grid>
         {/*  <Footer style={{position:'absolute',
            left:0,
            bottom:0,
            right:0}}/> */}
           
       </Grid>
 
            
      
 
       </>
       
           
            
           
            
           
            
            
           
        
    )
}