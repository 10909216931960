import { Flex, Text } from "@aws-amplify/ui-react";
import { SvgIcon, Tooltip } from "@mui/material";


export function Badge(props){
    let { colorA, colorB, colorC, label, sizeBadge, labelColor,tooltip } = props
    let sizeLabel = props.sizeBadge/8
    /* let colorA = '#FCFCFC'
    let colorB = '#8a2be2' //primary color */
    return(
        <>
        <Tooltip title={tooltip} enterTouchDelay={0}>
        <Flex 
            direction={'row'}
            alignContent={'center'}
            justifyContent={'center'}
            alignItems={'center'}
        >
        <SvgIcon sx={{ fontSize: sizeBadge }} viewBox="0 0 332 310" {...props}>
           <path fill={colorB} opacity="1.000000" stroke="none" 
	d="
M333.000000,154.000000 
	C333.000000,158.024551 333.000000,162.049088 332.632812,166.646637 
	C327.081085,194.514816 304.909485,209.926697 278.734131,209.600250 
	C219.601837,208.862808 160.453964,209.373337 101.312439,209.374313 
	C84.486275,209.374588 67.658699,209.505463 50.834293,209.331879 
	C27.353638,209.089661 8.602302,194.171539 2.901103,171.570877 
	C2.580892,170.301498 1.647825,169.186722 1.000003,168.000000 
	C1.000000,163.310745 1.000000,158.621490 1.376495,153.408157 
	C2.013428,152.103531 2.332241,151.337173 2.525833,150.540375 
	C8.605844,125.516182 27.128445,111.026421 52.983315,111.030067 
	C129.388733,111.040840 205.794159,111.033867 282.199585,111.038536 
	C305.447296,111.039955 323.988464,124.747437 330.640533,146.807144 
	C331.368866,149.222290 332.210785,151.603210 333.000000,154.000000 
z"/>
<path fill={colorA} opacity="1.000000" stroke="none" 
	d="
M333.000000,153.531342 
	C332.210785,151.603210 331.368866,149.222290 330.640533,146.807144 
	C323.988464,124.747437 305.447296,111.039955 282.199585,111.038536 
	C205.794159,111.033867 129.388733,111.040840 52.983315,111.030067 
	C27.128445,111.026421 8.605844,125.516182 2.525833,150.540375 
	C2.332241,151.337173 2.013428,152.103531 1.376495,152.942047 
	C1.000000,102.405624 1.000000,51.811249 1.000000,1.000000 
	C52.354023,1.000000 103.708351,1.000000 155.507263,1.188171 
	C155.951859,1.376342 155.981644,1.754579 155.777298,2.030908 
	C149.861282,9.468208 143.371338,11.756180 135.476395,5.570144 
	C135.000931,5.197595 134.279846,5.138527 133.512802,4.879473 
	C131.954742,6.722934 130.164139,8.320062 129.024918,10.293260 
	C126.284988,15.038889 122.654037,15.817809 117.847275,13.718107 
	C115.769073,12.810301 113.460823,12.429120 111.180298,11.787104 
	C109.460480,14.649844 107.701637,17.100397 106.448807,19.786922 
	C104.970993,22.955906 103.101517,24.227608 99.469154,23.316662 
	C96.480103,22.567051 93.284943,22.639257 89.440697,22.277575 
	C88.963707,34.062336 81.664345,36.844227 71.731865,35.967739 
	C71.593773,35.955551 71.440460,36.115887 70.961189,36.377514 
	C70.387177,38.857700 69.579155,41.559387 69.167915,44.320175 
	C68.619438,48.002258 67.276451,50.313751 63.000656,50.473515 
	C60.109055,50.581554 57.255920,51.719257 54.232689,52.432480 
	C54.028759,55.811443 53.617016,58.956829 53.711506,62.086937 
	C53.808842,65.311462 52.344097,66.857361 49.430393,67.734344 
	C46.303581,68.675468 43.270252,69.927170 40.254910,71.021996 
	C40.327480,72.254066 40.261410,72.935844 40.420563,73.560204 
	C42.392612,81.296730 41.396851,87.480408 32.504417,90.178246 
	C31.354870,90.526985 29.919905,92.918648 30.105545,94.137253 
	C30.714846,98.136887 32.010117,102.032021 32.950466,105.619019 
	C37.472965,104.750183 41.400326,103.317024 45.287098,103.419716 
	C50.212566,103.549866 52.018993,101.169975 53.863026,97.165237 
	C84.068489,31.566954 166.268250,5.588744 228.827484,41.699524 
	C252.028473,55.091724 269.263397,73.894737 280.746643,98.072166 
	C281.633850,99.940147 283.158203,102.438843 284.819427,102.836716 
	C290.577454,104.215851 296.526764,104.796463 302.738464,105.731361 
	C303.071350,104.621292 303.289581,103.115746 303.947205,101.835976 
	C306.716858,96.445908 305.272095,92.931915 299.548828,90.977097 
	C294.393555,89.216278 293.048370,85.679459 294.616730,80.534866 
	C295.270416,78.390602 295.057159,75.982056 295.233215,73.755981 
	C294.450348,73.372475 294.041107,73.058128 293.586853,72.966309 
	C285.003235,71.231377 280.130341,66.840881 281.813599,57.278419 
	C281.976990,56.350250 280.169922,54.105450 279.287811,54.110977 
	C268.906189,54.176086 266.029968,47.734631 265.609314,38.949146 
	C265.596130,38.673840 265.248932,38.414528 264.609619,37.523865 
	C254.584030,39.681740 248.565125,35.158978 246.824936,24.777531 
	C246.781433,24.518084 246.417908,24.312281 245.616394,23.448799 
	C235.683029,28.081671 229.674362,23.333319 226.114670,13.782066 
	C226.020081,13.528215 225.623428,13.386935 225.186661,13.056058 
	C222.267227,13.791381 219.120804,14.295907 216.200150,15.398473 
	C213.125534,16.559170 211.289230,15.702994 209.527832,13.126332 
	C207.689224,10.436699 205.437622,8.029371 202.897324,4.925244 
	C194.243271,12.730068 187.058105,10.601377 181.022903,1.700867 
	C181.024231,1.467038 181.000000,1.000000 181.000000,1.000000 
	C231.593903,1.000000 282.187805,1.000000 333.000000,1.000000 
	C333.000000,51.687351 333.000000,102.375015 333.000000,153.531342 
M107.546005,62.005219 
	C92.259377,72.545227 80.263550,85.902367 71.948936,102.681297 
	C135.678802,102.681297 198.826828,102.681297 262.148804,102.681297 
	C236.892014,49.521267 165.455811,24.078035 107.546005,62.005219 
z"/>
<path fill={colorA} opacity="1.000000" stroke="none" 
	d="
M1.000003,168.468658 
	C1.647825,169.186722 2.580892,170.301498 2.901103,171.570877 
	C8.602302,194.171539 27.353638,209.089661 50.834293,209.331879 
	C67.658699,209.505463 84.486275,209.374588 101.312439,209.374313 
	C160.453964,209.373337 219.601837,208.862808 278.734131,209.600250 
	C304.909485,209.926697 327.081085,194.514816 332.632812,167.109833 
	C333.000000,211.265915 333.000000,255.531815 333.000000,300.000000 
	C281.645935,300.000000 230.291626,300.000000 178.500854,299.837830 
	C178.064407,299.675690 178.022446,299.347717 178.243286,299.079651 
	C184.102280,291.432129 190.618286,289.390808 198.519043,295.462280 
	C199.004044,295.834991 199.734070,295.888885 200.515045,296.145233 
	C201.333191,295.258636 202.315109,294.530334 202.853836,293.556763 
	C206.622406,286.746307 211.754272,284.375427 219.054428,288.190582 
	C220.168396,288.772766 221.555923,288.831512 223.574402,289.313049 
	C225.885101,277.555786 233.848297,276.296509 244.467606,278.836914 
	C245.288010,267.495941 251.996460,263.346893 262.844208,265.612579 
	C263.682007,262.210297 264.588593,259.518524 264.965820,256.754517 
	C265.474304,253.029129 266.763000,250.753311 271.070831,250.619186 
	C273.957703,250.529297 276.811005,249.361069 279.820923,248.641495 
	C280.032593,245.060944 279.895782,241.679596 280.559937,238.463654 
	C280.909637,236.770340 282.327423,234.614365 283.829254,233.952682 
	C293.911621,229.510513 293.964539,229.622070 292.826599,218.513275 
	C292.778748,218.046371 292.547089,217.598297 292.287903,216.787415 
	C290.009918,216.969254 287.730164,217.212189 285.444061,217.317261 
	C282.948730,217.431961 280.403595,217.732132 277.958893,217.389618 
	C274.104309,216.849594 272.126038,218.596924 269.947357,221.611130 
	C243.674683,257.958923 208.001022,276.761444 163.159775,275.495300 
	C121.172943,274.309753 87.987495,255.316483 63.545597,221.099899 
	C62.496109,219.630692 60.798237,217.719604 59.304970,217.629501 
	C52.918842,217.244232 46.496128,217.465408 39.978119,217.465408 
	C38.301723,227.250076 38.251957,227.382874 47.746510,230.940979 
	C50.699387,232.047577 51.964081,233.534439 51.866535,236.681274 
	C51.764977,239.957382 52.129486,243.247955 52.292908,246.587402 
	C54.638077,247.095840 56.570633,247.766434 58.538284,247.893524 
	C64.109657,248.253372 67.437111,250.700974 67.287193,256.645752 
	C67.136665,262.614471 70.503372,264.337708 75.789124,263.868164 
	C76.281990,263.824402 76.800728,264.002960 77.283653,263.927002 
	C81.498344,263.264008 83.298248,265.247040 84.557755,269.218414 
	C87.248131,277.701477 87.505173,277.620148 96.209869,276.493988 
	C96.540154,276.451294 96.880486,276.436859 97.196838,276.343689 
	C100.223000,275.452667 102.121910,276.385651 103.441864,279.406494 
	C104.753937,282.409271 106.661827,285.151703 108.357796,288.093964 
	C110.548607,287.576935 112.630470,287.449432 114.366730,286.608429 
	C119.808693,283.972382 123.691589,285.289459 126.596375,290.531799 
	C127.612831,292.366241 129.213165,293.877167 131.025467,296.131012 
	C139.275177,289.102112 146.600616,289.985901 152.713989,298.674438 
	C152.809555,298.810242 152.883682,298.961151 152.964325,299.329163 
	C152.960846,299.553314 153.000000,300.000000 153.000000,300.000000 
	C102.411476,300.000000 51.822956,300.000000 1.000000,300.000000 
	C1.000000,256.312714 1.000000,212.625015 1.000003,168.468658 
M228.202927,217.490616 
	C184.723267,217.466949 141.243591,217.440796 97.763924,217.435547 
	C96.784477,217.435425 95.804993,217.672638 94.009026,217.509827 
	C91.706963,217.587540 89.404900,217.665253 86.534447,217.394379 
	C86.672775,218.296173 86.811111,219.197968 87.184364,220.723785 
	C126.679138,263.534973 189.738495,269.110901 233.999435,233.403275 
	C234.571426,232.844803 235.143433,232.286346 236.247375,231.336594 
	C240.750504,227.163010 245.253616,222.989426 249.756729,218.815842 
	C249.436569,218.473267 249.116409,218.130707 248.796249,217.788132 
	C245.211746,217.788132 241.627258,217.788132 237.368454,217.484909 
	C235.917038,217.583511 234.465607,217.682098 232.237213,217.500717 
	C231.153244,217.598618 230.069290,217.696503 228.202927,217.490616 
z"/>
<path fill={colorA}  opacity="1.000000" stroke="none" 
	d="
M155.981644,1.754580 
	C155.981644,1.754579 155.951859,1.376342 155.975922,1.188171 
	C164.020905,1.000000 172.041809,1.000000 180.531357,1.000000 
	C181.000000,1.000000 181.024231,1.467038 180.658051,1.709394 
	C176.322800,4.253672 172.353729,6.555594 168.703278,8.672723 
	C164.295197,6.275568 160.138412,4.015075 155.981644,1.754580 
z"/>
<path fill={colorA}  opacity="1.000000" stroke="none" 
	d="
M178.022446,299.347717 
	C178.022446,299.347717 178.064407,299.675690 178.032196,299.837830 
	C169.979095,300.000000 161.958191,300.000000 153.468643,300.000000 
	C153.000000,300.000000 152.960846,299.553314 153.334595,299.341248 
	C157.688110,296.828979 161.667877,294.528717 165.361298,292.393982 
	C169.697632,294.775574 173.860031,297.061646 178.022446,299.347717 
z"/>
<path fill={colorB} opacity="1.000000" stroke="none" 
	d="
M107.848930,61.807220 
	C165.455811,24.078035 236.892014,49.521267 262.148804,102.681297 
	C198.826828,102.681297 135.678802,102.681297 71.948936,102.681297 
	C80.263550,85.902367 92.259377,72.545227 107.848930,61.807220 
M161.799683,56.024990 
	C160.797684,56.103817 159.795700,56.182640 158.793701,56.261463 
	C163.202347,58.683086 163.561203,62.056519 161.431976,66.865776 
	C163.867386,65.235405 165.331192,64.255455 166.875732,63.221474 
	C168.623581,64.267082 170.329971,65.287895 172.666885,66.685905 
	C171.883133,64.074623 171.366455,62.353172 170.835281,60.583412 
	C172.419144,59.189823 173.921692,57.867794 176.092529,55.957745 
	C173.234146,55.957745 171.423111,55.957745 169.506805,55.957745 
	C168.689087,54.040833 167.925140,52.249989 167.429230,51.087440 
	C165.651413,52.892448 164.108826,54.458645 161.799683,56.024990 
M120.807892,83.694710 
	C122.612541,83.599472 124.417183,83.504227 125.160759,83.464989 
	C124.088760,80.263489 123.091812,77.286133 122.064072,74.216805 
	C121.080200,74.799812 119.705444,75.614456 118.359451,76.412048 
	C116.638710,75.163445 115.190109,74.112305 112.755035,72.345367 
	C115.300270,77.367180 114.014229,80.500336 109.352707,82.853661 
	C112.213028,83.119034 114.002884,83.285095 115.794769,83.451340 
	C116.525711,85.357750 117.195312,87.104195 118.166122,89.636238 
	C119.086601,87.256210 119.663597,85.764313 120.807892,83.694710 
M214.208527,75.442497 
	C213.284042,75.001152 212.359558,74.559799 210.775894,73.803757 
	C211.412155,76.136520 211.831741,77.674919 212.265549,79.265388 
	C210.678696,80.641304 209.196548,81.926422 207.714417,83.211540 
	C212.659714,82.540871 215.115005,84.863129 216.631958,89.125290 
	C216.882553,84.121017 219.713730,82.495323 223.161255,82.835258 
	C221.778015,80.010124 220.412704,77.221611 219.117645,74.576584 
	C218.025055,74.907280 216.430801,75.389824 214.208527,75.442497 
z"/>
<path fill={colorB} opacity="1.000000" stroke="none" 
	d="
M155.777298,2.030910 
	C160.138412,4.015075 164.295197,6.275568 168.703278,8.672723 
	C172.353729,6.555594 176.322800,4.253672 180.656738,1.943223 
	C187.058105,10.601377 194.243271,12.730068 202.897324,4.925244 
	C205.437622,8.029371 207.689224,10.436699 209.527832,13.126332 
	C211.289230,15.702994 213.125534,16.559170 216.200150,15.398473 
	C219.120804,14.295907 222.267227,13.791381 225.186661,13.056058 
	C225.623428,13.386935 226.020081,13.528215 226.114670,13.782066 
	C229.674362,23.333319 235.683029,28.081671 245.616394,23.448799 
	C246.417908,24.312281 246.781433,24.518084 246.824936,24.777531 
	C248.565125,35.158978 254.584030,39.681740 264.609619,37.523865 
	C265.248932,38.414528 265.596130,38.673840 265.609314,38.949146 
	C266.029968,47.734631 268.906189,54.176086 279.287811,54.110977 
	C280.169922,54.105450 281.976990,56.350250 281.813599,57.278419 
	C280.130341,66.840881 285.003235,71.231377 293.586853,72.966309 
	C294.041107,73.058128 294.450348,73.372475 295.233215,73.755981 
	C295.057159,75.982056 295.270416,78.390602 294.616730,80.534866 
	C293.048370,85.679459 294.393555,89.216278 299.548828,90.977097 
	C305.272095,92.931915 306.716858,96.445908 303.947205,101.835976 
	C303.289581,103.115746 303.071350,104.621292 302.738464,105.731361 
	C296.526764,104.796463 290.577454,104.215851 284.819427,102.836716 
	C283.158203,102.438843 281.633850,99.940147 280.746643,98.072166 
	C269.263397,73.894737 252.028473,55.091724 228.827484,41.699524 
	C166.268250,5.588744 84.068489,31.566954 53.863026,97.165237 
	C52.018993,101.169975 50.212566,103.549866 45.287098,103.419716 
	C41.400326,103.317024 37.472965,104.750183 32.950466,105.619019 
	C32.010117,102.032021 30.714846,98.136887 30.105545,94.137253 
	C29.919905,92.918648 31.354870,90.526985 32.504417,90.178246 
	C41.396851,87.480408 42.392612,81.296730 40.420563,73.560204 
	C40.261410,72.935844 40.327480,72.254066 40.254910,71.021996 
	C43.270252,69.927170 46.303581,68.675468 49.430393,67.734344 
	C52.344097,66.857361 53.808842,65.311462 53.711506,62.086937 
	C53.617016,58.956829 54.028759,55.811443 54.232689,52.432480 
	C57.255920,51.719257 60.109055,50.581554 63.000656,50.473515 
	C67.276451,50.313751 68.619438,48.002258 69.167915,44.320175 
	C69.579155,41.559387 70.387177,38.857700 70.961189,36.377514 
	C71.440460,36.115887 71.593773,35.955551 71.731865,35.967739 
	C81.664345,36.844227 88.963707,34.062336 89.440697,22.277575 
	C93.284943,22.639257 96.480103,22.567051 99.469154,23.316662 
	C103.101517,24.227608 104.970993,22.955906 106.448807,19.786922 
	C107.701637,17.100397 109.460480,14.649844 111.180298,11.787104 
	C113.460823,12.429120 115.769073,12.810301 117.847275,13.718107 
	C122.654037,15.817809 126.284988,15.038889 129.024918,10.293260 
	C130.164139,8.320062 131.954742,6.722934 133.512802,4.879473 
	C134.279846,5.138527 135.000931,5.197595 135.476395,5.570144 
	C143.371338,11.756180 149.861282,9.468208 155.777298,2.030910 
z"/>
<path fill={colorB} opacity="1.000000" stroke="none" 
	d="
M178.243286,299.079651 
	C173.860031,297.061646 169.697632,294.775574 165.361298,292.393982 
	C161.667877,294.528717 157.688110,296.828979 153.338074,299.117126 
	C152.883682,298.961151 152.809555,298.810242 152.713989,298.674438 
	C146.600616,289.985901 139.275177,289.102112 131.025467,296.131012 
	C129.213165,293.877167 127.612831,292.366241 126.596375,290.531799 
	C123.691589,285.289459 119.808693,283.972382 114.366730,286.608429 
	C112.630470,287.449432 110.548607,287.576935 108.357796,288.093964 
	C106.661827,285.151703 104.753937,282.409271 103.441864,279.406494 
	C102.121910,276.385651 100.223000,275.452667 97.196838,276.343689 
	C96.880486,276.436859 96.540154,276.451294 96.209869,276.493988 
	C87.505173,277.620148 87.248131,277.701477 84.557755,269.218414 
	C83.298248,265.247040 81.498344,263.264008 77.283653,263.927002 
	C76.800728,264.002960 76.281990,263.824402 75.789124,263.868164 
	C70.503372,264.337708 67.136665,262.614471 67.287193,256.645752 
	C67.437111,250.700974 64.109657,248.253372 58.538284,247.893524 
	C56.570633,247.766434 54.638077,247.095840 52.292908,246.587402 
	C52.129486,243.247955 51.764977,239.957382 51.866535,236.681274 
	C51.964081,233.534439 50.699387,232.047577 47.746510,230.940979 
	C38.251957,227.382874 38.301723,227.250076 39.978119,217.465408 
	C46.496128,217.465408 52.918842,217.244232 59.304970,217.629501 
	C60.798237,217.719604 62.496109,219.630692 63.545597,221.099899 
	C87.987495,255.316483 121.172943,274.309753 163.159775,275.495300 
	C208.001022,276.761444 243.674683,257.958923 269.947357,221.611130 
	C272.126038,218.596924 274.104309,216.849594 277.958893,217.389618 
	C280.403595,217.732132 282.948730,217.431961 285.444061,217.317261 
	C287.730164,217.212189 290.009918,216.969254 292.287903,216.787415 
	C292.547089,217.598297 292.778748,218.046371 292.826599,218.513275 
	C293.964539,229.622070 293.911621,229.510513 283.829254,233.952682 
	C282.327423,234.614365 280.909637,236.770340 280.559937,238.463654 
	C279.895782,241.679596 280.032593,245.060944 279.820923,248.641495 
	C276.811005,249.361069 273.957703,250.529297 271.070831,250.619186 
	C266.763000,250.753311 265.474304,253.029129 264.965820,256.754517 
	C264.588593,259.518524 263.682007,262.210297 262.844208,265.612579 
	C251.996460,263.346893 245.288010,267.495941 244.467606,278.836914 
	C233.848297,276.296509 225.885101,277.555786 223.574402,289.313049 
	C221.555923,288.831512 220.168396,288.772766 219.054428,288.190582 
	C211.754272,284.375427 206.622406,286.746307 202.853836,293.556763 
	C202.315109,294.530334 201.333191,295.258636 200.515045,296.145233 
	C199.734070,295.888885 199.004044,295.834991 198.519043,295.462280 
	C190.618286,289.390808 184.102280,291.432129 178.243286,299.079651 
z"/>
<path fill={colorB}  opacity="1.000000" stroke="none" 
	d="
M233.449051,233.656998 
	C189.738495,269.110901 126.679138,263.534973 87.110023,220.119080 
	C87.058067,218.923904 87.080452,218.333450 87.102837,217.742981 
	C89.404900,217.665253 91.706963,217.587540 94.346191,217.756927 
	C94.683357,218.004028 94.584427,218.232925 94.441849,218.313416 
	C94.149300,218.564301 94.051735,218.760757 93.908478,219.272583 
	C97.246269,219.707703 100.682037,219.977676 104.118027,219.980423 
	C143.849274,220.012161 183.580643,220.033829 223.311539,219.911606 
	C225.204941,219.905777 227.094162,218.531433 228.985321,217.794403 
	C230.069290,217.696503 231.153244,217.598618 232.686157,218.031036 
	C235.364868,219.198395 237.594635,219.835434 239.824417,220.472458 
	C239.886307,220.181061 239.948181,219.889679 240.010071,219.598282 
	C239.354309,218.994888 238.698532,218.391510 238.042770,217.788132 
	C241.627258,217.788132 245.211746,217.788132 248.796249,217.788132 
	C249.116409,218.130707 249.436569,218.473267 249.756729,218.815842 
	C245.253616,222.989426 240.750504,227.163010 235.587799,231.403915 
	C234.435150,232.199829 233.942093,232.928406 233.449051,233.656998 
z"/>
<path fill={colorB}  opacity="1.000000" stroke="none" 
	d="
M94.584427,218.232925 
	C94.584427,218.232925 94.683357,218.004028 94.754440,217.901581 
	C95.804993,217.672638 96.784477,217.435425 97.763924,217.435547 
	C141.243591,217.440796 184.723267,217.466949 228.594116,217.642517 
	C227.094162,218.531433 225.204941,219.905777 223.311539,219.911606 
	C183.580643,220.033829 143.849274,220.012161 104.118027,219.980423 
	C100.682037,219.977676 97.246269,219.707703 94.077026,219.202515 
	C94.602150,218.709793 94.682404,218.506378 94.584427,218.232925 
z"/>
<path fill={colorB}  opacity="1.000000" stroke="none" 
	d="
M237.705612,217.636520 
	C238.698532,218.391510 239.354309,218.994888 240.010071,219.598282 
	C239.948181,219.889679 239.886307,220.181061 239.824417,220.472458 
	C237.594635,219.835434 235.364868,219.198395 233.074646,218.171021 
	C234.465607,217.682098 235.917038,217.583511 237.705612,217.636520 
z"/>
<path fill={colorA}  opacity="1.000000" stroke="none" 
	d="
M86.818642,217.568680 
	C87.080452,218.333450 87.058067,218.923904 86.992569,219.807068 
	C86.811111,219.197968 86.672775,218.296173 86.818642,217.568680 
z"/>
<path fill={colorA}  opacity="1.000000" stroke="none" 
	d="
M233.724243,233.530136 
	C233.942093,232.928406 234.435150,232.199829 235.321823,231.599564 
	C235.143433,232.286346 234.571426,232.844803 233.724243,233.530136 
z"/>
<path fill={colorC}  opacity="1.000000" stroke="none" 
	d="
M162.182953,56.024918 
	C164.108826,54.458645 165.651413,52.892448 167.429230,51.087440 
	C167.925140,52.249989 168.689087,54.040833 169.506805,55.957745 
	C171.423111,55.957745 173.234146,55.957745 176.092529,55.957745 
	C173.921692,57.867794 172.419144,59.189823 170.835281,60.583412 
	C171.366455,62.353172 171.883133,64.074623 172.666885,66.685905 
	C170.329971,65.287895 168.623581,64.267082 166.875732,63.221474 
	C165.331192,64.255455 163.867386,65.235405 161.431976,66.865776 
	C163.561203,62.056519 163.202347,58.683086 158.793701,56.261463 
	C159.795700,56.182640 160.797684,56.103817 162.182953,56.024918 
z"/>
<path fill={colorC}  opacity="1.000000" stroke="none" 
	d="
M120.524239,83.983566 
	C119.663597,85.764313 119.086601,87.256210 118.166122,89.636238 
	C117.195312,87.104195 116.525711,85.357750 115.794769,83.451340 
	C114.002884,83.285095 112.213028,83.119034 109.352707,82.853661 
	C114.014229,80.500336 115.300270,77.367180 112.755035,72.345367 
	C115.190109,74.112305 116.638710,75.163445 118.359451,76.412048 
	C119.705444,75.614456 121.080200,74.799812 122.064072,74.216805 
	C123.091812,77.286133 124.088760,80.263489 125.160759,83.464989 
	C124.417183,83.504227 122.612541,83.599472 120.524239,83.983566 
z"/>
<path fill={colorC}  opacity="1.000000" stroke="none" 
	d="
M214.522537,75.657433 
	C216.430801,75.389824 218.025055,74.907280 219.117645,74.576584 
	C220.412704,77.221611 221.778015,80.010124 223.161255,82.835258 
	C219.713730,82.495323 216.882553,84.121017 216.631958,89.125290 
	C215.115005,84.863129 212.659714,82.540871 207.714417,83.211540 
	C209.196548,81.926422 210.678696,80.641304 212.265549,79.265388 
	C211.831741,77.674919 211.412155,76.136520 210.775894,73.803757 
	C212.359558,74.559799 213.284042,75.001152 214.522537,75.657433 
z"/>
<path fill={colorB}  opacity="1.000000" stroke="none" 
	d="
M94.441849,218.313416 
	C94.682404,218.506378 94.602150,218.709793 94.175117,218.913239 
	C94.051735,218.760757 94.149300,218.564301 94.441849,218.313416 
z"/>
        </SvgIcon>
        <Text style={{fontSize: sizeLabel, position: "absolute", color: labelColor}}>
           <b> {label} </b>
        </Text>
        </Flex>
        </Tooltip>
</>
    )
}