import { ScrollView, Text } from "@aws-amplify/ui-react";
import { Card } from "@mui/material";
import Header from "./header";


export function PrivacyDeclaration(props) {

    return(
        <>
        
        {/* <Header width={"30%"}/> */}
        <ScrollView style={{height: window.innerHeight-100,  width: "100%" /* , backgroundColor: "black" */}}>
        <Card style={{margin:"2rem"}} >
        <Text style={{color: "Black", fontSize:30}}>Privacy Policy</Text>
{/* <Text style={{color: "Black", fontSize:20}}>Last updated: 2022-12-01</Text> */}
<Text style={{marginTop: "20px"}}>
We are pleased that you are visiting a website of Gotchaaa (hereinafter: Gotchaaa). We attach great importance to your privacy and the protection of your personal data. Therefore, we would like to inform you at this point how we use and protect the data collected about you. For Gotchaaa, it goes without saying that we comply with all applicable data protection laws and regulations, including the EU General Data Protection Regulation (“GDPR”).

<p>Use of this website</p>
This privacy statement describes what information we collect about you and how that information may be stored and processed within Switzerland and Gotchaaa as described below.

This website and related Gotchaaa events and publications provide products, content and services for professional and consumer audiences and are not intended for persons under the age of 18. We do not knowingly collect or store any personally identifiable information from children under the age of 18.
<p>
This document describes the following:

What data we collect and how it is collected
How we protect your personal data
How we handle the personal information we collect
What legal basis we have for processing your personal data
When and how we share your personal data with third parties
How long we store your personal data
Your Rights and Preferences
How to get in touch with us
What data we collect
The information Gotchaaa collects from you is limited to the information typically found on a business card: first name, last name, job/job title, employer name/company name, and employer address, email address and phone number /the company. In some cases, we may ask you to provide additional professional information, such as the industry and size of the company you work for.
</p>
To improve and/or update the information you provide, we may combine it with professional information or personal information collected from third-party sources. Personal information described in this document is personal and professional information that you provide to us, as well as information that we obtain from third-party sources, both of which types of information are typically combined into one user record.
<p>
In addition, this website automatically collects and stores certain information using cookies and similar technologies. This includes IP addresses, region or location of a computer or device used to access the Internet, browser type, operating system, historical data on websites viewed and other usage data. For more information, see our cookie policy.
</p>
<p>
<b>How personal information is collected by us</b>
We may collect your personal information when you sign up to receive products, content or services offered by Gotchaaa or its third party partners (“Partners”), such as publications, subscriptions, contests, newsletters, memberships, premium content, webcasts, Videos, white papers, online seminars, conferences and events.
</p>
<p>
<b>What happens if you don't provide any personal information?</b>
In order to provide the products, content or services you have requested, we need your personal data. If you do not provide this personal information, we cannot provide the products, content or services.
</p>
Gotchaaa
This website is one of the many websites operated by SchooIT, collectively referred to by us as Gotchaaa. Where permitted by law, we may transfer the personal information we collect about you to another Gotchaaa project for internal analytical and business development purposes and to provide information about products, content and services that may be of interest to you.
<p>
<b>How we protect your personal data</b>
Security of your personal data
Gotchaaa has technical and organizational safeguards in place to protect your personal information from loss, misuse, unauthorized use, access, accidental disclosure, alteration and destruction. We also require appropriate safeguards from third parties contracted to support Gotchaaa's business operations (see section “When and how we share your personal information with third parties”).

We regularly update and test security measures and limit access to your personal information to those who have a need to know to provide you with Gotchaaa products, content or services.
</p>
<p>
<b>Storage of your personal data</b>
We may store your personal data on our own website servers or using other Gotchaaa hosted internal technology. Your personal data may also be stored, via cloud services or other technology, by third parties that Gotchaaa has contracted to support its business operations (see section “When and how we share your personal data with third parties”).

These third parties may only use or access your personal data for storage in or retrieval from the cloud. Gotchaaa requires these third parties to provide at least the same level of security to protect your personal information (see the “Security of your personal information” section).
</p>
<b>How we handle the personal information we collect</b>
We use the personal data we collect to communicate with you, fulfill your requests, improve our service and display other information and offers that may be of interest to you.
<p>
<b>To communicate with you</b>
We may use your personal information to send important communications, such as notices of your registration, transactions and changes to Gotchaaa's terms, conditions or policies, and/or for other internal purposes.
</p>
<p>
<b>To fulfill your wishes</b>
We may use your personal information to provide you with the product, content or service you have requested. If you enter a Gotchaaa sweepstakes, contest or similar promotion, we may use your personal information to administer that promotion.
</p>
<p>
<b>To improve our service</b>
We may use your personal information to audit, analyze data and improve Gotchaaa's products, content and services.
</p>
<p>
<b>To display offers that may be of interest to you</b>
We may contact you to offer you other products, content or services from Gotchaaa or third parties that we think you may find interesting.
</p>
<p>
<b>What legal basis we have for processing your personal data</b>
Gotchaaa uses the personal information you provide to provide you with the products, content and services you request, to send you important communications, and for internal purposes such as testing, data analysis and research to provide you with the information you need for truly in-depth technology need purchasing decisions.

We may also use your personal information to provide you with products, content or services that may be of interest to you based on the interests or preferences you have shared with us. We may also transfer your personal information to external sponsors of content, events and other services or offerings.

The legal basis for processing your personal data is your consent. In certain circumstances, we may otherwise process your personal data where Gotchaaa has a legitimate interest to do so and it does not violate your rights and fundamental freedoms.
</p>
<p>
Where Gotchaaa processes your personal data for its own legitimate interests, Gotchaaa will have due regard for any potential impact on you and your rights under data protection and other relevant legislation. However, our legitimate business interests do not override your interests. Where your rights and freedoms override our legitimate interests, Gotchaaa will only use your personal information where we have your consent, are otherwise required to do so, or are permitted by law to do so.
</p>
<p>
<b>When and how we share your personal data with third parties</b>
To provide content and services from our websites and affiliates and third party services that may be of interest to you, including sponsored content and events, for business operations and to comply with applicable legal process, Gotchaaa may share your personal information.

For content and services that may be of interest to you or that you request
As mentioned above, we may share your personal information within Gotchaaa for internal analytical and business development purposes and to provide information about products, content and services that may be of interest to you (see “Gotchaaa” section). We may also transfer your personal data to third parties in order to provide those third parties with the sponsored content or services you have requested (see the “Third Party Sponsored Content and Events” section).

For business operations
We may also transfer your personal information to third parties that we have contracted to support Gotchaaa's business operations, including but not limited to delivery, fulfillment, payment processing, email delivery, suppression/Robinson list management, and data processing .

For third-party sponsored content and events
Gotchaaa partners with external sponsors to provide our users with an extensive library of content, such as white papers, (live or online) professional events, and other services or offerings. In return for accessing such offerings, we may ask you to provide personal information upon registration.

We may use your personal data to send you the offer you have requested. In addition, your personal data may be shared with the sponsor(s) of the offer to inform you about the offer and other products, content or services that may be of interest to you.
</p>
<p>
<b>To comply with the law</b>
Your personal information may be shared where permitted by law or necessary to comply with an applicable legal process.
</p>
<p>
When there is a change of ownership
If all or any Gotchaaa property, products or services are sold or transferred, your personal information will be transferred to the new owner.

International Data Transfer
If Gotchaaa transfers your personal data within Gotchaaa or to third parties as described in this Privacy Policy and your personal data is transferred to a country that is not a member state of the European Union or the European Economic Area (EEA) or, in the opinion of the European Commission, is not offers an adequate level of data protection, then Gotchaaa (as controller/data exporter) will only undertake this data transfer (to a processor/data importer) if appropriate safeguards are in place, such as binding internal data protection rules, standard contractual clauses, an approved code of conduct or an approved certification mechanism. For more information, please contact Gotchaaa's Data Protection Officer (see contact details below).
</p>
<p>
How long we store your personal data
We store your personal data for the duration of your business relationship with us and only keep your data for as long as is necessary for the individual purposes of use, unless you have given us your consent to use your data for other or similar purposes .
</p>
Your Rights and Preferences
If you notify us to erase your personal information, or to stop sending you marketing communications, or otherwise restrict the processing of your personal information, we will only retain some basic information to prevent the sending of unsolicited materials in the future and to keep a record of your request and our response.
<p>
Confirmation and information about your personal data
Please send an email to below if you would like to receive confirmation and information about your personal data processed by Gotchaaa, access, update or correct your personal data stored by Gotchaaa or receive a copy of this data, to use them for your own purposes.

Deletion of your personal data
If you want your personal data to be deleted, please send an email to us.

Opting Out of Marketing Communications
If you no longer wish to receive marketing communications from Gotchaaa, please email us.

Restriction of the processing of your personal data
If you would like Gotchaaa to otherwise restrict the processing of your personal data, please send an email us below
</p>
<p>
How to get in touch with us
Questions, concerns or complaints:
If you have any questions, concerns or complaints about how Gotchaaa handles personal data or about this Privacy Policy, you should contact our Data Protection Officer.

If you believe that you have suffered damage as a result of a violation of your rights by Gotchaaa in accordance with this Privacy Policy and that Gotchaaa has not dealt with your complaint in an appropriate manner, you may also lodge a complaint with the competent supervisory authority.
</p>
<img width={"100%"} src="https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/Bildschirmfoto 2022-12-06 um 22.34.19.png"></img>

      

Your feedback is important to us! We welcome your ideas, opinions and comments on Gotchaaa services. 

© Copyright Gotchaaa

</Text>
</Card>
</ScrollView>
</>
)
}