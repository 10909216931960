/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { getOverrideProps, IconClose } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, ScrollView, Text, View } from "@aws-amplify/ui-react";
/* import MyIcon from "./MyIcon"; */
import { AmplifyS3Image, AmplifyS3ImagePicker } from '@aws-amplify/ui-react/legacy';
import { JoinGotcha, LikeChallenger, ReplyToChallenger, SendMarkFavorite } from "../services/graphQlServices";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import MessageIcon from '@mui/icons-material/Message';
import { Box, IconButton, Menu, MenuItem, Modal, Snackbar, TextField } from "@mui/material";
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SendIcon from '@mui/icons-material/Send';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import IosShareIcon from '@mui/icons-material/IosShare';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GlobalContext } from "../Context/globalState";
import AbuseReport from "../components/AbuseReport";


export default function GotchaSingleDisplayCustom(props) {
  const { loadMyGotchaaas } = useContext(GlobalContext);
  console.log({props})
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const styleAbuse = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    
  };

  let isOwner = false;
  let isOwnerChallenger = false;
  console.log(props.challengeId.creatorUid)
  console.log(props.user.id)
  if(props.challengeId.creatorUid === props.user.id){
    isOwner = true;
  }

    console.log(props.props.userId, props.user.id)
  if(props.props.userId === props.user.id){
        isOwnerChallenger = true;
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [fallbackImage, setfallbackImage] = useState(false)
  /* const openMenu = () => setMenuIsOpen(true) */
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
      
const handleLoader = () => {
  console.log("error")
  setfallbackImage(true)
}
  
  console.log({isOwnerChallenger})
  
  let setter = 0
  let setter1 = false
  if(props.props.challengerLikes){
    setter = props.props.challengerLikes.length
    if(props.props.challengerLikes.includes(props.user.id)){
      setter1 = true
    }
  }
  console.log(setter1)
  const [likeCount, setLikeCount] = useState(setter);

  let setter2 = false
  if(props.props.joined){
    setter2 = props.props.joined
  }
  const [joined, setJoined] = useState(setter2);
  const [liked, setLiked] = useState(setter1);

  let setter3 = false
  if(props.user.favoriteGotchas && props.user.favoriteGotchas.includes(props.props.mainPic)){
      setter3 = true
  }
  const [favorite, setfavorite] = useState(setter3);
  const [msgInputVisible, setmsgInputVisible] = useState(false);
  const [shareLinkVisible, setshareLinkVisible] = useState(false);
  const [openAbuse, setOpenAbuse] = useState(false);
  const [linkCopied, setlinkCopied] = useState(false);
  const [msgContent, setmsgContent] = useState("");
  const [openSnackbar, setopenSnackbar] = useState(false);
  const [snackbarText, setsnackbarText] = useState("Please enter your comment");
  let hrsago = Math.floor((Number(Date.now()) - Number(props.props.timestamp)) / (60 * 1000*60))
  let gotchaNameString = hrsago + " hours ago"
  if(props.usage != "feed"){
    let creatorName = props.challengeId.successfulChallengers[0].name;
    let gotchaNameString = creatorName + "'s gotcha (" + hrsago + " hours ago)"
  }
  
  if(isOwner){  
    gotchaNameString = hrsago + " hours ago"
  }
  if(hrsago > 48){
    hrsago = Math.floor(hrsago/24) + " days ago";
    gotchaNameString = hrsago 
  }

  if(props.jot === 0 && props.challengeId.successfulChallengers.length > 1){
    gotchaNameString = gotchaNameString+" ("+props.challengeId.successfulChallengers.length+" participants)"
    console.log(gotchaNameString)
  }
  const { overrides, ...rest } = props;
  const handleClose = () => setmsgInputVisible(false);
  const handleCloseShareLink = () => {
    setshareLinkVisible(false)
    setlinkCopied(false)
  };
  const handleCloseAbuse = () => {
    setOpenAbuse(false)
  };
  const handleCloseSnackbar = () => setopenSnackbar(false);
  const navigate = useNavigate();

  const openAbuseHandler = () => {
    setOpenAbuse(true)
  }
  
  
  const action = (
    <React.Fragment>
      
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
      <IconClose />
      </IconButton>
    </React.Fragment>
  );

  const joinGotcha = async () => {
    console.log({props})
    await JoinGotcha(props.challengeId)
  }

  const seeChallenger = async () => {
    console.log({props})
    
    if(props.props.challenge){
    navigate('/gotchaaa/'+props.challengeId+"/"+props.props.id)
    } 


  }

  async function sendLike(){
    console.log({liked})
    let likeObj = {challengerId: props.props.challengerId, like: !liked}
    console.log({likeObj})
    await LikeChallenger(likeObj)

    if(liked){
      setLikeCount(likeCount-1)
    }
    if(!liked){
      setLikeCount(likeCount+1)
    }
    setLiked(!liked)
  }

  async function sendMarkFavorite(){
    console.log({favorite})
    let favObj = {mainPic: props.props.mainPic, favorite: !favorite, userId: props.user.id}
    console.log({favObj})
    await SendMarkFavorite(favObj)
    setfavorite(!favorite)
    loadMyGotchaaas()
     
  }

  async function sendMsg(replyObj){
    setmsgInputVisible(true)

  }
  async function showShareAbleLink(replyObj){
    setshareLinkVisible(true)

  }

  async function sendFinalMsg(){
    if(msgContent != ""){
    let sendObj = {challengeId: props.challengeId.challengeId, challengerId: props.props.challengerId, msg: "tests"}
    sendObj.msg = msgContent
    console.log({sendObj})
    await ReplyToChallenger(sendObj)
    setmsgInputVisible(false)
    } else {
      setsnackbarText("Please enter your comment")
      setopenSnackbar(true)
    }
  }

  async function checkLiked(){
    let userId = props.user.id
    console.log({userId})

    let challengerLikes = props.props.challengerLikes
    console.log({challengerLikes})

    if(challengerLikes && challengerLikes.indexOf(userId) != -1){
      setLiked(true)
      
    }

  }
  function CopyToClipboard(e){
    
    setlinkCopied(true)
    console.log(props)
    let copyString = "localhost:3000/joinCustomId/"+props.challengeId.challengeId+"/"+props.props.challengerId
    navigator.clipboard.writeText(copyString)
  }
  /* const innerWidth = window.innerWidth; */

  useEffect(() => {
    console.log({props})
    checkLiked()
    if(props.props.challengerLikes && props.props.challengerLikes.includes("empty")){
      setLikeCount(props.props.challengerLikes.length-1);
    } 
    
  },[])

  return (
    <>
    <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarText}
        action={action}
      />

<Modal
        open={openAbuse}
        onClose={handleCloseAbuse}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={styleAbuse}>
        
                
                
                    <Flex direction="row" >
                {/* <StepperHori step={1}/> */}
                <Flex
                    direction="column"
                    
                    style={{width: "100%"}}
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                    
                >
                <Flex direction="column" style={{width: "80%"}}>
              
                <ScrollView style={{height: "600px"}}>
                <AbuseReport props={props} />
                </ScrollView>
                </Flex>
                
                </Flex>
                
               
                </Flex>
                
            
            
               </Box>
               </Modal>
      
      
    <Modal
        open={shareLinkVisible}
        onClose={handleCloseShareLink}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
        
                
                <div style={{maxWidth: "375px"}}>
                    <Flex direction="row" gap="small">
                {/* <StepperHori step={1}/> */}
                <Flex
                    direction="column"
                    gap="small" 
                    style={{width: "100%"}}
                    alignContent="center"
                    justifyContent="center"
                    alignItems="center"
                    
                >
                <Flex direction="column" gap="small" style={{width: "80%"}}>
               <Text fontSize={25}>
                Share Gotchaaa
                </Text>
               <Text fontSize={12}>
                Click on the right symbol to copy shareable link
                </Text>
               <Text style={{fontWeight: "bold"}}>
                Challenge ID
                </Text>
               <Text>
                {props.challengeId.challengeId}
                </Text>
                <Text style={{fontWeight: "bold"}}>
                Challenger ID
                
                </Text>
                <Text >
                
                {props.props.challengerId}
                </Text>
                </Flex>
                
                </Flex>
                
                <Flex direction={"row"} alignContent="center"
                    justifyContent="center"
                    alignItems="center" style={{padding:"20 0 0 0"}}>
                     {!linkCopied ? <ContentCopyIcon onClick={() => CopyToClipboard()} style={{fontSize: 50, color: "DarkMagenta"}} />: 
                     <>
                     <Flex direction="column" gap="0">
                      <CheckCircleIcon style={{fontSize: 50, color: "green"}} />
                      <Text fontSize={12}>
                      Copied!
                      </Text>
                      </Flex>
                     
                     </>}
                
                </Flex>
                </Flex>
                
            </div>
            
               </Box>
               </Modal>
    <Modal
        open={msgInputVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
        
                
                <div style={{maxWidth: "375px"}}>
                    <Flex direction="row" gap="small">
                {/* <StepperHori step={1}/> */}
                <Flex>
                <TextField
                    id="outlined-multiline-static"
                    label="Message"
                    multiline
                    rows={4}
                    defaultValue={"e.g. Awesome picture, "+props.props.name+"!"}
                    
                    variant="outlined"
                    onChange={(e) => setmsgContent(e.target.value)}
                />
                </Flex>
                <Flex style={{padding:"20 0 0 0"}}>
                <SendIcon onClick={() => sendFinalMsg()} style={{fontSize: 50, color: "DarkMagenta"}} />
                </Flex>
                </Flex>
            </div>
            
               </Box>
               </Modal>
    
    <Flex
      gap="0"
      direction="column"
      width="374px"
      height="552px"
      position="relative"
      padding="0px 0px 0px 0px"
      
      {...rest}
      {...getOverrideProps(overrides, "GotchaSingleDisplay")}
    >
      <Flex
        gap="0"
        direction="column"
        shrink="0"
        position="relative"
        padding="10px 0px 10px 0px"
        {...getOverrideProps(overrides, "Frame 2934772783")}
      >
        <Flex
          gap="0px"
          width="fit-content"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 2934772784")}
        >
          {isOwner ? <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="700"
            color="rgba(139,0,139,1)"
            lineHeight="16px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={gotchaNameString}
            {...getOverrideProps(overrides, "1 Minute ago")}
          ></Text>:
          <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="700"
            color="rgba(217,217,217,1)"
            lineHeight="16px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={gotchaNameString}
            {...getOverrideProps(overrides, "1 Minute ago")}
          ></Text>}
        </Flex>
        <Flex
          gap="16px"
          width="fit-content"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="15px 15px 15px 15px"
          {...getOverrideProps(overrides, "Frame 2834772786")}
        >
          <Flex
            gap="12px"
            width="fit-content"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            height="37px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 2834772787")}
          >
            {/* Profilepicture */}
            {props.props.profilPicture || props.props.profilPic ? 
            <>
            {props.props.profilPicture && <AmplifyS3Image imgProps={{style: {"border-radius": "50%"}}} style={{"--width": "50px"}} imgKey={props.props.profilPicture} />}
            {props.props.profilPic && <AmplifyS3Image imgProps={{style: {"border-radius": "50%"}}} style={{"--width": "50px"}} imgKey={props.props.profilPic} />}
            </> 
            
               :
               <AmplifyS3Image imgProps={{style: {"border-radius": "50%"}}} style={{"--width": "50px"}} imgKey={"profileDefault.jpg"} />
              /*  <Image
               width="37px"
               height="37px"
               shrink="0"
               position="relative"
               borderRadius="160px"
               padding="0px 0px 0px 0px"
               {...getOverrideProps(overrides, "image34772788")}
             ></Image> */
      }
            
            <Flex
              gap="0"
              direction="column"
              shrink="0"
              height="34px"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "NameLocation")}
            >
            
              <Text
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="16.5px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                letterSpacing="0px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Frank"
                {...getOverrideProps(overrides, "Frank")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="8px"
                fontWeight="400"
                color="rgba(0,0,0,1)"
                lineHeight="16.5px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                letterSpacing="0px"
                width="62px"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Basel"
                {...getOverrideProps(overrides, "Basel")}
              ></Text>
            </Flex>
            <Flex>
              
                <MoreVertIcon onClick={handleClick} style={{fontSize: 30, color: "DarkMagenta"}} />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={openAbuseHandler}>Report Gotchaaa</MenuItem>
                </Menu>
              
            </Flex>
            <Flex
              gap="0"
              direction="column"
              shrink="0"
              height="34px"
              /* border={"1px solid black"} */
              position="relative"
              
              padding="0px 0px 0px 150px"
              {...getOverrideProps(overrides, "NameLocation")}
            >
              
              {props.usage != "feed" && <IosShareIcon onClick={() => {showShareAbleLink()}} style={{color: "Indigo", fontSize:"40"}}/>}
              
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="-47px"
        direction="column"
        height="300px"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 418")}
      >
        {/* MainPic */}
        {props.props.mainPic ? 
        <>
        {isOwnerChallenger && props.usage != "feed" && 
         <Flex
              position={"absolute"}
              right={10}
              margin={"0px 0px 0px 00px"}
              >
            {!favorite && 
            
            <StarBorderIcon onClick={() => {sendMarkFavorite()}} style={{color: "black", fontSize:"60"}}/>
            
            
            }
            {favorite && 
            
            <StarIcon onClick={() => {sendMarkFavorite()}} style={{color: "Orange", fontSize:"60"}}/>
            
            
            }
         </Flex>}
         
        {/*  {!fallbackImage && 
          <div style={{transform: 'rotate(-90deg)'}}> 
            <AmplifyS3Image handleOnError={handleLoader} imgProps={{style: {"border-radius": "50%"}}} style={{"--width": "325px"}} imgKey={props.props.mainPic+"1"} />
         </div> 
         }
            {fallbackImage &&
            <AmplifyS3Image imgProps={{style: {"border-radius": "50%"}}} style={{"--width": "325px"}} imgKey={props.props.mainPic} />
            } */}
         <AmplifyS3Image imgProps={{style: {"border-radius": "50%"}}} style={{"--width": "325px"}} imgKey={props.props.mainPic} />
         
        </>
              
               :
          <Image
          height="408px"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "image34772776")}
        ></Image>
      }
      
        
       

        {/* FrontPic */}
        {props.props.frontPic ? 
           <AmplifyS3Image imgProps={{style: {"border-radius": "50%"}}} style={{"--width": "100px"}} imgKey={props.props.frontPic} />  
           :
           <Image
          width="99px"
          height="95px"
          shrink="0"
          position="relative"
          borderRadius="160px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "image34772779")}
        ></Image>
      }
        
      </Flex>

      {props.usage && props.usage === "feed" ?
       <Flex
       gap="210px"
       width="366px"
       alignItems="flex-start"
       shrink="0"
       position="relative"
       padding="51px 38px 51px 45px"
       {...getOverrideProps(overrides, "Frame 419")}
     >
      
        <Flex
           direction="column"
           alignItems={"center"}
         >

         <Flex
             position={"relative"}
             >
           {joined && <BeenhereIcon style={{color: "green", fontSize:"40"}}/>} 
          
        </Flex>

       
        
        </Flex>
       
        {joined ? <VisibilityIcon onClick={() => {seeChallenger()}} style={{color: "Indigo", fontSize:"40"}}/>:
           <>
           <Button
           display="flex"
           margin="10px 0px 0px 20px"
           gap="0"
           
           height="33px"
           justifyContent="center"
           alignItems="center"
           shrink="0"
           position="relative"
           size="small"
           isDisabled={false}
           variation="primary"
           children="Join"
           onClick={joinGotcha}
           {...getOverrideProps(overrides, "Button")}
         ></Button>
         
          </>
         }
        
     </Flex>
      :
      <Flex
        gap="200px"
        width="366px"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="51px 38px 51px 45px"
        {...getOverrideProps(overrides, "Frame 419")}
      >
       {/*  <MyIcon
          width="43px"
          height="43px"
          shrink="0"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          type="favorite"
          onClick={() => {sendLike()}}
          {...getOverrideProps(overrides, "MyIcon34782702")}
        ></MyIcon> */}
         <Flex
            direction="column"
            
            alignItems={"center"}
          >
          <Flex
              position={"relative"}
              >
            {!liked && 
            
            <FavoriteBorderIcon onClick={() => {sendLike()}} style={{color: "black", fontSize:"40"}}/>
            
            
            }
            {liked && 
            
            <FavoriteIcon onClick={() => {sendLike()}} style={{color: "DeepPink", fontSize:"40"}}/>
            
            
            }
         </Flex>

         <Flex
          position={"relative"}
         >
         {likeCount > 0 && <Text style={{paddingLeft: "0px", marginTop: "-20px"}}>{likeCount}</Text>}
         </Flex>
         
         </Flex>
        
          <MessageIcon onClick={() => {sendMsg()}} style={{color: "Indigo", fontSize:"40"}}/>
      </Flex>}
     
    </Flex>
    </>
  );
}
