/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:ca616332-dbc3-4ef7-b30b-8b7d6fe4662b",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_sJpgkd4np",
    "aws_user_pools_web_client_id": "3kuk0d9rutns05u66tovctdd2f",
    "oauth": {
        "domain": "gotchaaac28fb5ee-c28fb5ee-dev.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "myapp://callback/",
        "redirectSignOut": "myapp://signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://elkad4fnena5bablt27xoul2vq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "gotchaimages174336-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "geo": {
        "amazon_location_service": {
            "region": "eu-central-1",
            "search_indices": {
                "items": [
                    "placeindexgotcha-dev"
                ],
                "default": "placeindexgotcha-dev"
            }
        }
    }
};


export default awsmobile;
