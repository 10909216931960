
import { Routes, Route, Redirect, Navigate, useNavigate, Outlet, Link } from 'react-router-dom';
import graphQLService, { GetUser } from '../services/graphQlServices';


import { Layout } from '../components/layout';
import { Login1 } from '../components/login1';
import { Protected } from '../components/Protected';
import { ProtectedSecond } from '../components/ProtectSecond';

import { RequireAuth } from '../components/requireAuth';
import MemberArea from '../pages/MemberArea';

import { useContext, useEffect, useState } from 'react';
import RegForm from '../components/regForm';
import { GetCurrentUser } from '../services/getters';
import { GlobalContext } from '../Context/globalState';
import MenuNew from '../ui-components/MenuNew';
import { ScrollView, View } from '@aws-amplify/ui-react';
import Header from '../components/header';
import { TableRow } from '@mui/material';
import Landing2 from '../components/landingPage2';
import { InitLoaderFunction } from './InitLoaderFunction';
import { PrivacyDeclaration } from '../components/privacyDeclaration';
import { Impressum } from '../components/Impressum';
import MemberAreaPublic from '../pages/MemberAreaPublic';


export default function MyRoutes(props) {
  console.log({props})
  const { loadAvailableGotchaaas, loadMyGotchaaas, loadMyProfile } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [userId, setuserId] = useState("false");
    let path = '/feed'
  async function getUser() {
    const user = await GetCurrentUser();
    let userId = user.username
    setuserId(userId)
    console.log({ userId });
    const userDetails = await GetUser(userId);
    console.log({ userDetails });
    const userData = userDetails.data.getUser
    console.log({ userData });
    if(!userData){
      console.log("no user")
      
      setShouldRedirect(true)
      navigate('/register');
      
    } else {
      loadAvailableGotchaaas()
      loadMyGotchaaas()
      loadMyProfile()
      navigate(path);
    }
    
  }

  function checkPath(){
    if(props.pathname){
      let pathName = props.pathname
      let pathNameArray = pathName.split("/")
      let pathNameArrayLength = pathNameArray.length
      console.log({pathNameArray})
      if(pathNameArrayLength > 1){
        path = props.pathname
      } 
    }
  }


  

  useEffect(() => {
    checkPath()
    getUser()
  }, []);

    return (
      
        <Routes>
          {/* <Route element={
            <>
            
              <TableRow style={{position:"relative"}} >
        <View padding={10}>
                 <Header/>
            </View>
         
        </TableRow>
        <TableRow style={{position:"absolute", top:"10%", width:"100%"}} >
        
        <Outlet/>
        
            </TableRow>
            
            <TableRow  style={{position:"absolute",bottom: 0, top:"10%", width:"100%"}} >
            <View 
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              
            }}
            >
            <MenuNew/>
            </View>
            </TableRow>
            </>
          }> */}
            {/* <Route element={<Outlet />}> */}
            
              <Route path="/feed/*" element={<RequireAuth><MemberArea page={"feed"} /></RequireAuth>} />
              <Route path="/joinCustomId/*" element={<RequireAuth><MemberArea page={"feed"} /></RequireAuth>} />
              <Route path="/gotchaaa/*" element={<RequireAuth><MemberArea page={"gotchaaa"} /></RequireAuth>} />
              <Route path="/chat" element={<RequireAuth><MemberArea id={userId} page={"chat"} /></RequireAuth>} />
            
            {/* </Route> */}
            <Route path="/profile" element={<RequireAuth><MemberArea page={"profile"} /></RequireAuth>} />
            
            
            
            {/* <Route path="/newGotcha" element={<RequireAuth><MemberArea page={"newGotcha"} /></RequireAuth>} />
            <Route path="/frontCam" element={<RequireAuth><MemberArea page={"frontCam"} /></RequireAuth>} />
            <Route path="/mainCam" element={<RequireAuth><MemberArea page={"mainCam"} /></RequireAuth>} /> */}
            <Route path="/login/*" element={<Login1 />} />
            <Route path="/privacy-declaration" element={<PrivacyDeclaration />} />
            <Route path="/imprint" element={<Impressum />} />
            <Route path="/register" element={<RegForm />} />
            <Route index path="/" element={<Landing2 />} />
            <Route path="/previewFeed/*" element={<MemberAreaPublic page={"feed"} />} />
          {/* </Route> */}
        </Routes>
      
    );
  }