
import { API, graphqlOperation } from "aws-amplify"
import { getReferal, getUser } from "../graphql/queries"
import { getChallenge, changeUserPicture, createFeedbackFunction, createReferalFunction, createGotcha, createUserCloud, getMyGotchas, joinGotcha, likeChallenger, listAvailableGotchas, listAvailableGotchasPublic, replyToChallenger, updateUser, markFavorite, createAbuse, createReportAbuseFunction } from './../graphql/mutations'
import * as queries from './../graphql/queries'
import * as mutations from './../graphql/mutations'


  export const CreateGotcha = async (obj:any) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = obj
     let jobString = JSON.stringify(job)
     console.log(jobString)
      let success = await API.graphql(graphqlOperation(createGotcha, {event:jobString}))
      return success
    } catch (err) {
      console.log('error creating job:', err)
    }
  }
  export const CreateUser = async (obj:any) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = obj
     let jobString = JSON.stringify(job)
     console.log(jobString)
      let success = await API.graphql(graphqlOperation(createUserCloud, {event:jobString}))
      return success
    } catch (err) {
      console.log('error creating job:', err)
    }
  }
  export const JoinGotcha = async (obj:any) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = obj
     let jobString = JSON.stringify(job)
     console.log(jobString)
      let success = await API.graphql(graphqlOperation(joinGotcha, {event:jobString}))
      return success
    } catch (err) {
      console.log('error creating job:', err)
    }
  }
  export const createFeedback = async (obj:any) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = obj
     let jobString = JSON.stringify(job)
     console.log(jobString)
      let success = await API.graphql(graphqlOperation(createFeedbackFunction, {event:jobString}))
      return success
    } catch (err) {
      console.log('error creating feedback:', err)
    }
  }
  export const createReport = async (obj:any) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = obj
     let jobString = JSON.stringify(job)
     
     let success = await API.graphql(graphqlOperation(createReportAbuseFunction, {event: jobString}))

      return success
    } catch (err) {
      console.log('error creating feedback:', err)
    }
  }
  export const createReferal = async (obj:any) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = {obj: "empty"}
     let jobString = JSON.stringify(job)
     console.log(jobString)
      let success = await API.graphql(graphqlOperation(createReferalFunction, {event:jobString}))
      return success
    } catch (err) {
      console.log('error creating referal:', err)
    }
  }
  export const GetMyGotchas = async () =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = {}
     let jobString = JSON.stringify(job)
     console.log(jobString)
      let success = await API.graphql(graphqlOperation(getMyGotchas, {event:jobString}))
      return success
    } catch (err) {
      console.log('error creating job:', err)
    }
  }
  export const GetChallenge = async (id, challengerId) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = {challengeId: id, challengerId: challengerId}
     let jobString = JSON.stringify(job)
     console.log(jobString)
      let success = await API.graphql(graphqlOperation(getChallenge, {event:jobString}))
      return success
    } catch (err) {
      console.log('error getting challenge:', err)
    }
  }
  export const ListAvailableGotchas = async () =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = {}
     
     
      let success = await API.graphql(graphqlOperation(listAvailableGotchas, {event:""}))
      return success
    } catch (err) {
      console.log('error creating job:', err)
    }
  }
  export const ChangeUserPicture = async (obj1) =>  {
    console.log({obj1})
    let finalObject = JSON.stringify(obj1)
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     
     
     
      let success = await API.graphql(graphqlOperation(changeUserPicture, {event:finalObject}))

      return success
    } catch (err) {
      console.log('error changing picture:', err)
    }
  }
  export const GetReferalInternal = async (id) =>  {
    try {
      let success = await API.graphql(graphqlOperation(getReferal, {id: id}))
      
      return success
    } catch (err) {
      console.log('error getting referal:', err)
    }
  }
  export const GetAvailableGotchasPublic = async () =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     
      let success = await API.graphql(
        {
          query: mutations.listAvailableGotchasPublic,
          authMode: 'AWS_IAM',
          variables: {
            input: {
              event: "test"
            }
          }
        }
      )
      /* let success = await API.graphql(graphqlOperation(
        getReferal,  {id: id},  {authMode: 'AWS_IAM'}
          
      )
        ) */
      
      return success
    } catch (err) {
      console.log('error GetAvailableGotchas:', err)
    }
  }
  export const AddToWaitingList = async (email) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     
     
     
     
     let body =  {
      getReferal,
      authMode: 'AWS_IAM',
      variables: {
        input: {
          email: email
        },
      },
    }
    let stringifiedBody = JSON.stringify(body)
      let success = await API.graphql(
        {
          query: mutations.createWaitlist,
          authMode: 'AWS_IAM',
          variables: {
              input: {
                email: email
              }
          },
        }
      )
      /* let success = await API.graphql(graphqlOperation(
        getReferal,  {id: id},  {authMode: 'AWS_IAM'}
          
      )
        ) */
      
      return success
    } catch (err) {
      console.log('error getting referal:', err)
    }
  }
  export const GetReferal = async (id) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = {id: id}
     
     console.log(getReferal)
     console.log(id)
     let body =  {
      getReferal,
      authMode: 'AWS_IAM',
      variables: {
        input: {
          id: id
        },
      },
    }
    let stringifiedBody = JSON.stringify(body)
      let success = await API.graphql(
        {
          query: queries.getReferal,
          authMode: 'AWS_IAM',
          variables: {
              id
          },
        }
      )
      /* let success = await API.graphql(graphqlOperation(
        getReferal,  {id: id},  {authMode: 'AWS_IAM'}
          
      )
        ) */
      
      return success
    } catch (err) {
      console.log('error getting referal:', err)
    }
  }
  export const GetUser = async (id) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = {id: id}
     
     
      let success = await API.graphql(graphqlOperation(getUser, {id: id}))
      return success
    } catch (err) {
      console.log('error creating job:', err)
    }
  }
  export const UpdateUser = async (userObj) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = userObj
     console.log({job})
     
      let success = await API.graphql(graphqlOperation(updateUser, {input: job}))
      return success
    } catch (err) {
      console.log('error creating job:', err)
    }
  }
  export const LikeChallenger = async (userObj) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = JSON.stringify(userObj)
     console.log({job})
     
      let success = await API.graphql(graphqlOperation(likeChallenger, {event: job}))
      return success
    } catch (err) {
      console.log('error creating job:', err)
    }
  }

  export const SendMarkFavorite = async (userObj) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = JSON.stringify(userObj)
     console.log({job})
     
      let success = await API.graphql(graphqlOperation(markFavorite, {event: job}))
      return success
    } catch (err) {
      console.log('error creating job:', err)
    }
  }
  export const ReplyToChallenger = async (userObj) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = JSON.stringify(userObj)
     console.log({job})
     
      let success = await API.graphql(graphqlOperation(replyToChallenger, {event: job}))
      return success
    } catch (err) {
      console.log('error creating job:', err)
    }
  }