// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { User, Abuse, Waitlist, Feedback, Referal, Badges, Friends, Group, Challenge, Challenger, Matches, Reports } = initSchema(schema);

export {
  User,
  Abuse,
  Waitlist,
  Feedback,
  Referal,
  Badges,
  Friends,
  Group,
  Challenge,
  Challenger,
  Matches,
  Reports
};