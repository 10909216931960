import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Header from './header';
import { Flex, Grid, LocationSearch, MapView, ScrollView, Text, View } from '@aws-amplify/ui-react';
import { Card, Checkbox, Chip, CircularProgress, IconButton, Input, Modal, Snackbar, Stack, TableRow, TextField } from '@mui/material';
import Home from '@mui/icons-material/Home';

import { useEffect } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';

import { Geo } from 'aws-amplify';
import { CreateUser } from '../services/graphQlServices';
import { GetCurrentUser } from '../services/getters';
import { useNavigate } from 'react-router-dom';
import { AmplifyS3Image } from '@aws-amplify/ui-react/legacy';
import { padding } from '@mui/system';

import CarouselCustom from './carousel';
import { useContext } from 'react';
import { GlobalContext } from '../Context/globalState';
import { ToS } from './ToS';
import { IconClose } from "@aws-amplify/ui-react/internal";


let interestsInitCloud = [
  { key: 'vintage clothing', val: 'vintage clothing', isChecked: false, no: 5},
  { key: 'organic food', val: 'organic food', isChecked: false, no: 5},
  { key: 'dating', val: 'Staubsauger', isChecked: false, no: 5},
  { key: 'health & fitness', val: 'Staubsauger', isChecked: false, no: 5},
  { key: 'art', val: 'Staubsauger', isChecked: false, no: 5},
  { key: 'minimalism', val: 'minimalism', isChecked: false, no: 5},
  { key: 'craftmanship & hoobies', val: 'organic food', isChecked: false, no: 5},
  { key: 'indie music', val: 'indie music', isChecked: false, no: 5},
  { key: 'party', val: 'party', isChecked: false, no: 5},
  { key: 'self-improvment', val: 'Staubsauger', isChecked: false, no: 5},
  { key: 'travel', val: 'travel', isChecked: false, no: 5},
  { key: 'rap', val: 'Staubsauger', isChecked: false, no: 5},
  { key: 'environment', val: 'Staubsauger', isChecked: false, no: 5},
  { key: 'movies', val: 'Staubsauger', isChecked: false, no: 5},
  { key: 'science', val: 'Staubsauger', isChecked: false, no: 5},
  { key: 'vegan', val: 'Staubsauger', isChecked: false, no: 5},
  { key: 'technology', val: 'Staubsauger', isChecked: false, no: 5},
  { key: 'gaming', val: 'Staubsauger', isChecked: false, no: 5},
]

const steps = [
  {
    label: 'Enter first name or nickname',
    description: `Select your firstname or nickname that people will see on Gotchaaa.`,
    inputFieldName: 'username'
  },
  {
    label: 'Enter your birthdate',
    description: ``, /* This information will never be public. Global KYC guidelines require us to collect this information. */
    inputFieldName: 'birthdate'
  },
  {
    label: 'Select your home location',
    description:
      'Please select from list', /* We just need a rough idea of where you are from. */ 
  },
  {
    label: 'Select your interests',
    description: `Don't worry... You can change this later in your profile.`,
  },
];

export default function RegForm() {
  const {referalId} = useContext(GlobalContext);
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [location, setLocation] = useState(0);
  const [randomNo1, setrandomNo1] = useState(4);
  const [randomNo2, setrandomNo2] = useState(0);
  const [changeLocation, setChangeLocation] = useState(true);
  const [interestCloud, setInterestCloud] = useState(interestsInitCloud);
  const [interestCounter, setInterestCounter] = useState(0);
  const [newUserObject, setNewUserObject] = useState({});
  const [open, setOpen] = useState(false);
  const [tosChecked, settosChecked] = useState(false);
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarText, setsnackbarText] = React.useState("false");
  const [loading, setloading] = useState(false)
         
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  }
    

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
 const invertCheck = () => {
  settosChecked(!tosChecked);
 }
 const action = (
  <React.Fragment>
    
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseSnackbar}
    >
    <IconClose />
    </IconButton>
  </React.Fragment>
);

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if(activeStep === 0){
      console.log('step1')
      console.log(name.length)
      if(name.length < 4){
        setActiveStep((prevActiveStep) => prevActiveStep -1);
        setsnackbarText("Min. 4 characters");
        setOpenSnackbar(true);
      }
    }
    if(activeStep === 1){
      console.log('step2')
      console.log(birthdate)
      console.log(birthdate.length)
      if(birthdate.length < 4){
        setActiveStep((prevActiveStep) => prevActiveStep -1);
        setsnackbarText("Select your birthdate");
        setOpenSnackbar(true);
      }
    }
    if(activeStep === 2){
     
      let search = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input maplibregl-ctrl-geocoder--input')[0].value
      setLocation(search)

      if(search.length < 4){
        setActiveStep((prevActiveStep) => prevActiveStep -1);
        setsnackbarText("Select a location from the list");
        setOpenSnackbar(true);
      }
    }
    if(activeStep === 3){
      let selectedInterests = []
      console.log({interestCloud});
      for(let el of interestCloud){
        if(el.isChecked === true){
         selectedInterests.push(el.key)
        }
      }
      console.log({location})
      console.log({name})
      console.log({birthdate})
      let user = await currentUser()
      Geo.searchByText(location).then((data) => {
        console.log(data)
        let lat = data[0].geometry.point[1]
        let long = data[0].geometry.point[0]
        
        console.log({location})
        console.log({referalId})
        let newUserObjectLocal = {id: user, interests: selectedInterests, name: name, birthdate: String(birthdate), location: location, lat: String(lat), long: String(long)}
        if(referalId){
          newUserObjectLocal.referalId = referalId
        }
        setNewUserObject(newUserObjectLocal)
        console.log({newUserObjectLocal})
        
      })

    }
  };

  const handleSend = async () => {
    setloading(true)
    console.log({newUserObject})
    await CreateUser(newUserObject).then(el => console.log(el))
    navigate('/feed')
    setloading(false)
    window.location.reload()
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (event) => {
    console.log(event);
  }
  const handleLocate = () => {
    let search = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input maplibregl-ctrl-geocoder--input')[0].value
    setChangeLocation(!changeLocation)
    console.log(search)
  }

  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  function change(e){
    console.log(e)
    if(e.tags){
      random()
      if(interestCounter < 5 || e.tags[1]){
        let counter = 0
        for(let el of interestCloud){
     
          if(e.tags[0] === el.key){
            /* console.log(el.key)
            console.log(el.isChecked)
            console.log(e.tags[0]) */
          el.isChecked = !el.isChecked
          
          /* console.log(el.isChecked) */
          }
      }
      for(let el of interestCloud){
        if(el.isChecked){
          counter++
        }
      }
      setInterestCloud([...interestCloud])
      setInterestCounter(counter)
      }
      
      
    }
   
  
  console.log(interestCloud)
  
  }
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  function random(){
    
      //Script to shuffle number from 0 to length of interestCloud
      let random1 = 0
      while(random1 < 6){
       random1 = Math.floor(Math.random() * interestCloud.length)
      }
      setrandomNo1(random1)
      setrandomNo2(random1-6)
      console.log(random1)
  
    }

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: window.innerWidth*0.9,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 0,
    };

  function showAll(){
    
      //Script to shuffle number from 0 to length of interestCloud
      let random1 = 0
      
      setrandomNo1(interestCloud.length-1)
      setrandomNo2(random1)
      console.log(random1)
  
    }

  async function currentUser(){
    const user = await GetCurrentUser();
    return user.username
  }
  
  
 
  return (
 
        <>
        {loading && <CircularProgress />}
        <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarText}
        action={action}
      />
         <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
         <ToS />
         </Box>
      </Modal>
        <Header width="40%"/>
        
<ScrollView height={window.innerHeight-150}>
  <Grid margin={"2rem"} marginBottom="50px">
<Text
  margin={"1rem"}
  fontSize={"1.5rem"}
>
  A few more steps to get you started 🚀
</Text>
<Text
  margin={"0rem"}
  fontSize={"0.5rem"}
>
  Use tab to navigate between fields
</Text>
   
  <TableRow >
  
  
  {/* <Button onClick={handleLocate}>Locate</Button> */}
  </TableRow>

  <View >
  <Box sx={{ maxWidth: 400}}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 3 ? (
                  <>
                  {interestCounter === 0 && <Typography variant="caption">Please select your five favorite interests {interestCounter} / 5</Typography>}
                  {interestCounter > 0 && interestCounter < 5 && <Typography variant="caption"> {interestCounter} / 5 interests selected</Typography>}
                  {interestCounter === 5 && <Typography variant="caption"> All done! {interestCounter} interests selected.</Typography>}
                  </>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
            
              {index === 0 &&
              <View  padding="1rem">
              <TextField
                required
                id="outlined-required"
                label="First Name"
                value={name}
                onChange={e => setName(e.nativeEvent.target.value)}
                autoComplete="off"
              />
              </View> }
              {index === 1 &&
              <View  padding="1rem">
              <Input
              type="date"
                required
                id="outlined-required"
                label="Birthdate"
                value={birthdate}
                onChange={e => setBirthdate(e.nativeEvent.target.value)}
                autoComplete="off"
              />
              </View> }
              {index === 2 && <View padding="1rem">
                {location != "" && 
                <>
                 
                <View padding="1rem">
                  <Typography fontSize={20} variant="caption">Selected location: <br/> {location}</Typography>
                  </View>
                <Button  onClick={() => setLocation("")}>Change location</Button>
                  </>  
                  }
                  {location == "" && <LocationSearch placeholder='Enter your location' id='Search' />}
               
              </View>}
              {index === 3 && 
              <>
              <View  padding="1rem" >
                 <Stack spacing={1} alignItems="center">
                <Stack spacing={1} >
                {interestCloud.filter((el, i) => el.isChecked).map(({ key, isChecked }, i) => {
                /*   console.log(key)
                  console.log(isChecked) */
                 return (
                  <React.Fragment>
                  
                  
                    {isChecked &&
                    <Chip
                    label={key}
                    onClick={(e:any) => {change({tags: [key, isChecked]})}}
                    deleteIcon={<DoneIcon />}
                    onDelete={(e:any) => {change({tags: [key, isChecked]})}}
                  /> 
                    }
                
                  
                  </React.Fragment>
                  )
                 
                }
                )
                }
                
                </Stack>
                </Stack>
             
              </View> 
              <View  padding="1rem" >
                 <Stack spacing={1} alignItems="center">
                <Stack spacing={1} >
                {interestCloud.filter((el, i) => i >= randomNo2 && i <= randomNo1).map(({ key, isChecked }, i) => {
                /*   console.log(key)
                  console.log(isChecked) */
                 return (
                  <React.Fragment>
                    {!isChecked &&  <Chip
                    label={key}
                    onClick={(e:any) => {change({tags: [key, isChecked]})}}
                  /> }
                  
                  </React.Fragment>
                  )
                 
                }
                )
                }
                
                </Stack>
                </Stack>
                <View padding="1rem">
                {/* <Button color='primary' onClick={(e:any) => {showAll()}}>Show all</Button> */}
                <Button color='primary' onClick={(e:any) => {showAll()}}>Show all</Button>
                </View>
              </View> 
              </>
              }

              
             
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    disabled={interestCounter < 5 && index === 3}
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          {/* <Text>All steps completed - you&apos;re finished</Text> */}
          <Flex style={{margin:"20px"}}>
          <Flex item xs={1}>
          <Checkbox checked={tosChecked} onChange={invertCheck} />
        </Flex>
        <Flex item xs={4}>
          <Text >I'm over 18 years and accept the <a onClick={handleOpen} >Terms of Service</a></Text>
        </Flex>
            </Flex>
          <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
          {tosChecked ?
           <Button variant="contained"onClick={handleSend} sx={{ mt: 1, mr: 1 }}>
            Send
          </Button> : 
          <Button variant="contained" disabled sx={{ mt: 1, mr: 1 }}>
          Send
        </Button>}
        </Paper>
      )}
    </Box>
    </View>
    </Grid>
    </ScrollView>
    </>
  

    
  );
}
