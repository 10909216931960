import { Divider, Flex, ScrollView, Text, View } from "@aws-amplify/ui-react"
import { Button, CircularProgress } from "@mui/material";
import { DataStore } from "aws-amplify";
import { createRef, useContext, useEffect, useRef, useState } from "react";
import { useComponentDidMount } from "../hooks/lifecycleHooks";
import { Challenge, User } from "../models";
import { GetCurrentUser } from "../services/getters";
import SwipeIcon from '@mui/icons-material/Swipe';

import { GetMyGotchas } from "../services/graphQlServices";
import { CreateNewGotchaButton, GotchaElementFeed } from "../ui-components"
import GotchaSingleDisplayCustom from "../ui-components/GotchaSingleDisplayCustom";
import { GlobalContext } from "../Context/globalState";
import { isUUID } from "../services/externalFunctions";


const demoFeed = [0,1,2,3,4]
const demoFeed2 = [0,1,2,3,4]
let counter = 0
export default function BodyGotchaaa (user1){
    const {myProfile} = useContext(GlobalContext)
    const user = myProfile
    console.log({user})
    const { myGotchaaas } = useContext(GlobalContext);
    console.log({myGotchaaas})
    const [gotchas, setGotchas] = useState([])
    const [noGotchasFound, setNoGotchasFound] = useState(false)
    const [loading, setloading] = useState(false)
    let myRef = createRef();
    myRef = []
    let myRefChallengers = []
    let challengersLocal = []

    console.log(window.location.pathname)
    let path = window.location.pathname
    let pathArray = path.split("/")
    console.log({pathArray})
    console.log(pathArray.length)
    function scrollToComponent() {
        
        console.log({myRef})
        console.log({challengersLocal})
        
        console.log(window.location)
        console.log(window)
        let challengeIdFromUrl = window.location.pathname.split("/")[2]
        let challengerIdFromUrl = window.location.pathname.split("/")[3]
        console.log({challengeIdFromUrl})
        console.log({challengerIdFromUrl})
        let isUuid1 = true /* isUUID(challengeIdFromUrl)  */
        let isUuid2 = true /* isUUID(challengerIdFromUrl)  */
        console.log({isUuid1})
        console.log({isUuid2})
        console.log({challengeIdFromUrl})
        console.log({myRefChallengers})
        if(isUuid1 && isUuid2){
        let j = 0
        for(let el of challengersLocal){
            console.log(el)
            if(el.challenger == challengerIdFromUrl){
                console.log("challenger found")
                el.ref.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
               break
            }
            j++
        }
        }
        
      }
useEffect(() => {
    /* if (window.location.pathname.indexOf('/') > 1) { */
        scrollToComponent()
      /* } */
}, [])
    
    
    async function getChallenges(){
        setloading(true)
        let currentUser = await GetCurrentUser()
        console.log(currentUser.username)
        if(counter === 0){
            let myGotchas = await GetMyGotchas()
            
            if(myGotchas.data.getMyGotchas != "bad"){
                let myGotchasObj = JSON.parse(myGotchas.data.getMyGotchas)
                setGotchas(myGotchasObj)
                console.log({myGotchasObj})
                counter++
                setloading(false)
             
                console.log({myRef})
            } else {
                setNoGotchasFound(true)
                setloading(false)
                
            }
            
        }
        
       
    }
 
    const innerHeightContent = window.innerHeight-150
    const innerWidth = window.innerWidth-10
    

   
    
    
    return(
        <>
        {loading && <CircularProgress />}
        {myGotchaaas.length === 0 && 
        <>
        <Text
            fontSize={20}
            fontweight="bold"
            >No Gotchas Found</Text>
        <Text
            fontSize={14}
            margin="1rem"
            >
                Go to "Feed" to create or join a Gotchaaa, afterwards you will see your gotchaaas here.
            </Text>
        </>
        }
        {myGotchaaas.sort((a, b) => a.successfulChallengers[0].timestamp < b.successfulChallengers[0].timestamp ? 1 : -1).map((item,i) => {
            console.log(item)
            console.log(i)
            console.log(gotchas.length)
            if(i === gotchas.length-1){
                console.log("last")
                setTimeout(() => {
                scrollToComponent()
                }, 1000);
            }
            return(
               <>
                <div ref={(ref) => { 
                    if(ref != null){
                    myRef[i] = {ref:ref, key: item.challengeId}
                     
                    }
                    }} key={item.challengeId} id='#api-doc' /* style={{marginTop: 50}} */>
                    <Divider></Divider>
                    {/* <Text color={"lightgray"} style={{fontSize:10}}><b>Gotcha 12.09.21 - 14:30</b></Text> */}
                    <ScrollView style={{marginLeft: "0.5rem"}} maxWidth={innerWidth} height="600px">
                    <Flex>
                        {item.successfulChallengers.map((item1, j) => {
                            return ( 
                                <>
                              {j > 0 && innerWidth > 500 &&  <Divider orientation="vertical" flexItem></Divider>}
                            <View ref={(ref) => { 
                                
                                if(ref != null){
                                    challengersLocal.push({ref:ref, challenger: item1.challengerId, challenge: item.challengeId})                                    
                                }
                                }}>
                            <GotchaSingleDisplayCustom jot={j} props={item1} user={user} challengeId={item} overrides={
                                {
                                image34772788: {src: "https://images.pexels.com/photos/2726111/pexels-photo-2726111.jpeg?auto=compress&cs=tinysrgb&w=630&h=375&dpr=2"},
                                image34772776: {src: "https://images.pexels.com/photos/12476792/pexels-photo-12476792.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"},
                                image34772779: {src: "https://www.kukksi.de/wp-content/uploads/2022/03/Selfie.png"},
                                "Frank": {children: item1.name},
                                "Basel": {children: item1.location}
                                }
                                    } />
                                    
                                  {item.successfulChallengers.length > 1 && j === 0 &&  <SwipeIcon />}
                                    </View>
                                    
                                    
                                    </>
                                    )
                        })
                        }
                        </Flex>
                        
                    </ScrollView>
                </div>
                </>
            )
        })}
       
        
        </>
    )

}