/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, { useContext, useState } from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, ScrollView, Text } from "@aws-amplify/ui-react";
import MyIcon from "./MyIcon";
import { Box, Button, CircularProgress, Modal } from "@mui/material";
import uuid from 'react-uuid';

import { AmplifyS3Image, AmplifyS3ImagePicker } from '@aws-amplify/ui-react/legacy';
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { ChangeUserPicture } from "../services/graphQlServices";
import { GlobalContext } from "../Context/globalState";



export default function GotchaaProfileCustom(props) {
  const { overrides, ...rest } = props;
  const { myProfile, loadMyProfile, loadMyGotchaaas, stateChange } = useContext(GlobalContext);
  
  console.log({props})
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [upload, setUpload] = useState(false)
  const [loading, setloading] = useState(false)
  const [newProfilePic, setnewProfilePic] = useState("")
  const [mainPicture, setmainPicture] = useState(props.props.profilPicture)
  const [user1, setUser1] = useState("")
  const profilChange = () => {
    setUpload(true)
  }
  const handleClose = () => setUpload(false);
  
  const handleUpload = async (e) => {
    
  }

 function namePicture(){
    setnewProfilePic("")
    let uuid2 = uuid()
    setmainPicture("")
    
    
      
    setUpload(false)
     timer(uuid2)
    console.log(uuid2)
    
    return uuid2
  }

  async function timer(picUuid){
    setloading(true)
    
    
    setTimeout(async () => {
      updateProfilpic(picUuid)
      setnewProfilePic(picUuid)
      setloading(false)
      await loadMyProfile()
  }, 2000);
 
    
}

async function updateProfilpic(picUuid){
  let newPicObj = {id: props.props.id, profilPicture:picUuid}
  try{
    await ChangeUserPicture(newPicObj)
  } catch (err){
    console.log(err)
  }
}
/* async function getUser(){
  
 
  const user1 = await Auth.currentAuthenticatedUser().then(el => {

    setUser1(el.username)

  })
  console.log({user1})
}
useEffect(() => {
  getUser()

}, []) */

  return (
    <Flex
      gap="16px"
      direction="column"
      
      alignItems="center"
      position="relative"
      padding="0px 22px 0px 22px"
      {...rest}
      {...getOverrideProps(overrides, "GotchaaProfile")}
    >
      {/* Profilpicture */}
      {/* <Image
        width="160px"
        height="160px"
        shrink="0"
        position="relative"
        borderRadius="160px"
        padding="0px 0px 0px 0px"
        onClick={profilChange}
        {...getOverrideProps(overrides, "image")}
      ></Image> */}
      
      
      {newProfilePic != "" &&
            <AmplifyS3Image onClick={profilChange} imgProps={{style: {"border-radius": "50%"}}} style={{"--width": "150px"}} imgKey={newProfilePic} />
    }

    {newProfilePic === "" && !loading &&
        <>
            <AmplifyS3Image onClick={profilChange} imgProps={{style: {"border-radius": "50%"}}} style={{"--width": "100px"}} imgKey={mainPicture} />
            {mainPicture === "profileDefault.jpg" && <Text onClick={profilChange} style={{marginTop: -30,fontSize: 10, color:"DarkMagenta"}}>Update your profile picture</Text>}
            </>
            }
            {/* {newProfilePic != "" && mainPicture == "" &&  <Button onClick={updateProfilpic} >Change</Button>} */}
      {loading && <CircularProgress />}
      {upload && 
       <Modal
        open={upload}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
        
                <ScrollView>
                <div style={{maxWidth: "375px"}}>
                    
                {/* <StepperHori step={1}/> */}
                <AmplifyS3ImagePicker fileToKey={namePicture} headerTitle="Profile picture" headerHint="Upload a new profile picture" />
                
            </div>
            </ScrollView>    
               </Box>
               </Modal>}
      <Flex
        gap="8px"
        direction="column"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Name")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="25px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Melinda Marcus"
          {...getOverrideProps(overrides, "Melinda Marcus")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(48,64,80,1)"
          lineHeight="24px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.01px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Design Engineer at Cloth Studios"
          {...getOverrideProps(overrides, "Design Engineer at Cloth Studios")}
        ></Text>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(48,64,80,1)"
        lineHeight="24px"
        textAlign="center"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        letterSpacing="0.01px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="123 Gotchaaas"
        {...getOverrideProps(overrides, "123 Gotchaaas")}
      ></Text>
      <Flex
        gap="16px"
        width="fit-content"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Friends")}
      >
        <MyIcon
          width="24px"
          height="24px"
          shrink="0"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          type="group"
          {...getOverrideProps(overrides, "MyIcon")}
        ></MyIcon>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(48,64,80,1)"
          lineHeight="24px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.01px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="99 Friends"
          {...getOverrideProps(overrides, "99 Followers")}
        ></Text>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="223px"
        height="48px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 424")}
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(48,64,80,1)"
          lineHeight="24px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.01px"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Last Gotcha: 12.09.2022"
          {...getOverrideProps(overrides, "Last Gotcha: 12.09.2022")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          color="rgba(48,64,80,1)"
          lineHeight="24px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.01px"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Registered since: 10.09.2022"
          {...getOverrideProps(overrides, "Registered since: 10.09.2022")}
        ></Text>
      </Flex>
    </Flex>
  );
}
