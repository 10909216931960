import { Flex, ScrollView, Text, Divider } from "@aws-amplify/ui-react"
import { useContext, useEffect, useId, useState } from "react";
import { AddGotcha, CreateNewGotchaButton, GotchaElementFeed } from "../ui-components"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import uuid from 'react-uuid';
import {Adsense} from '@ctrl/react-adsense';

import StepperHori from "./stepper";
import { AmplifyS3ImagePicker } from '@aws-amplify/ui-react/legacy';
import AddGotchaCustomized from "../ui-components/AddGotchaCustomized";
import { DataStore } from "aws-amplify";
import { Challenge } from "../models";

import { GetChallenge, JoinGotcha, ListAvailableGotchas } from "../services/graphQlServices";
import GotchaElementFeedCustom from "../ui-components/GotchaElementFeedCustom";
import { TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GotchaSingleDisplayCustom from "../ui-components/GotchaSingleDisplayCustom";
import { GlobalContext } from "../Context/globalState";
import { isUUID } from "../services/externalFunctions";
import { GetCurrentUser } from "../services/getters";




const demoFeed = [0,1,2,3,4]
let joinInfo = {isMember: true}
const innerHeight = window.innerHeight
const innerWidth = window.innerWidth
const bodyHeight = window.innerHeight-100
let marginLeftCustom = "1.2rem"
if(innerWidth > 500){
    marginLeftCustom = "40%"
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: innerWidth*0.8,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  /* async function getChallenges(){
    let challenges = await DataStore.query(Challenge)
    console.log({challenges})
  } */

export default function BodyHome(props){
  console.log({props})
    const {availableGotchaaas, myGotchaaas, loadMyGotchaaas, myProfile} = useContext(GlobalContext)
    const [open, setOpen] = useState(false);
    const [joinGotcha, setJoinGotcha] = useState(false);
    const [joinGotchaData, setJoinGotchaData] = useState(false);
    const [gotchas, setGotchas] = useState(availableGotchaaas)
    const [customGotchaaas, setcustomGotchaaas] = useState(false)

    const navigate = useNavigate();
    console.log({availableGotchaaas})
    console.log({myProfile})

    const handleClose = () => setOpen(false);
    const [newId, setnewId] = useState("")
    const handleOpen = () => setOpen(true);

    const handleOpenCreateGotcha = () => {
      setJoinGotcha(false)
      setJoinGotchaData(false)
      setOpen(true)
    
     function loadGotchas(){

     } 
      
    };
   function handleOpenJoinGotcha1(item) {
    console.log({item})
      setOpen(true)
      setJoinGotcha(true)
      setJoinGotchaData(item)
    };
   function handleOpenSpecificGotcha(item) {
    console.log({item})
      navigate('/gotchaaa/'+item.id)
    };


   async function loadCustomChallenge(){
      let pathname = window.location.pathname
    console.log({pathname})
      let challengeID1 = pathname.split("/")[2]
      let challengerID1 = pathname.split("/")[3]
      let validUUID1 = true /* isUUID(challengeID1) */
      let validUUID2 = true /* isUUID(challengerID1) */
      console.log({challengeID1, challengerID1, validUUID1, validUUID2})
      if(validUUID1 && validUUID2 && challengeID1 != null && challengerID1 != null){
      console.log({challengeID1})
      let challengeResponse1 = await GetChallenge(challengeID1, challengerID1)
      console.log({challengeResponse1})
      let customChallengeResponseData =  JSON.parse(challengeResponse1.data.getChallenge)
      console.log({customChallengeResponseData})
      
      
      setcustomGotchaaas(customChallengeResponseData)
      }
    }
   let counter = 0


console.log({availableGotchaaas})
useEffect(() => {
  loadCustomChallenge()
  
}, [])


    return(
        <>
        <ScrollView style={{marginLeft: marginLeftCustom}} width={innerWidth} height={bodyHeight} maxWidth="375px">
          
        {customGotchaaas && 
        <>
        <Text color={"green"} fontWeight="bold"  >
          Your Invite
        </Text>
        <GotchaElementFeedCustom style={{marginBottom: 30, border:"4px dotted", color:"green"}} props={customGotchaaas} onClick={() => handleOpenJoinGotcha1(customGotchaaas)} overrides={
            {
                'Frank': {children: customGotchaaas.name},
                'Basel': {children: customGotchaaas.location},
                'Interests': {children: ""},
                '1 Minute ago': {children: (Math.floor((Number(Date.now())-customGotchaaas.timestamp)/(1000*60*60)))+" Hours ago"},
            }
        }
            props={customGotchaaas}
           />
           </>
      }
      {/*    Height: {innerHeight} <br/>
         Width: {innerWidth} */}
       {/*  <Text color={"green"} fontWeight="bold"  >
        Available Gotchaaas
        </Text> */}
        {availableGotchaaas && availableGotchaaas.length > 0 && availableGotchaaas.map((item, index) => {
          let interests = String(item.interests)
          let intString = interests.replaceAll(",", ", ")
          console.log({item})
          
          
          let timeString = (Math.floor((Number(Date.now())-item.timestamp)/(1000*60*60)))+" Hours ago"
          if(item.successfulChallengers && item.successfulChallengers.length > 1){
          timeString = (Math.floor((Number(Date.now())-item.timestamp)/(1000*60*60)))+" Hours ago"+" ("+item.successfulChallengers.length+" successful participants)"
          }
           return (
           <>
           {/*  <amp-ad width="100vw" height="320"
            type="adsense"
            data-ad-client="ca-pub-2644478000888518"
            data-ad-slot="8115052959"
            data-auto-format="rspv"
            data-full-width="">
 <div overflow=""></div>

</amp-ad> */}
{/* <Adsense
  client="ca-pub-2644478000888518"
  slot="8115052959"
/> */}

       

          {item.creatorUid && <GotchaElementFeedCustom style={{marginBottom: 30}} props={item} onClick={() => handleOpenJoinGotcha1(item)} overrides={
            {
                'Frank': {children: item.name},
                'Basel': {children: item.location},
                'Interests': {children: intString},
                '1 Minute ago': {children: timeString},
            }
        }
            props={item}
           />}
          {item.frontPicBlurred && 
          <>
           <Text color={"green"} fontWeight="bold" 
            padding={"30px 0px 0px 0px"}
            margin={"0px 0px -10px 0px"}
           >
            New Participant🔥
          </Text>
          
            <GotchaSingleDisplayCustom usage={"feed"} onClick={() => handleOpenJoinGotcha1(item)} props={item} user={{user:item.userid}} challengeId={item.challenge} overrides={
                                {
                                image34772788: {src: "https://images.pexels.com/photos/2726111/pexels-photo-2726111.jpeg?auto=compress&cs=tinysrgb&w=630&h=375&dpr=2"},
                                image34772776: {src: "https://images.pexels.com/photos/12476792/pexels-photo-12476792.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"},
                                image34772779: {src: "https://www.kukksi.de/wp-content/uploads/2022/03/Selfie.png"},
                                "Frank": {children: item.name},
                                "Basel": {children: item.location},
                                'Interests': {children: intString}
                                
                                }
                                    } />
                                    </>}
        
        <Divider
          height="1px"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          size="small"
          orientation="horizontal"
        ></Divider>
        
        </>)

        })}
      
       {availableGotchaaas && availableGotchaaas.length === 0 && 
       <>
       
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
        >
          
         
          <Text color={"darkgray"} style={{fontSize:20}} ><b>A little too late...</b><br/> All active Gotchaaas are already filled.<br/><br/> </Text>
         
         
          <Text color={"darkblue"} style={{fontSize:20}} ><b>But don't worry!</b><br/> You can create your own Gotchaaas, check Updates in your existing Gotchaas or come back in a little while.<br/><br/> </Text>
         
       </Flex>
       
       </>
        }
        </ScrollView>
        <Flex
            position="absolute"
            bottom="440px"
            left="0px"
            right="6px"
            justifyContent="right"
            alignItems="right"
            alignContent="right"

            >
           {/* {myProfile.challenges && myProfile.challenges.length >= 0 && */}
            <Button onClick={handleOpenCreateGotcha}> 
            <CreateNewGotchaButton /></Button>
            {/* }  */}
       
       
        
        </Flex>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
        {joinGotcha ?
           <AddGotchaCustomized overrides={{
            image34803798: {src:"https://images.unsplash.com/photo-1491637639811-60e2756cc1c7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8a25hcHNhY2t8ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60"},
            image34803799: {src:"https://images.pexels.com/photos/59576/pexels-photo-59576.jpeg?auto=compress&cs=tinysrgb&w=400"},
            ButtonJoin: {children: "Join"}
           }}
           props={joinGotchaData}  
         />
        :
        <AddGotchaCustomized props={joinGotcha} overrides={{
                image34803798: {src:"https://images.unsplash.com/photo-1491637639811-60e2756cc1c7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8a25hcHNhY2t8ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60"},
                image34803799: {src:"https://images.pexels.com/photos/59576/pexels-photo-59576.jpeg?auto=compress&cs=tinysrgb&w=400"},
                Button: {children: "Create"}
               }}  
             />
             }
           
         {/*  <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
          
        </Box>
      </Modal>
        </>
    )
}