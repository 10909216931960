
import { Amplify, DataStore } from 'aws-amplify';
import { ProfileCard } from '../ui-components';
import { 
  User 
} from '../models';
import { Authenticator, Flex } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './../aws-exports';
import { useEffect, useState } from 'react';
import MemberArea from './MemberArea';

Amplify.configure(awsExports);



export default function Login() {
  /* const users = getUsers()
  console.log(users) */
  const [users, setUsers] = useState()

  async function getUsers(){
    try {
      const posts = await DataStore.query(User);
      /* console.log(posts)
      console.log("Posts retrieved successfully!", JSON.stringify(posts, null, 2)); */
      setUsers(posts)
    } catch (error) {
      console.log("Error retrieving posts", error);
    }
  }

  useEffect(() => {
     getUsers()
  },[])

  useEffect(() => {
    
    const subscription = DataStore.observe(User).subscribe((msg) => {
      console.log(msg.model, msg.opType, msg.element);
    });

    return () => subscription.unsubscribe();
  },[]);

  console.log(users)
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <main>
          <h1>Hello {user.username}</h1>
          {/* <ProfileCard overrides={{image: {src: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1600"}}}/> */}
          <Flex
              direction="row"
              justifyContent="center"
              alignItems="center"
              alignContent="flex-start"
              wrap="nowrap"
              gap="1rem"
            >
          {users && users.map(item => {
           return <ProfileCard profile={item} />
          })}
          </Flex>
          
          <button onClick={signOut}>Sign out</button>
          <MemberArea/>
        </main>
      )}
    </Authenticator>
  );
}