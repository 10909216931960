

// Modern theme

/* import 'survey-react/modern.min.css'; */
// Default theme
// import 'survey-react/survey.min.css';
import { StylesManager, Model } from 'survey-react';
import { Component } from 'react';
import "survey-core/modern.css";
import * as Survey from "survey-core";
import * as SurveyReact from "survey-react-ui";

import * as widgets from "surveyjs-widgets";
import { germanSurveyStrings } from '../localization/german.ts';
import { Flex } from '@aws-amplify/ui-react';
import { createFeedback } from '../services/graphQlServices';






const surveyJson = {
    "title": "Feedback",
    "description": "Your feedback is very important to us. Please take a minute to complete this survey.",
    "showProgressBar": "top",
    "pages": [
        {
            "navigationTitle": "Erfahrung",
            "navigationDescription": "Deine bisherige Berufserfahrung",
            "elements": [
                {
                    "type": "rating",
                    "name": "satisfaction",
                    "title": "How do you like Gotchaaa?",
                    "isRequired": true,
                    
                    "maximumRateDescription": "Genious"
                }, 
               /*  {
                    "type": "sortablelist",
                    "name": "roomsPriority",
                    "title": "Order the features according to your priority",
                    "isRequired": true,
                    "choices": [
                        "Add titles to gotchaaas",
                        "Add descriptions to Gotchaaas", 
                        "Add Donation function", 
                        "Add Friends only function", 
                        "Add a search function",
                        "Add a filter function",  
                        "Add a sorting function",
                        "Add a comment function",
                        "Add a help function",
                        "Add a language function",
                        "Add a FAQ function",
                        "Add a feedback function",
                    ]
                }, */
                {
                    "type": "rating",
                    "name": "recommendFriends",
                    "visibleIf": "{satisfaction} > 3",
                    "title": "How likely are you to recommend Gotchaaa to a friend or co-worker?",
                    "mininumRateDescription": "Unlikely",
                    "maximumRateDescription": "Sure"
                },
                {
                    "type": "dropdown",
                    "name": "findUs",
                    "title": "How did you find us?",
                    "isRequired": true,
                    "colCount": 0,
                    "hasNone": false,
                    "choices": [
                        "Google",
                        "Twitter",
                        "TikTok",
                        "Instagram",
                        "Friends",
                        "Others"
                    ]
                },
                {
                    "type": "comment",
                    "name": "suggestions",
                    "title": "Do you have any other suggestions?"
                },
                
                
                
            ]
        }
    ]
};

Survey.StylesManager.applyTheme("modern");
widgets.sortablejs(Survey);


class Quiz extends Component{
    constructor(props) {
        super(props);
   console.log(props)     
const survey = new Survey.Model(surveyJson);
//Add a new locale into the library.
var mycustomSurveyStrings = {
    pagePrevText: "My Page Prev",
    pageNextText: "My Page Next",
    completeText: "OK - Press to Complete"
};
let counter = 0
/* Survey
    .surveyLocalization
    .locales["my"] = germanSurveyStrings; */
        
        this.survey = survey;
        survey.locale = 'my';
        console.log(survey)
        var storageName = "questionnaire";

        async function saveSurveyData(survey) {
            var data = survey.data;
            /* data.pageNo = survey.currentPageNo; */
            let res = await createFeedback(data)
            console.log({res})
        }

        survey.onPartialSend.add(function(sender){
            
            let currentCounter = String(counter++)
            let action = "HelperQuestionnairePartialSend"+currentCounter
            /* ReactGA.event({
                category: "servicesuchender",
                action: action,
              }); */
            /* saveSurveyData(sender); */
        });

        survey.onComplete.add(function(sender, options){
            
            /* ReactGA.event({
                category: "servicesuchender",
                action: "HelperQuestionnaireCompleted",
              }); */
            saveSurveyData(sender);
            
        });
        
        survey.sendResultOnPageNext = true;
       /*  var prevData = window.localStorage.getItem(storageName) || null;
        if(prevData) {
            var data = JSON.parse(prevData);
            survey.data = data;
            if(data.pageNo) {
                survey.currentPageNo = data.pageNo;
            }
        } */
    }
    

    render() {
        return (
            <>
            <Flex >
            <SurveyReact.Survey
                model={this.survey}
            />
            </Flex>
            </>
        );
    }
}
export default Quiz;