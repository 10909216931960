const { Auth, Storage } = require("aws-amplify");


export const GetCurrentUser = async () => {
        return await Auth.currentAuthenticatedUser();
    }   

    export const getImage = async (key) => {
        
        try {
         const image = await Storage.get(key, {download:true}).then(result => {
                console.log(result)
                if(result.Body){
                    return true
                }
         })
         console.log(image)
        } catch (error) {
          console.log("Error uploading file: ", error);
          return false
        }
      }


  