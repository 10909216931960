import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FolderIcon from '@mui/icons-material/Folder';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import ChatIcon from '@mui/icons-material/Chat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import Diversity1Icon from '@mui/icons-material/Diversity1';

import {
    getOverrideProps,
    useNavigateAction,
  } from "@aws-amplify/ui-react/internal";
import { Link } from 'react-router-dom';

export default function MenuNew(page) {
    console.log({page})
  const [value, setValue] = React.useState(page.page);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const homeOnClick = useNavigateAction({ type: "url", url: "/" });
  const gotchaaasOnClick = useNavigateAction({ type: "url", url: "/gotchaaa" });
  const chatOnClick = useNavigateAction({ type: "url", url: "/chat" });
  const profileOnClick = useNavigateAction({ type: "url", url: "/profile" });

  return (
    
    <BottomNavigation /* sx={{ width: "100%" }} */ value={value} onChange={handleChange} >
       <Link to="/feed" style={{width: "25%"}}>
        <BottomNavigationAction
          size="large"
          label="Feed"
          value="feed"
          icon={<DynamicFeedIcon />}
        />
       </Link>
       <Link to="/gotchaaa" style={{width: "25%"}} className='link'>
      <BottomNavigationAction
        label="Gotchaaas"
        value="gotchaaa"
        icon={<Diversity1Icon />}
      />
      </Link>
      <Link to="/chat" style={{width: "25%"}}>
      <BottomNavigationAction
        label="Chat"
        value="chat"
        icon={<ChatIcon />}
      />
      </Link>
      <Link to="/profile" style={{width: "25%"}}>
      <BottomNavigationAction
        label="Profile"
        value="profile"
        icon={<AccountCircleIcon />}
      />
      </Link>
      
    </BottomNavigation>
    
  );
}
