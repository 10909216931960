import { Button, Flex, ScrollView, TableRow, Text, View } from "@aws-amplify/ui-react";

import { Box, Card, Modal } from "@mui/material";

import CarouselCustom from "./carousel";
import CarouselGotchaaa from "./carouselGotchaaa";
import {
    getOverrideProps,
    useNavigateAction,
  } from "@aws-amplify/ui-react/internal";
import Header from "./header";
import IntroCarousel from "./introCarousel";
import { ToS } from "./ToS";
import { useState } from "react";
import { Impressum } from "./Impressum";
import { PrivacyDeclaration } from "./privacyDeclaration";
import { useNavigate } from "react-router-dom";
/* import {Adsense} from '@ctrl/react-adsense'; */

const style = {
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: window.innerWidth*0.9,
   bgcolor: 'background.paper',
   border: '2px solid #000',
   boxShadow: 24,
   p: 0,
 };

export default function Landing1(){
   const navigate = useNavigate();
   const [open, setOpen] = useState(false);
   const [open1, setOpen1] = useState(false);
   const [open2, setOpen2] = useState(false);
   const handleClose = () => setOpen(false);
   const handleClose1 = () => setOpen1(false);
   const handleClose2 = () => setOpen2(false);
   const handleOpen = () => setOpen(true);
   const handleOpen1 = () => setOpen1(true);
   const handleOpen2 = () => setOpen2(true);
   const login = () => navigate("/login" );
   return(
        <>
       {/* <Header width="30%"/> */}
       <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
         <ToS />
         </Box>
      </Modal>

       <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
         <Impressum />
         </Box>
      </Modal>

       <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
         <PrivacyDeclaration/>
         </Box>
      </Modal>


        <ScrollView style={{height: window.innerHeight-100,  width: "100%" /* , backgroundColor: "black" */}}>
        
        <iframe
         /* src="https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/Variation2.mp4" */
         /* src="https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/santaThought.mp4" */
         /* src="https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/gotchaDesribeNew.mp4" */
         src="https://gotchaimages174336-dev.s3.eu-central-1.amazonaws.com/public/GotchaaaExplainer.mp4"
        style={{width:"100%", height: 430}}
        /* allow="autoplay; encrypted-media" */
        allowFullScreen
        title="video"
      />
            <Card style={{margin:"1rem"}} >
            <Text style={{color: "Black", fontSize:20, margin: "1.5rem"}}>
            People like you
            </Text>
            
         <Text style={{color: "Black", fontSize:16, margin: "1.5rem"}}>
            Create or join Gotchaaas based on your interests and engage in meaningful conversations.
             
             <br/><br/> 
             Gotchaaa is not just about connecting with like-minded individuals, it's also about meeting new people and expanding your social circle, whether you're looking for friends, dating or just some fun.
               <br/>
             <Button boxShadow="rgba(13, 26, 38, 0.25) 0px 4px 12px 0px" onClick={() => login()} style={{margin:"1rem"}} >
         Get started
         </Button>
         </Text>
         </Card>
         
         <CarouselCustom/>
         <Card style={{margin:"1rem", padding:"1em"}} >
         <Text>
         <b style={{fontSize: 20}}>
            Features
            </b> 
         <br/><br/>
               <b>Instant Connect</b>  <br/>
               If you like each other's gotchaaa, you will automatically be connected via privat chat, this way you can engage in one-on-one conversations with potential new friends or romantic partners.
               <br/><br/> 
               <b>Match Your Area</b>  <br/>
               The location setting makes it easy to find people nearby who share your interests. This can be especially helpful if you're looking to meet new people in your local area.
               <br/><br/> 
               <b>Mark Your Favorites</b>  <br/>
            You can mark your favorite gotchaaas and display them in your profile. This way you can curate your favorits and show them to liked-minded people & friends. <br/><br/>

            
            

            
              {/*  You can create or join groups based on your interests and hobbies, such as music, sports, travel, and more. This allows you to easily find and connect with other people who share your interests. 
               <br/><br/>  */}
            {/* Upload one picture of what is in front (with your main camera) and one picture from yourself (selfie from you front camera).<br/><br/> */}
            This is <br/>
            
        
                 <Header width={"50%"}/>
        
            

            

           {/*  You can also optionally add a short description to your gotchaaa.
            Although it is totally fine to not write anything, since this was the original idea of gotchaaa.
            But first user feedback indicated that people may like to add a short description to their gotchaaa. */}

         </Text>
         
         {/* <IntroCarousel />  */}
       
         
         <Button boxShadow="rgba(13, 26, 38, 0.25) 0px 4px 12px 0px" onClick={() => login()} style={{margin:"1rem"}} >
         Join Now
         </Button>
         </Card>
         {/* <Adsense
  client="ca-pub-2644478000888518"
  slot="8115052959"
/> */}
        
         <div style={{margin:"2rem", padding:"2rem"}}>
         <a onClick={handleOpen} style={{fontSize: 10}} margin={"1rem"}>
            Imprint
         </a><br/>
        {/*  <a onClick={handleOpen1} style={{fontSize: 10}} margin={"1rem"}>
            Terms of Service
         </a><br/> */}
         <a onClick={handleOpen2} style={{fontSize: 10}} margin={"1rem"}>
            Privacy Declaration
         </a>
         </div>
        </ScrollView>
        </>
    )
}