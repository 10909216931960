import { createContext, useReducer } from "react"
import AppReducer from "./AppReducer"


import { GetMyGotchas, GetUser, ListAvailableGotchas, GetAvailableGotchasPublic } from "../services/graphQlServices"
import { GetCurrentUser } from "../services/getters"


const initialState = {
    availableGotchaaas: [],
    myGotchaaas: [],
    myProfile: {},
    myChats: [],
    stateChange: false,
    referalId: "",
    isRegistered: false,
    isConnected: false,
    chatState: null
}







export const GlobalContext = createContext(initialState)

export const GlobalProvider = ({children}) => {


    const [state, dispatch] = useReducer(AppReducer, initialState)
console.log({state})
    async function saveReferalId  (id) {
        dispatch(
            {
                type: "SAVE_REFERAL_ID",
                payload: id
            }
        ) 
        
    }
    async function loadAvailableGotchaaas  () {
        let gotchas = await ListAvailableGotchas()
        
        let gotchasArray = JSON.parse(gotchas.data.listAvailableGotchas)
        console.log({gotchasArray})
        /* return gotchasArray */
        dispatch(
            {
                type: "SAVE_AVAILABLE_GOTCHAAAS",
                payload: gotchasArray
            }
        ) 
    }
    async function loadAvailableGotchaaasPublic  () {
        let gotchas = await GetAvailableGotchasPublic()
        
        let gotchasArray = JSON.parse(gotchas.data.listAvailableGotchasPublic)
        console.log({gotchasArray})
        /* return gotchasArray */
        dispatch(
            {
                type: "SAVE_AVAILABLE_GOTCHAAAS",
                payload: gotchasArray
            }
        ) 
    }
    

    async function loadMyGotchaaas(){
            let myGotchas = await GetMyGotchas()
            let myGotchasObj = []
            if(myGotchas.data.getMyGotchas != "bad"){
                myGotchasObj = JSON.parse(myGotchas.data.getMyGotchas)
            }
            dispatch(
                {
                    type: "SAVE_MY_GOTCHAAAS",
                    payload: myGotchasObj
                }
            ) 
            
        }
    async function loadMyProfile(){
            let user = await GetCurrentUser()
            let userDetails = await GetUser(user.username)
            let userObj =  userDetails.data.getUser
            if(userObj){
                dispatch(
                    {
                        type: "SAVE_MY_PROFILE",
                        payload: userObj
                    }
                ) 
            }
            console.log({userObj})
            
            
        }
        
    async function stateChangeFunction(){
        console.log("state change")
        dispatch(
            {
                type: "STATE_CHANGE",
                payload: !state.stateChange
            }
        ) 
    }   
    async function isRegisteredFunction(value){
        console.log("state change")
        dispatch(
            {
                type: "REGISTERED",
                payload: value
            }
        ) 
    }   
    async function setChatState(value){
        console.log("state change")
        dispatch(
            {
                type: "CHAT_STATE",
                payload: value
            }
        ) 
    }   
    async function isConnectedFunction(value){
        console.log("is connected: "+value)

        dispatch(
            {
                type: "CONNECTED",
                payload: value
            }
        ) 
    }   
    
    
    function addRecipeToList(recipe){
        recipe['myRecipe'] = true
        dispatch(
            {
                type: "ADD_ITEM",
                payload: recipe
            }
        ) 
    }
   
    function getMyCurrentRecipes(recipe){
        dispatch(
            {
                payload: "get"
            }
        ) 
    }
    function deleteRecipeFromList(recipe){
        delete recipe['myRecipe'] 
        dispatch(
            {
                type: "DELETE_ITEM",
                payload: recipe
            }
        )
    }
    
    return(
        <GlobalContext.Provider value={{chatState: state.chatState, isConnected: state.isConnected, isRegisteredUser: state.isRegistered, referalId: state.referalId, availableGotchaaas: state.availableGotchaaas, myGotchaaas: state.myGotchaaas, myProfile: state.myProfile, myChats: state.myChats, stateChange: state.stateChange, loadAvailableGotchaaas, loadMyGotchaaas, loadMyProfile, stateChangeFunction, saveReferalId, isRegisteredFunction, isConnectedFunction, setChatState, loadAvailableGotchaaasPublic}}>
            {children}
        </GlobalContext.Provider>
    )

}