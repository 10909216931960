import { surveyLocalization } from "survey-core";

export var germanSurveyStrings = {
  pagePrevText: "Back",
  pageNextText: "Weiter",
  completeText: "Abschließen",
  previewText: "Vorschau",
  editText: "Bearbeiten",
  emptyText: "Schiebe die Begriffe hierein",
  startSurveyText: "Start",
  otherItemText: "Sonstiges (Bitte angeben)",
  noneItemText: "Nichts trifft zu",
  selectAllItemText: "Alles auswählen",
  progressText: "Seite {0} von {1}",
  panelDynamicProgressText: "Eintrag {0} von {1}",
  questionsProgressText: "{0}/{1} Fragen beantwortet",
  emptySurvey: "Es sind keine Fragen vorhanden.",
  completingSurvey: "Vielen Dank, dass Sie die Umfrage abgeschlossen haben!",
  completingSurveyBefore:
    "Wir haben festgestellt, dass Sie diese Umfrage bereits abgeschlossen haben.",
  loadingSurvey: "Umfrage wird geladen...",
  optionsCaption: "Bitte auswählen...",
  value: "Wert",
  requiredError: "Bitte beantworten Sie diese Frage.",
  requiredErrorInPanel: "Bitte beantworten Sie mindestens eine Frage.",
  requiredInAllRowsError: "Bitte beantworten Sie alle Fragen.",
  numericError: "Der Wert muss eine Zahl sein.",
  textMinLength: "Bitte geben Sie mindestens {0} Zeichen ein.",
  textMaxLength: "Bitte geben Sie nicht mehr als {0} Zeichen ein.",
  textMinMaxLength: "Bitte geben Sie mindestens {0} und maximal {1} Zeichen ein.",
  minRowCountError: "Bitte machen Sie in mindestens {0} Zeilen eine Eingabe.",
  minSelectError: "Bitte wählen Sie mindestens {0} Antwort(en) aus.",
  maxSelectError: "Bitte wählen Sie nicht mehr als {0} Antwort(en) aus.",
  numericMinMax:
    "'{0}' muss größer oder gleich {1} und kleiner oder gleich {2} sein",
  numericMin: "'{0}' muss größer oder gleich {1} sein",
  numericMax: "'{0}' muss kleiner oder gleich {1} sein",
  invalidEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  invalidExpression: "Der Ausdruck: {0} muss den Wert 'wahr' zurückgeben.",
  urlRequestError: "Ein Netzwerkdienst hat folgenden Fehler zurückgegeben '{0}'. {1}",
  urlGetChoicesError:
    "Eine Netzwerkdienst hat ungültige Daten zurückgegeben",
  exceedMaxSize: "Die Datei darf nicht größer als {0} sein.",
  otherRequiredError: "Bitte geben Sie einen Wert an.",
  uploadingFile:
    "Bitte warten Sie bis der Upload Ihrer Dateien abgeschlossen ist.",
  loadingFile: "Wird hochgeladen...",
  chooseFile: "Datei(en) auswählen...",
  noFileChosen: "Keine Datei ausgewählt",
  confirmDelete: "Wollen Sie den Eintrag löschen?",
  keyDuplicationError: "Dieser Wert muss einmalig sein.",
  addColumn: "Spalte hinzufügen",
  addRow: "Zeile hinzufügen",
  removeRow: "Entfernen",
  addPanel: "Neu hinzufügen",
  removePanel: "Entfernen",
  choices_Item: "Element",
  matrix_column: "Spalte",
  matrix_row: "Zeile",
  savingData: "Die Ergebnisse werden auf dem Server gespeichert...",
  savingDataError: "Es ist ein Fehler aufgetreten. Die Ergebnisse konnten nicht gespeichert werden.",
  savingDataSuccess: "Die Ergebnisse wurden gespeichert!",
  saveAgainButton: "Erneut absenden",
  timerMin: "Min.",
  timerSec: "Sek.",
  timerSpentAll: "Sie waren {0} auf dieser Seite und brauchten insgesamt {1}.",
  timerSpentPage: "Sie waren {0} auf dieser Seite.",
  timerSpentSurvey: "Sie haben insgesamt {0} gebraucht.",
  timerLimitAll:
    "Sie waren {0} von {1} auf dieser Seite und brauchten insgesamt {2} von {3}.",
  timerLimitPage: "Sie waren {0} von {1} auf dieser Seite.",
  timerLimitSurvey: "Sie haben insgesamt {0} von {1} gebraucht.",
  cleanCaption: "Alles löschen",
  clearCaption: "Auswahl entfernen",
  chooseFileCaption: "Datei auswählen",
  removeFileCaption: "Datei löschen",
  booleanCheckedLabel: "Ja",
  booleanUncheckedLabel: "Nein",
  confirmRemoveFile: "Sind Sie sicher, dass Sie diese Datei löschen möchten: {0}?",
  confirmRemoveAllFiles: "Sind Sie sicher, dass Sie alle Dateien löschen möchten?",
  questionTitlePatternText: "Fragentitel",
};

surveyLocalization.locales["de"] = germanSurveyStrings;
surveyLocalization.localeNames["de"] = "deutsch";